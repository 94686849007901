import React from "react";
import { useSelector } from 'react-redux';
import styles from '../../../assets/css/ContactUs.module.css';
import { useNavigate } from "react-router-dom";
function ContactUs() {
    const navigate = useNavigate();
    const contact_us = useSelector((state) => state.rootReducer.website_setting.contact_us)
    return (
        <div className={styles.ContactUsContainer}>
            <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.contactUs} dangerouslySetInnerHTML={{ __html: contact_us }}></div>
        </div>
    )
}

export default ContactUs;