import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IMAGE_URL } from "../../../Config/Config";
import styles from '../../../assets/css/ProductPreview.module.css'
import { useSelector } from "react-redux";
import { get_single_item } from '../../../Utils/method'
import AddButton from '../../AddButton';
import classNames from 'classnames';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../Loader';
import ItemPrice from './ItemPrice';
import ReviewWrite from './Review_write';
import ItemDescription from './ItemDescription';
import ImagePreview from './ImagePreview';
import { isMobile } from 'react-device-detect';
import { _domain } from '../../../Config/Domains';


function SingleItem(props) {
    const location = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [offer_text, setOfferText] = useState('');
    const [data, setData] = useState({})
    const [loader, setLoader] = useState(true)
    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const [activeDelivery, setactiveDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const currency_sign = useSelector((state) => state.rootReducer.currency_sign);
    const [tabState, setTabState] = useState(1);
    const [reviewWriting, setReviewWriting] = useState(false);
    const [description, setDescription] = useState(false);
    const [imgPreview, setImgPreview] = useState(false);
    const [imgSrc, setSrc] = useState('');

    const settings = {
        customPaging: function (i) {
            return (
                <a>
                    <img src={IMAGE_URL + data.image_url[i]} alt='' />
                </a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    useEffect(() => {
        let _isMounted = true;
        setLoader(true);
        const get_product = async () => {
            let apires = await get_single_item(params._id);
            console.log(48, apires)
            if (_isMounted) {
                if (apires.success) {
                    setData(apires.item);
                }
            }
            setLoader(false);
        }
        get_product();

        return () => {
            _isMounted = false;
        }
    }, [params._id])

    const findOffer = () => {
        if (data.offer_percentage !== '0' && data.offer_percentage !== undefined) {
            if (data.offer_percentage === '50') {
                setOfferText('BUY 1 GET 1')
            } else if (data.offer_percentage === '10') {
                setOfferText('10% OFF')
            } else if (data.offer_percentage === '20') {
                setOfferText('20% OFF')
            } else if (data.offer_percentage === '30') {
                setOfferText('30% OFF')
            } else if (data.offer_percentage === 'custom1') {
                setOfferText(data.custom_tag1)
            } else {
                setOfferText(data.custom_tag2)
            }
        }
    }

    useEffect(() => {
        console.log(data)
        findOffer()
    }, [data])

    const previousPrice = (price, offer) => {
        if (offer === 10) {
            return Math.round((Number(price / 0.9)));
        } else if (offer === 20) {
            return Math.round((Number(price / 0.8)));
        } else if (offer === 30) {
            return Math.round((Number(price / 0.7)));
        } else if (offer === 50) {
            return Math.round((Number(price / 0.5)));
        }

    }

    // console.log(108, data.details)

    const backToSubCate = (data) => {
        console.log(111, data.product_id);
        if (location.pathname.indexOf('/deshi/') > -1) {
            navigate(-1)
        } else {
            navigate("/product/" + data.product_id)
        }
    }

    return (
        <div className={styles.productPrevPopup}>
            {/* <div className={styles.single_itemBreadCrumb}>
                <Link to={"/"}><span> Home </span></Link> <span style={{ fontSize: '10px' }}> <i className="fa-thin fa-chevron-right"></i> </span>
            </div> */}
            <div className={styles.carting_head}>
                <span className={styles.closeCart} onClick={() => backToSubCate(data)}><i className="fa-thin fa-xmark"></i></span>
            </div>
            {
                loader ?
                    <Loader />
                    : <div className={styles.previewDivider}>
                        { }
                        <div className={styles.divItem + ' ' + styles.itemImage}>
                            <Slider {...settings} className={data.image_url.length === 1 ? 'firstSliderFH' : 'firstSlider'}>
                                {data.image_url.map(i =>
                                    <img className={styles.sliderImg} onClick={() => { setSrc(IMAGE_URL + i); setImgPreview(true) }} key={i + '_k'} src={IMAGE_URL + i} alt='' />
                                )}
                            </Slider>
                        </div>
                        <div className={classNames(styles.divItem, styles.itemDetails)}>
                            <div className={styles.itemDetailsTop}>
                                <div className={styles.prev_title}>
                                    <h2> {data.name} </h2>
                                </div>
                                <div className={styles.topBox}>
                                    <div className={styles.prev_price}>
                                        <ItemPrice type={'sitem'} data={data} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={offer_text} previousPrice={previousPrice} />
                                    </div>
                                    {data.delivery_time_static !== '' && <p className={classNames(styles.vatTaxLabel)} style={{ marginTop: '5px' }} ><span className={styles.deliveryTimeIcon}><i className="fa-thin fa-truck"></i></span> {data.delivery_time_static} </p>}
                                    <div className={styles.originPay}>
                                        <div className={styles.productOrigin}>
                                            {/* <div className={styles.originLogo}>
                                                <span className={styles.supplierLabel}>Source:</span>
                                                {data.supplier_icon !== '' && <img src={IMAGE_URL + data.supplier_icon} alt="Supplier" />}
                                            </div> */}
                                            {/* <div className={styles.originLogo}>
                                                {data.brand_icon !== '' && <img src={IMAGE_URL + data.brand_icon} alt="Band" />}
                                            </div> */}
                                        </div>
                                        <div className={styles.previewBottomLine}>
                                            <div>
                                                <div className={styles.bottomSection}>
                                                    <span className={styles.payIconsText}>Pay with</span>
                                                </div>
                                                <div className={styles.bottomSection}>
                                                    <span className={classNames(styles.payIcons, styles.aexpress)}></span>
                                                    <span className={classNames(styles.payIcons, styles.master)}></span>
                                                    <span className={classNames(styles.payIcons, styles.visa)}></span>
                                                    <span className={classNames(styles.payIcons, styles.bkash)}></span>
                                                    <span className={classNames(styles.payIcons, styles.nagad)}></span>
                                                    <span className={classNames(styles.payIcons, styles.cod)}></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.prev_add}>
                                    <p className={styles.writeReview} onClick={() => setReviewWriting(true)}><i className="fa-thin fa-message-pen fa-rotate-270" style={{ color: '#012965', marginRight: 4 }}></i> Write a review</p>
                                    <AddButton data={data} theme={'preview'} />
                                </div>
                            </div>

                            <div className={styles.tabContainer}>
                                <div className={styles.tabHead}>
                                    <div className={classNames(styles.tabs, tabState === 1 ? styles.active : '')} onClick={() => setTabState(1)}>Product Details</div>
                                    <div className={classNames(styles.tabs, tabState === 2 ? styles.active : '')} onClick={() => setTabState(2)}>Delivery & Returns</div>
                                    <div className={classNames(styles.tabs, tabState === 3 ? styles.active : '')} onClick={() => setTabState(3)}>{_domain().title}</div>
                                </div>
                                <div className={styles.tabBody}>
                                    {tabState === 1 ?
                                        <>
                                            <div className={styles.item_description} dangerouslySetInnerHTML={{ __html: data.details }} />
                                            {!isMobile && <div className={styles.read_more_btn_cont}><span onClick={() => setDescription(true)} className={styles.read_more_btn}>Read more</span></div>}
                                        </>
                                        : tabState === 2 ?
                                            <div className={styles.ukbdTab}>
                                                <p style={{ marginBottom: 15 }}>
                                                    <p style={{ fontWeight: 'bold' }}>Delivery</p>
                                                    FREE for inside Dhaka city, outside Dhaka subject to parcel size and distance.
                                                    Almost everything on www.{_domain().title.toLowerCase()}.app is available for home delivery, we can send your order to all the regions around Bangladesh and UK.
                                                    Your order will be delivered within 15 days subject to custom clearance in Bangladesh.
                                                    Due to customs checks, please be advised we may experience delays in delivering in Bangladesh.
                                                    Inside UK standard delivery time is 3 to 5 days.
                                                </p>

                                                <p>
                                                    <p style={{ fontWeight: 'bold' }}>Returns</p>
                                                    Unfortunately, for orders shipped outside the UK, we’re unable to refund any taxes, duties, charges or delivery costs you may have paid when placing your order. You will be refunded the tax-free price of the products returned.
                                                </p>
                                            </div>
                                            :
                                            <div className={styles.ukbdTab}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-around',
                                                }}>

                                                    <div className={styles.ukbdTabDivider}>
                                                        <p>We deliver 100% authentic {_domain().title === 'UKBD' ? 'British' : 'American'} products to your doorsteps in Bangladesh.</p>
                                                    </div>
                                                    <div className={styles.ukbdTabDivider} style={{ padding: '18px 0px' }}>
                                                        <div className={styles.applinks}>
                                                            <div className={styles.applink}>
                                                                <div className={styles.appIcon}><i className="fa-brands fa-google-play"></i></div>
                                                                <div className={styles.appTexts}>
                                                                    <span className={styles.name}>Google Play</span>
                                                                </div>
                                                            </div>
                                                            <div className={styles.applink}>
                                                                <div className={styles.appIcon}><i className="fa-brands fa-apple"></i></div>
                                                                <div className={styles.appTexts}>
                                                                    <span className={styles.name}>App Store</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={styles.supportlinks}>
                                                            {/* <div className={styles.supportNumber}>
                                                            <p><span><i className="fa-light fa-headset"></i></span> <span style={{ fontSize: '20px', fontWeight: 400 }}>199996</span></p>
                                                        </div> */}
                                                            <div className={styles.supportMail}>
                                                                <p><i className="fa-thin fa-envelope" style={{ marginRight: 5, fontSize: 18 }}></i> <span style={{ fontSize: '18px', fontWeight: 400 }}> support@ukbd.com </span> </p>
                                                            </div>
                                                        </div>
                                                        <div className={styles.sociallinks}>
                                                            <div className={styles.socialicon}><i className="fa-brands fa-youtube"></i></div>
                                                            <div className={styles.socialicon}><i className="fa-brands fa-facebook"></i></div>
                                                            <div className={styles.socialicon}><i className="fa-brands fa-twitter"></i></div>
                                                            <div className={styles.socialicon}><i className="fa-brands fa-instagram"></i></div>
                                                        </div>
                                                    </div>
                                                    <div className={styles.ukbdTabDivider}>
                                                        <p>{_domain().title} APP LTD is registered in the UK and the Company number is 14162100.</p>
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            }
            {imgPreview && <ImagePreview all_data={data.image_url} imgSrc={imgSrc} setImgPreview={setImgPreview} />}
            {description && <ItemDescription data={data} setDescription={setDescription} />}
            {(!loader && reviewWriting) && <ReviewWrite data={data} setReviewWriting={setReviewWriting} />}
        </div>
    )
}

export default SingleItem;