import React, { useState, useEffect } from 'react'
// import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import styles from '../../../assets/css/Category.module.css'
import { IMAGE_URL } from '../../../Config/Config'
import { getItem_from_category } from '../../../Utils/method'
import Loader from '../../Loader'

export default function Category() {
	const params = useParams();
	// const product_groups = useSelector((state) => state.rootReducer.product_groups);
	// const [productsALL, setProducts] = useState([]);
	const [itemsALL, setItems] = useState([]);
	const [loader, setLoader] = useState(true)
	// const category = product_groups.filter(c => c._id === params._id)[0]

	useEffect(() => {
		let isMounted = true;
		const getCategoryItems = async () => {
			try {
				setLoader(true)
				let apires = await getItem_from_category({ category_id: params._id });
				console.log(22, apires.items)
				if (isMounted) {
					setItems(apires.items)
				}
				setLoader(false)
			} catch (error) {
				setLoader(false)
				console.log(error)
			}
		}
		getCategoryItems()
		return () => {
			isMounted = false;
		}
	}, [params._id])

	// <Link to={'/product/'+v._id} className={styles.categoryItemGrid} key={'ig_' + v._id}>
	// 	<div className={styles.categoryItem}>
	// 		{v.feature_images !== undefined && v.feature_images.map((vi, ii) =>
	// 			<div className={styles.categoryItemIn} key={'ci_' + ii}>
	// 				<img src={IMAGE_URL + vi.path} alt={'categoryLogo'} />
	// 			</div>
	// 		)}
	// 		<p className={styles.categoryTitle}>{v.name[0]}</p>
	// 	</div>
	// </Link>
	return (
		<div className="product_section" style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start',padding:'0px' }}>
			{loader ?
				<Loader />
				:
				itemsALL.map(v =>
					<Link to={'/single-item/' + v._id} className={styles.categoryItemIn} key={'key_'+v._id} title={v.name[0]}>
						<img src={IMAGE_URL + v.image_url[0]} alt={'categoryLogo'} />
					</Link>
				)
			}
		</div>
	)
}
