import { Link } from 'react-router-dom';
import styles from '../../../assets/css/SiteMap.module.css'
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { _domain } from '../../../Config/Domains';


const SiteMap = () => {
    const product_groups = useSelector((state) => state.rootReducer.product_groups);
    return (
        <div className="sitemap_container">
            <div className={styles.sitemap_top}>
                <div className={styles.sitemap_logo_sec}>
                    <Link className={styles.siteMapLogoHolder} to={_domain().app}>
                        <img className={styles.pageLogo} src={_domain().logo} alt="" />
                    </Link>
                    {/* <div className={styles.pageTitle}>
                        <h2>UK & EU Brands</h2>
                    </div> */}
                </div>
                {/* <div className={styles.sitemap_feedback_sec}>
                    <p className={styles.feedback_title}><i style={{ marginRight: 5 }} className="fa-light fa-message-plus"></i> Website Feedback</p>
                </div> */}
            </div>
            <div className={styles.sitemap_Bottom}>
                <div className={styles.sitemap_Bottom_col}>
                    {/* <div className={styles.parent_element}>UK & EU Brands</div> */}
                    {product_groups.filter(t => t.name !== 'Exclusive & Luxury').map((v, k) =>
                        <div key={k + '_group'} className={styles.child_element_group}>
                            <div className={styles.child_element}>{v.name}</div>
                            <div className={styles.grand_child_group}>
                                {(v?.products).length > 0 && v.products.map((vv, kk) =>
                                    <Link to={"/product/" + vv._id} className={styles.grand_child} key={kk + '_grand'}>{vv.name.toString()}</Link>
                                )}
                            </div>
                            {/* {(v?.products).length > 0 ?} */}
                        </div>
                    )}
                </div>


                <div className={classNames(styles.sitemap_Bottom_col, styles.sitemap_Bottom_col_right)}>
                    <div className={styles.child_element}> PARTNER </div>
                    <div className={styles.child_element}> DRIVER </div>
                    {_domain().title === 'UKBD' ?
                        <div className={styles.child_element}> UK-EU </div>
                        :
                        <div className={styles.child_element}> UK-EU </div>
                    }
                    <div className={styles.child_element}> DESHI </div>
                    <div className={styles.child_element}> TRAVEL </div>
                    <div className={styles.child_element}> STUDY </div>
                    <div className={styles.child_element}> WORK/VISA </div>
                    <div className={styles.child_element}> PROPERTY </div>
                    <div className={styles.child_element}> MONEY </div>
                    <div className={styles.child_element}>About</div>
                    <div className={styles.child_element}>Help & FAQs</div>
                    <div className={styles.child_element}>Contact us</div>
                    <div className={styles.child_element}>Jobs</div>
                    <div className={styles.child_element}>Terms & Conditions</div>
                    <div className={styles.child_element}>Privacy Policy</div>
                </div>
            </div>
            <div className={styles.social_media_part}>
                <Link to={'https://play.google.com/store/apps/details?id=uk.ukbd.user&pli=1'} className={styles.social_icon} title="Play Store">
                    <i className="fa-brands fa-google-play"></i>
                </Link>
                <Link to={'https://apps.apple.com/us/app/ukbd-app/id6443981791'} className={styles.social_icon} title="App Store" style={{ fontSize: '18px' }}>
                    <i className="fa-brands fa-apple"></i>
                </Link>
                <Link to={'https://api.whatsapp.com/send?phone=+44%207305%20240807&&text=Hi&&type=phone_number&&app_absent=%270%27'} className={styles.social_icon} title="Whatsapp">
                    <i className="fa-brands fa-whatsapp"></i>
                </Link>
                <Link to={'https://www.youtube.com/@UKBDApp'} className={styles.social_icon} title="Youtube">
                    <i className="fa-brands fa-youtube"></i>
                </Link>
                <Link to={'https://www.facebook.com/ukbdappcom'} className={styles.social_icon} title="Facebook">
                    <i className="fa-brands fa-facebook"></i>
                </Link>
                <Link to={'https://twitter.com/ukbdapp'} className={styles.social_icon} title="Twitter">
                    <i className="fa-brands fa-twitter"></i>
                </Link>
                <Link to={'https://www.linkedin.com/company/ukbdapp/'} className={styles.social_icon} title="Linkedin">
                    <i className="fa-brands fa-linkedin-in"></i>
                </Link>
                <Link to={'https://www.instagram.com/ukbdapp/'} className={styles.social_icon} title="Instagram">
                    <i className="fa-brands fa-instagram"></i>
                </Link>
            </div>
        </div>
    )
}

export default SiteMap;