import React from 'react';
import styles from '../../assets/css/StudyInUk.module.css';
import { Link } from 'react-router-dom';
import travel from '../../assets/images/travel.png'


function FindYourHost() {
    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.logoText}>
                    <div className={styles.ukbdtext}>
                        <p className={styles.title}> Find Your Host</p>
                    </div>
                </div>
                {/* <div className={styles.studyBtns}>
                    <Link className={styles.headBtns}>Admission</Link>
                    <Link className={styles.headBtns}>Accommodation</Link>
                    <Link className={styles.headBtns}>Jobs</Link>
                    <Link className={styles.headBtns}>Banks</Link>
                    <Link className={styles.headBtns}>Consultation</Link>
                </div> */}
                <div className={styles.topTextN}>
                    <p className={styles.topinnertext}>
                        <span className={styles.slide_text_title}>Discover Authentic Bangladeshi Hospitality in London!</span>
                        <br />
                        <br />
                        {/* <div className={styles.travelImageContainer}></div> */}
                        <span className={styles.slide_text_body}>
                            Connects Bangladeshi visitors with accommodating Bangladeshi hosts. Experience London's vibrant atmosphere while staying with Bangladeshi hosts who understand your needs, language, and traditions. A personalised connection in London, offering a warm welcome, insider tips, and a genuine cultural exchange for Bangladeshi visitors.
                        </span>
                    </p>
                </div>
            </div>
            {/* <div className={styles.rightContainer}> */}
            {/* <div className={styles.returnArrow} onClick={() => navigate(-1)}>
                    <i className="fa-light fa-house"></i>
                </div> */}
            {/* <div className={styles.rightTopBtns}>
                    <Link className={styles.headBtns}>Accommodation</Link>
                    <Link className={styles.headBtns}>Jobs</Link>
                </div> */}
            {/* <div className={styles.rightTopText}>
                    <p className={styles.rightTopinnertext}>
                        find your ideal home & job in the UK
                    </p>
                    <p className={styles.rightTopinnertext2}>
                        Let's start NOW
                    </p>
                </div>
                <div className={styles.rightForms}>
                    <div className={styles.rightFormTop}>
                        <label for="Name">Full Name</label><br></br>
                        <input type='text' placeholder='Enter your full name'></input><br></br>
                        <label for="Name">University</label><br></br>
                        <input type='text' placeholder='Enter your university name'></input>
                    </div>
                    <div className={styles.rightFormMiddle}>
                        <label for="Name">Campus</label><br></br>
                        <input type='text' placeholder='Enter city/county name'></input><br></br>
                        <label for="Name">Degree</label><br></br>
                        <input type='text' placeholder='Select your degree type'></input><br></br>
                        <label for="Name">Course Title</label><br></br>
                        <input type='text' placeholder='Your Course Title'></input><br></br>
                        <label for="Name">Arrival Date</label><br></br>
                        <input type='text' placeholder='Enter arrival date'></input><br></br>
                        <label for="Name">Start Session</label><br></br>
                        <input type='text' placeholder='Session starts'></input><br></br>
                    </div>
                    <div className={styles.rightFormBottom}>
                        <label for="Name">Mobile</label><br></br>
                        <div className={styles.inputGroup}>
                            <span className={styles.logoNum}>
                                <img src={flag_bd}></img>
                                <p>+880</p>
                            </span>
                            <input type='text' placeholder=''></input><br></br>
                        </div>
                        <label for="Name">Email</label><br></br>
                        <input type='email' placeholder='Enter your email'></input>
                    </div>
                </div>
                <div className={styles.termsAndCondition}>
                    <input type='checkbox'></input>
                    <label>Accepting terms & conditions</label>
                </div>
                <div className={styles.submitBtn}>
                    <button>SUBMIT</button>
                </div>
                <div className={styles.rightBottomText}>
                    <p className={styles.rightBottominnertext}>
                        Our dedicated support team will contact you to ensure that you can focus on your studies with peace of mind, knowing you have a comfortable & conducive living environment, along with a part-time job & pocket money.
                    </p>
                </div> */}
            {/* <div className={styles.comingSoon}>
                    <p className={styles.comingSoonText}>Coming Soon</p>
                </div> */}
            {/* </div> */}
            {/* <div className={styles.uk_bd_flag_container}>
                <div className={styles.flag_img}> <img src={flag_uk} alt="" /> </div>
                <div className={styles.flag_img}> <img src={flag_bd} alt="" /> </div>
            </div> */}
        </div>
    );

}

export default FindYourHost;