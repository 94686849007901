/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import styles from '../../assets/css/Popup.module.css';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { get_only_categories, searchItems } from '../../Utils/method';
import loader from '../../assets/loader.svg';
import CurrencyFormat from 'react-currency-format';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import { IMAGE_URL } from '../../Config/Config';
import AddButton from '../AddButton';
import { getCurrencySign } from '../../Utils/common';
import { useCart } from 'react-use-cart';
import { useEffect } from 'react';
import Loader from '../Loader';
import { _domain } from '../../Config/Domains';

const Uk_Eu_Popup = ({ setMenu_popup }) => {
    const {
        inCart
    } = useCart();
    
    const product_groups = useSelector((state) => state.rootReducer.product_groups);
    const stores = useSelector((state) => state.rootReducer.stores);
    // const [search_state, setSearch_state] = useState(true);
    const [searchValue, setSearchValue] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState();
    const [searchedItemList, setSearchedItemList] = useState([]);
    // const [paginate_data, setPaginate_data] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 0 });
    const [nextPage, setNextPage] = useState();
    const [productGroups, setproductGroups] = useState([]);
    const [ploader, setPloader] = useState(false);

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    const fetchMoreData = async () => {
        try {
            if (hasNextPage) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: searchValue,
                    page: nextPage,
                    limit: 20,
                    domain_id: _domain().name.indexOf('ukbd') > -1 ? '6502b5d5b7448422bd3d62de' : _domain().name.indexOf('usbd') > -1 ? '65263cfc39b8bd1f2f87630d' : '' //ukbd.app domain id // JSON.parse(localStorage.getItem('current_domain'))._id
                });
                if (apires.status) {
                    setSearchedItemList([...searchedItemList, ...apires.items]);
                    // setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                console.log('Fetch data: ', apires);
            }
        } catch (error) {
            console.log(error)
        }
    }

    let timer;
    const searchOnKeyup = (e) => {
        clearTimeout(timer);
        timer = setTimeout(async function () {
            setSearchLoader(true);
            var vLength = e.target.value.length
            if (vLength > 0) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: e.target.value,
                    page: 1,
                    limit: 50,
                    domain_id: _domain().name.indexOf('ukbd') > -1 ? '6502b5d5b7448422bd3d62de' : _domain().name.indexOf('usbd') > -1 ? '65263cfc39b8bd1f2f87630d' : '' //ukbd.app domain id //JSON.parse(localStorage.getItem('current_domain'))._id
                });
                if (apires.status) {
                    setSearchedItemList(apires.items);
                    // setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                setSearchLoader(false);
                console.log(apires)
            }
        }, 500);
    }

    const setScrollPosition = () => {
        const element = document.getElementById('main_content_div')
        element.scrollTo(0, 0);
    }

    useEffect(() => {
        let _isMounted = true;

        if (_domain().name === 'ukbd.app') {
            setproductGroups(product_groups)
        } else {
            setPloader(true)
            async function fetchData() {
                try {
                    // let domain_id = JSON.parse(localStorage.getItem('current_domain'))._id
                    // console.log('domain_id:', domain_id);
                    let apires = await get_only_categories({
                        store_delivery_id: localStorage.getItem('delivery_id') || '',
                        domain_id: _domain().name.indexOf('ukbd') > -1 ? '6502b5d5b7448422bd3d62de' : _domain().name.indexOf('usbd') > -1 ? '65263cfc39b8bd1f2f87630d' : ''
                    });
                    if (_isMounted) {
                        console.log('get_only_categories :', apires);
                        setproductGroups(apires)
                    }
                    setPloader(false)
                } catch (error) {
                    setPloader(false)
                    console.log(error);
                }
            }
            fetchData()
        }

        return () => {
            _isMounted = false;
        }
    }, [])

    return (
        <div className={styles.popup_wraper}>
            <div className={styles.popupInner} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.header_section}>
                    {_domain().name.indexOf('ukbd') > -1 ?
                        <p className={styles.header_title}>Delivering 100% authentic British products to the doorsteps of Bangladesh</p>
                        :
                        <p className={styles.header_title}>Delivering 100% authentic American products to the doorsteps of Bangladesh</p>
                    }
                    <input className={styles.header_search_input} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup} type='text' placeholder={_domain().name.indexOf('ukbd') > -1 ? 'Search for UK & EU brands' : 'Search for US brands'} autoFocus />
                </div>
                {searchValue.length === 0 &&
                    <div className={styles.body_section}>
                        {ploader ?
                            <Loader />
                            :
                            productGroups.filter(t => t.name !== 'Exclusive & Luxury').map((v, k) =>
                                <div key={k + '_group'} className={styles.child_element_group}>
                                    <div className={styles.child_element}>{v.name}</div>
                                    <div className={styles.grand_child_group}>
                                        {(v?.products).length > 0 && v.products.map((vv, kk) =>
                                            <Link to={"/product/" + vv._id} onClick={() => { setScrollPosition(); setMenu_popup('') }} className={styles.grand_child} key={kk + '_grand'}>{vv.name.toString()}</Link>
                                        )}
                                    </div>
                                    {/* {(v?.products).length > 0 ?} */}
                                </div>
                            )}
                    </div>
                }
                {searchValue.length > 0 &&
                    <div className={styles.search_body_section}>
                        <div className={styles.search_result_container} id="searchItemContainer">
                            {searchLoader ?
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                </div>
                                :
                                <InfiniteScroll
                                    dataLength={searchedItemList.length}
                                    next={fetchMoreData}
                                    hasMore={hasNextPage}
                                    // className={styles.searchItemContainer}
                                    loader={
                                        <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                        </div>
                                    }
                                    scrollableTarget="searchItemContainer"
                                >
                                    {
                                        searchedItemList.map((v, i) =>
                                            <div className={classNames(styles.srItemHolder, inCart(v._id) ? styles.itemInCart : '')} key={'sri_' + i}>
                                                <Link to={'/single-item/' + v._id} className={styles.srItem} onClick={() => { setSearchValue(''); setMenu_popup('') }}>
                                                    <div className={styles.sri_image}>
                                                        <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
                                                    </div>
                                                    <div className={styles.sriDetails}>
                                                        <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
                                                        <div className={styles.sri_price}>
                                                            <CurrencyFormat value={v.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className={styles.srItemAdd}>
                                                    <AddButton data={v} theme="search" />
                                                </div>
                                            </div>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default Uk_Eu_Popup;