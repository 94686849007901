/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
import { add_favourite_address, update_favourite_address } from '../../../Utils/method'
import Geocode from "react-geocode";
import { appStatic } from '../../../Config/Config'
import styles from '../../../assets/css/AddressForm.module.css'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
// import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

function AddressForm({ type, setNewAddress, setDeliveryAddress, addressOnselect, editable_address }) {
    Geocode.setApiKey(appStatic.GOOGLE_KEY);
    Geocode.setLanguage("en");
    const user = useSelector((state) => state.rootReducer.user_data);
    const [loader, setLoader] = useState(false)
    const [address, setAddress] = useState({
        user_id: user._id || user.data._id,
        server_token: user.token || user.server_token,
        firstName: user.first_name || user.data.first_name,
        lastName: user.last_name || user.data.last_name,
        phone: '0' + user.phone || user.data.phone,
        address: '',
        address_name: user.first_name + ' ' + user.last_name || user.data.first_name + ' ' + user.data.last_name,
        city: '',
        street: '',
        note: '',
        postCode: '',
        country: '',
        latitude: '',
        longitude: ''
    })
    const [buttonStatus, setButtonStatus] = useState(false);

    useEffect(() => {
        console.log('editable_address:', editable_address);
        if (editable_address) {
            setAddress({
                // address_id: editable_address._id,
                latitude: editable_address.latitude,
                longitude: editable_address.longitude,
                address: editable_address.address,
                address_name: editable_address.address_name,
                street: editable_address.street,
                country: editable_address.country,
                city: editable_address.city,
                note: editable_address.note,
                postCode: editable_address.postcode,
                phone: editable_address.phone
            })
        }
    }, [])

    const onChangeAddress = (e) => {
        let { name, value } = e.target;
        console.log(name, value);
        if (name === 'firstName' || name === 'lastName') {
            setAddress({ ...address, [name]: value, 'address_name': address.firstName + ' ' + address.lastName });
        } else {
            setAddress({ ...address, [name]: value });
        }
    }

    // const getAddress = () => {
    //     Geocode.fromAddress(address.address).then(
    //         (response) => {
    //             const { lat, lng } = response.results[0].geometry.location;
    //             console.log(43, response.results[0]);
    //             const mainAddress = response.results[0].formatted_address;
    //             let city, state, country, postCode, street;
    //             for (let i = 0; i < response.results[0].address_components.length; i++) {
    //                 for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
    //                     switch (response.results[0].address_components[i].types[j]) {
    //                         case "locality":
    //                             city = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "administrative_area_level_1":
    //                             state = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "country":
    //                             country = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "route":
    //                             street = response.results[0].address_components[i].long_name;
    //                             break;
    //                         // case "street_number":
    //                         //     street_number = response.results[0].address_components[i].long_name;
    //                         //     break;
    //                         case "postal_code":
    //                             postCode = response.results[0].address_components[i].long_name;
    //                             break;
    //                     }
    //                 }
    //             }
    //             setAddress({
    //                 ...address,
    //                 'address': mainAddress,
    //                 // 'address_name': mainAddress,
    //                 'city': city,
    //                 'street': street,
    //                 'postCode': postCode,
    //                 'country': country,
    //                 'latitude': lat,
    //                 'longitude': lng,
    //             });

    //         },
    //         (error) => {
    //             console.error(error);
    //         }
    //     );
    // }

    useEffect(() => {
        let status = false;
        if (address.firstName === '') {
            status = false;
        } else if (address.lastName === '') {
            status = false;
        } else if (address.phone === '') {
            status = false;
            // } else if (address.houseNo === '') {
            //     status = false;
        } else if (address.address === '') {
            status = false;
        } else if (address.city === '') {
            status = false;
        } else if (address.postCode === '') {
            status = false;
        } else if (address.country === '') {
            status = false;
        } else {
            status = true;
        }
        setButtonStatus(status);
        console.log(address)
    }, [address])

    const update_address = async () => {
        setLoader(true)
        try {
            let ad = address
            ad['address_id'] = editable_address._id
            console.log('ad', ad);
            let apires = await update_favourite_address(ad);
            console.log('update_favourite_address:', apires);
            if (apires.success) {
                toast.success('Address updated Successfully', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                if (type === 'billing') {

                } else {
                    setNewAddress(false);
                    setDeliveryAddress([...apires.favourite_addresses]);
                    if (type === 'checkout') {
                        addressOnselect(apires.favourite_addresses[apires.favourite_addresses.length - 1]);
                    }
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const saveAddress = async () => {
        setLoader(true)
        try {
            let apires = await add_favourite_address(address);
            console.log(apires);
            if (apires.success) {
                toast.success('Address added Successfully', {
                    position: 'top-right',
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                if (type === 'billing') {
                    // console.log(125, apires.favourite_addresses[apires.favourite_addresses.length - 1])
                    // setAddNewAddress(false);
                    // setBillingAddress([apires.favourite_addresses[apires.favourite_addresses.length - 1]]);
                } else {
                    setNewAddress(false);
                    setDeliveryAddress([...apires.favourite_addresses]);
                    if (type === 'checkout') {
                        addressOnselect(apires.favourite_addresses[apires.favourite_addresses.length - 1]);
                    }
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const cancelAddressForm = () => {
        if (type === 'billing') {
            // setAddNewAddress(false);
        } else {
            setNewAddress(false)
        }
    }

    const { ref } = usePlacesWidget({
        apiKey: appStatic.GOOGLE_KEY,
        onPlaceSelected: (place) => {
            console.log(place);
            const { lat, lng } = place.geometry.location;
            console.log(43, lat(), lng());

            const mainAddress = place.formatted_address;
            let city, state, country, postCode, street;
            for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                    switch (place.address_components[i].types[j]) {
                        case "locality":
                            city = place.address_components[i].long_name;
                            break;
                        case "administrative_area_level_1":
                            state = place.address_components[i].long_name;
                            break;
                        case "country":
                            country = place.address_components[i].long_name;
                            break;
                        case "route":
                            street = place.address_components[i].long_name;
                            break;
                        // case "street_number":
                        //     street_number = place.address_components[i].long_name;
                        //     break;
                        case "postal_code":
                            postCode = place.address_components[i].long_name;
                            break;
                        default:
                    }
                }
            }
            setAddress({
                ...address,
                'address': mainAddress,
                // 'address_name': mainAddress,
                'city': city,
                'street': street,
                'postCode': postCode,
                'country': country,
                'latitude': lat(),
                'longitude': lng(),
            });
        },
        options: {
            //   types: ["(regions)"],
            types: ["geocode", "establishment"],
            componentRestrictions: { country: ["bd", "in", "uk", "us", "pr", "vi", "gu", "mp"] },
        },
    });

    return (
        <div className={classNames(styles.formContainer, styles[type])}>
            <div className={styles.formColumn}>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.firstName} onChange={onChangeAddress} name={'firstName'} placeholder="First Name *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.lastName} onChange={onChangeAddress} name={'lastName'} placeholder="Last Name *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} ref={ref} className={styles.inputs} value={editable_address && address.address} name={'address'} placeholder="Address Line - 1 *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.street} onChange={onChangeAddress} name={'street'} placeholder="Address Line - 2" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.city} onChange={onChangeAddress} name={'city'} placeholder="City *" autoComplete="new-password" />
                </div>

            </div>
            <div className={styles.formColumn}>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.phone} onChange={onChangeAddress} name={'phone'} placeholder="Mobile number (for delivery updates) *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <textarea className={styles.textAreas} onChange={onChangeAddress} name={'note'} placeholder="NOTE: (landmark & any instruction)" ></textarea>
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.postCode} onChange={onChangeAddress} name={'postCode'} placeholder="Postcode *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.country} onChange={onChangeAddress} name={'country'} placeholder="Country *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <button className={styles.cancelButton} onClick={cancelAddressForm}>Cancel</button>
                    {!editable_address ?
                        <button className={classNames(styles.saveButton, !buttonStatus ? styles.inactive : '')} onClick={saveAddress}>{!loader ? 'Save':'Saving...'}</button>
                        :
                        <button className={classNames(styles.saveButton, !buttonStatus ? styles.inactive : '')} onClick={update_address}>{!loader ? 'Update':'Updating...'}</button>
                    }
                </div>
            </div>
        </div>
    )
}

export default AddressForm;