import classNames from 'classnames';
import styles from '../../assets/css/Popup.module.css';
import student from '../../assets/Students.png'
const StudyPopup = ({ setMenu_popup }) => {
    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header}>
                    <p className={styles.main_title}> Study in the UK</p>
                </div>
                <div className={styles.popup_body}>
                    <div className={styles.popup_body_content}>
                        {/* <p className={styles.sub_title}>Discover Authentic Bangladeshi Hospitality in London!</p> */}
                        <div>
                            <p className={styles.body_text}>We empower Bangladeshi students to make well-informed decisions <br /> that ensure successful admission to prestigious UK universities.</p>
                        </div>
                        <div className={styles.imageContainer}>
                            <img src={student} alt='' />
                        </div>
                        <div>
                            <p className={styles.body_text}>Finding suitable <span>accommodation</span> in the UK can pose a significant challenge for international students. We specialise in helping Bangladeshi students discover <span>cost-effective, secure</span>, &amp; <span>strategically located</span> housing options for their academic journey.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudyPopup;