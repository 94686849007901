import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useCart } from "react-use-cart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IMAGE_URL } from "../../Config/Config";
import UserHeadSection from "./UserHeadSection";
import CurrencyFormat from 'react-currency-format';
import { IoBagSharp } from 'react-icons/io5';
import styles from '../../assets/css/Header.module.css';
import { get_orders, get_favourite_item_list } from '../../Utils/method';
import loader from '../../assets/loader.svg';
import { useEffect } from "react";
import Orders from "../Orders";
import ItemPrice from "../pages/Items/ItemPrice";
import classNames from "classnames";
import AddButton from "../AddButton";
import { getCurrencySign } from "../../Utils/common";
import SearchSuggest from "./SearchSuggest";
import UkEuPopup from "../popups/Uk_Eu_Popup";
import DeshiPopup from "../popups/DeshiPopup";
import TravelPopup from "../popups/TravelPopup";
import StudyPopup from "../popups/StudyPopup";
import WorkPopup from "../popups/WorkPopup";
import PropertyPopup from "../popups/PropertyPopup";
import MoneyPopup from "../popups/MoneyPopup";
import { _domain } from "../../Config/Domains";
import Currency from "./Currency";

function Header() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const currency_sign = useSelector((state) => state.rootReducer.currency_sign);
    // const stores = useSelector((state) => state.rootReducer.stores);
    const user_data = useSelector((state) => state.rootReducer.user_data);
    const [activeDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const {
        isEmpty,
        totalUniqueItems,
        cartTotal
    } = useCart();
    const [searchState, setSearchState] = useState(false);
    const [searchPopup, setSearchPopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [searchedItemList, setSearchedItemList] = useState([]);
    const [cartOption, setCartOption] = useState(false);
    const [showOrderList, setShowOrderList] = useState(false);
    const [searchLoader, setSearchLoader] = useState(false);
    const [orderList, setOrderList] = useState([]);

    const navigation = () => {
        const el = document.getElementById('left_sidebar');
        console.log(el.classList)
        const element = document.getElementById('ukeubrands');
        if (el.classList.contains('hide_nav')) {
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'end' })
            }
            el.classList.remove('hide_nav');
        } else {
            el.classList.add('hide_nav');
        }
    }

    const getAllorders = async () => {
        try {
            let apires = await get_orders();
            if (apires.success) {
                setOrderList([...apires.order_list]);
                dispatch({ type: 'set_ordersList', payload: apires.order_list });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFavourite = async () => {
        try {
            let apires = await get_favourite_item_list();
            if (apires.success) {
                let ids = [];
                for (let index = 0; index < apires.favourite_items.length; index++) {
                    const element = apires.favourite_items[index];
                    ids.push(element._id)
                }
                dispatch({ type: "set_allfavourite_items", payload: apires.favourite_items })
                dispatch({ type: "set_favourite_items", payload: ids })
            }
        } catch (error) {
            console.log(error);
        }
    }

    const gotoHome = () => {
        navigate('/')
    }

    useEffect(() => {
        if (user_data) {
            getAllorders();
            getFavourite();
        } else {
            setOrderList([]);
            dispatch({ type: "set_favourite_items", payload: [] })
        }
    }, [user_data])

    const navigateTocart = (type) => {
        console.log('activeDelivery:', activeDelivery)
        localStorage.setItem('currency_data', JSON.stringify({
            payment_currency: type,
            view_price_currency: _domain().currency,
            exchange_rate_website: activeDelivery?.conversion_rate,
        }))
        setCartOption(false);
        setSearchPopup(false)
        if (!isEmpty) navigate('/cart')
    }

    useEffect(() => {
        const handleEsc = (event) => {
            if (event.keyCode === 27) {
                if (searchPopup) {
                    setSearchPopup(false)
                }
            }
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);

    const [menu_popup, setMenu_popup] = useState('')
    const showMenuPopup = (type) => {
        setMenu_popup(type)
    }

    // const clickToNavigate = (e, type, hash) => {
    //     if (type === 'ukbd' && _domain().name === 'ukbd.app') {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         const element = document.getElementById(hash);
    //         if ((location.pathname.indexOf('/product/') > -1 || location.pathname.indexOf('/single-item/') > -1) || location.pathname === '/deshi' || location.pathname === '/travel' || location.pathname === '/study' || location.pathname === '/property') {
    //             navigate('/#ukeubrands')
    //             setTimeout(() => {
    //                 if (element) {
    //                     element.scrollIntoView({ behavior: 'smooth', block: 'end' })
    //                 }
    //             }, 200);
    //         }
    //         if (element) {
    //             element.scrollIntoView({ behavior: 'smooth', block: 'end' })
    //         }
    //     } else if (type === 'travel') {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         navigate('/' + type)
    //     } else if (type === 'property') {
    //         e.preventDefault();
    //         e.stopPropagation();
    //         navigate('/' + type)
    //     }
    // }

    const clickToNavigate = (e, type, hash) => {
        if (_domain().name === 'ukbd.app' || _domain().name === 'usbd.app') {
            e.preventDefault();
            e.stopPropagation();
            if (type === 'ukbd') {
                const element = document.getElementById(hash);
                if ((location.pathname.indexOf('/product/') > -1 || location.pathname.indexOf('/single-item/') > -1) || location.pathname === '/deshi' || location.pathname === '/travel' || location.pathname === '/study' || location.pathname === '/property') {
                    navigate('/#ukeubrands')
                    setTimeout(() => {
                        if (element) {
                            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
                        }
                    }, 200);
                }
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth', block: 'end' })
                }
            // } else if (type === 'deshi') {
            //     navigate('/' + type)
            } else if (type === 'travel') {
                navigate('/' + type)
            } else if (type === 'study') {
                navigate('/' + type)
            } else if (type === 'migration') {
                navigate('/' + type)
            } else if (type === 'property') {
                navigate('/' + type)
            }
        }
    }

    return (
        <>
            <header className={styles.mainHeader}>
                {/* Desktop Header section Start */}
                <div className={styles.head_container}>
                    <div className={styles.head_left}>
                        <Link className={styles.header_logo} to={_domain().app}>
                            <img className={styles.header_logo} src={_domain().logo} alt="Logo" />
                        </Link>
                        <div className={styles.all_menu_section}>
                            <div className={styles.home_button} onMouseOver={() => showMenuPopup('')}>
                                <span className={styles.icon_style} onClick={gotoHome}>
                                    <i className="fa-thin fa-house"></i>
                                </span>
                            </div>

                            <p className={styles.menu_title} onMouseOver={() => showMenuPopup('search')}>
                                <span className={styles.icon_style}><i className="fa-thin fa-magnifying-glass"></i></span>
                                <span className={styles.menu_label}> SEARCH</span>
                            </p>

                            <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('')} to={'https://partner.ukbd.app'} target="_blank">
                                <span className={styles.icon_style}><i className="fa-thin fa-warehouse-full"></i></span>
                                <span className={styles.menu_label}> PARTNER</span>
                            </Link>

                            <p className={styles.menu_title} onMouseOver={() => showMenuPopup('')} onClick={() => navigate('/driver/sign-up')}>
                                <span className={styles.icon_style}><i className="fa-thin fa-steering-wheel"></i></span>
                                <span className={styles.menu_label}> DRIVER</span>
                            </p>
                            {_domain().name.indexOf('ukbd') > -1 &&
                                <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('uk_eu')} onClick={(e) => { clickToNavigate(e, 'ukbd', 'ukeubrands'); showMenuPopup('') }} to={"https://ukbd.app/#ukeubrands"}> <span className={styles.menu_label}> UK & EU BRANDS</span></Link>
                            }
                            {_domain().name.indexOf('usbd') > -1 &&
                                <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('uk_eu')} onClick={(e) => { clickToNavigate(e, 'ukbd', 'ukeubrands'); showMenuPopup('') }} to={"https://usbd.app"}> <span className={styles.menu_label}> US BRANDS</span></Link>
                            }
                            {/* clickToNavigate(e, 'deshi', ''); */}
                            {_domain().name.indexOf('ukbd') > -1 && <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('deshi')} onClick={(e) => { showMenuPopup('') }} to={"https://deshi.ukbd.app"}> <span className={styles.menu_label}> DESHI</span></Link>}
                            <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('travel')} onClick={(e) => { clickToNavigate(e, 'travel', ''); showMenuPopup('') }} to={"https://host.sopnon.uk/"}> <span className={styles.menu_label}> TRAVEL</span></Link>
                            <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('study')} onClick={(e) => { clickToNavigate(e, 'study', ''); showMenuPopup('') }} to={"https://edu.ukbd.app"}> <span className={styles.menu_label}> STUDY</span></Link>
                            <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('work')} onClick={(e) => { clickToNavigate(e, 'migration', ''); showMenuPopup('') }} to={"https://job.ukbd.app"}> <span className={styles.menu_label}> JOB/SPONSORSHIP</span></Link>
                            <Link className={styles.menu_title} onMouseOver={() => showMenuPopup('property')} onClick={(e) => { clickToNavigate(e, 'property', ''); showMenuPopup('') }} to={"https://property.ukbd.app"}> <span className={styles.menu_label}> PROPERTY</span></Link>

                            {(_domain().name === 'ukbd.app' || _domain().name === 'usbd.app' || _domain().name === 'deshi.ukbd.app') ?
                                location.pathname !== '/profile' ?
                                    orderList.length > 0 &&
                                    <div className={classNames(styles.menu_section, styles.trackingSection)} onMouseOver={() => showMenuPopup('')}>
                                        <p className={styles.menu_title}> <span className={styles.menu_label}> TRACK ORDER</span></p>
                                        <div className={styles.trackingPop}>
                                            <Orders orderList={orderList} from={'head'} setShowOrderList={setShowOrderList} />
                                        </div>
                                    </div>
                                    : null
                                : null
                            }

                            {(_domain().name === 'ukbd.app' || _domain().name === 'usbd.app' || _domain().name === 'deshi.ukbd.app') ?
                                (location.pathname !== '/profile' && location.pathname.indexOf('/map') === -1) &&
                                <div className={classNames(styles.checkout_box, 'cartBoxContainer')} onMouseOver={() => showMenuPopup('')}>
                                    <p onClick={() => navigateTocart('GBP')}>
                                        <IoBagSharp color="#ff0000" size={16} style={{ position: 'relative', top: '-1px' }} />
                                        {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
                                        <span className={styles.pro_price}>
                                            <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                        </span>
                                    </p>
                                    <span className={styles.cartDropdown} style={cartOption ? { pointerEvents: 'none' } : {}} onClick={() => setCartOption(!cartOption)}>
                                        <i className="fa-thin fa-chevron-down"></i>
                                    </span>
                                    <ItemPrice type={'cart'} navigateTocart={navigateTocart} data={''} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={''} previousPrice={''} />
                                </div>
                                : null
                            }



                            <div className={styles.currency_section} onMouseOver={() => showMenuPopup('')}>
                                <Currency />
                            </div>

                            <div className={styles.account_section} onMouseOver={() => showMenuPopup('')}>
                                <UserHeadSection />
                            </div>
                        </div>

                    </div>
                </div>
                {/* Desktop Header section End */}
                {/* Mobile Header section Start */}
                <div className={styles.head_container + ' ' + styles.mobileHead}>
                    {!searchState &&
                        <div className={styles.head_left}>
                            <Link className={styles.m_logo} to={_domain().app}>
                                <img src={_domain().logo} alt="logo" />
                            </Link>


                            {_domain().name !== 'job.ukbd.app' ?
                                <div className={styles.m_search} onClick={() => setSearchPopup(true)}>
                                    <i className="fa-thin fa-magnifying-glass"></i>
                                    <span className={styles.iconLabel}>Search</span>
                                </div>
                                : null
                            }
                            {/* {location.pathname !== '/profile' ?
                                orderList.length > 0 && <div className={classNames(styles.m_search, styles.trackingSection)}>
                                    <i className="fa-thin fa-bags-shopping"></i>
                                    <span className={styles.iconLabel}>Orders</span>
                                    <div className={styles.trackingPop}>
                                        <Orders orderList={orderList} from={'head'} setShowOrderList={setShowOrderList} />
                                    </div>
                                </div>
                                : null
                            } */}
                            {/* <div className={styles.m_store}>
                            <i className="fa-thin fa-warehouse-full"></i>
                            <span className={styles.iconLabel}>Partner</span>
                        </div>
                        <div className={styles.m_driver}>
                            <i className="fa-thin fa-steering-wheel"></i>
                            <span className={styles.iconLabel}>Driver</span>
                        </div> */}
                            {/* </div>
                    }
                    {!searchState &&
                        <div className={styles.head_right}> */}
                            {/* {orderList.length > 0 && <div className={classNames(styles.menu_section, styles.trackingSection)}>
                                <p className={styles.menu_title}> <span> Orders </span></p>
                                <div className={styles.trackingPop}>
                                    <Orders orderList={orderList} from={'head'} setShowOrderList={setShowOrderList} />
                                </div>
                            </div>
                            } */}

                            {_domain().name !== 'job.ukbd.app' ?
                                (location.pathname !== '/profile' && location.pathname.indexOf('/map') === -1) &&
                                <div className={styles.checkout_box}>
                                    <p onClick={() => navigateTocart(activeDelivery.delivery_currency_code)}>
                                        <IoBagSharp color="#ff0000" size={24} style={{ position: 'relative', top: '-3px' }} />
                                        {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
                                        <span className={styles.pro_price}>
                                            <CurrencyFormat value={Number(cartTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                        </span>
                                    </p>
                                </div>
                                : null
                            }
                            <div className={styles.account_section}>
                                <UserHeadSection />
                            </div>
                            <div className={styles.m_search} onClick={navigation}>
                                <i className="fa-light fa-bars"></i>
                                <span className={styles.iconLabel}>Menu</span>
                            </div>
                        </div>
                    }
                    {/* {searchState &&
                        <div className={styles.searchSection}>
                            <span className={styles.headSearchIcon}>
                                <i className="fa-thin fa-magnifying-glass"></i>
                            </span>
                            <input type="text" className={styles.searchInput} style={searchPopup ? { pointerEvents: 'none' } : {}} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup} onFocus={searchOnFocus} placeholder="Search in UKBD" />
                            <span className={styles.headSearchIcon} onClick={() => searchValue.length > 0 ? setSearchValue('') : setSearchState(!searchState)}>
                                <i className="fa-thin fa-xmark"></i>
                            </span>
                        </div>
                    } */}
                    {/*<OutsideClickHandler onOutsideClick={() => setSearchPopup(false)}>*/}
                    {searchPopup &&
                        <div className={styles.searchSuggestionPopup}>
                            {searchLoader ?
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                </div>
                                :
                                searchedItemList.length > 0 ?
                                    searchedItemList.map((v, i) =>
                                        <div className={styles.srItemHolder} key={'sri_' + i}>
                                            <Link to={'/single-item/' + v._id} className={styles.srItem} onClick={() => setSearchValue('')}>
                                                <div className={styles.sri_image}>
                                                    <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
                                                </div>
                                                <div className={styles.sriDetails}>
                                                    <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
                                                    <div className={styles.sri_price}>
                                                        <CurrencyFormat value={v.view_price} displayType={'text'} thousandSeparator={true} prefix={_domain().currency + ' '} renderText={value => value} />
                                                    </div>
                                                </div>
                                            </Link>
                                            <div className={styles.srItemAdd}>
                                                <AddButton data={v} theme="search" />
                                            </div>
                                        </div>
                                    )
                                    : <p className={styles.notFound}>Item not found.</p>
                            }
                        </div>
                    }
                    {/* </OutsideClickHandler> */}
                </div>
                {/* Mobile Header section End */}
            </header>
            {/* {(searchPopup) && <SearchSuggest setSearchPopup={setSearchPopup} />} */}
            {menu_popup === 'search' && <SearchSuggest setMenu_popup={setMenu_popup} />}
            {menu_popup === 'uk_eu' && <UkEuPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'deshi' && <DeshiPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'travel' && <TravelPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'study' && <StudyPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'work' && <WorkPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'property' && <PropertyPopup setMenu_popup={setMenu_popup} />}
            {menu_popup === 'money' && <MoneyPopup setMenu_popup={setMenu_popup} />}
            {/* <OutsideClickHandler onOutsideClick={(prev) => setShow(!prev)}>
                {(!isEmpty && show) && <CartingSection setShow={setShow} />}
            </OutsideClickHandler> */}
        </>
    )
}

export default Header;