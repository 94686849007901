import styles from '../../../assets/css/ProductPreview.module.css'

const ItemDescription = ({ data, setDescription }) => {
    function containsHTMLTags(inputString) {
        var htmlTagRegex = /<[^>]*>/;
        return htmlTagRegex.test(inputString);
    }

    return (
        <div className={styles.review_popup}>
            <span className={styles.closeCart} onClick={() => setDescription(false)}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.review_container}>
                {containsHTMLTags(data.details[0]) ?
                    <div className={styles._description} dangerouslySetInnerHTML={{ __html: data.details }} />
                    :
                    <textarea className={styles.text_area_view} value={data.details} disabled></textarea>
                }
            </div>
        </div>
    )
}

export default ItemDescription;