import React from 'react'
import styles from '../assets/css/Header.module.css'
import { useSelector } from "react-redux";
import Moment from 'react-moment';
// import 'moment-timezone';
// import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { useNavigate } from "react-router-dom";
import classNames from 'classnames';
// import { get_orders } from '../Utils/method'
import { priceConvertTo, getCurrencySign } from '../Utils/common'

export default function Orders(props) {
    const currency_sign = useSelector((state) => state.rootReducer.currency_sign);
    const navigate = useNavigate();

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    return (
        <div className={classNames(styles.OrderListPopup, props.from === 'map' ? styles.orderListMap : styles.orderListHead)}>
            {props.orderList.map((v) =>
                <div className={styles.orderItem} onClick={() => { navigate('/map/track-order/' + v._id) }} key={v._id}>
                    {props.from === 'map' && <p className={styles.orderId} style={props.from === 'map' ? { width: '39%' } : {}}>{v._id}</p>}
                    <p className={styles.orderDate}><Moment date={v.created_at} format="ddd, MMM Do YYYY, hh:mm A" /></p>
                    <p className={styles.orderPrice}>
                        {v.payment_currency === undefined || v.payment_currency === '' ?
                            <CurrencyFormat value={v.total} displayType={'text'} thousandSeparator={true} prefix={currency_sign + ' '} renderText={value => value} />
                            :
                            v.payment_currency === 'GBP' ?
                                <CurrencyFormat value={v.total.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign('GBP') + ' '} renderText={value => <span className={styles.uk_price}>{fractionCheck(value)}</span>} />
                                :
                                <CurrencyFormat value={priceConvertTo(v.total, v)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(v.payment_currency) + ' '} renderText={value => value} />
                        }
                    </p>
                </div>
            )}
        </div>
    )
}
