import React from "react";
import { Link } from "react-router-dom";
function Store({ data, expand, setExpand }) {
    return (
        <div className="main_category_container" onClick={() => { setExpand(!expand) }}>
            {/* <Link to={'/category/'+data._id} className="title" >{data.name} </Link> */}
            <p className="title" >{data.name} </p>
            <p onClick={() => { setExpand(!expand) }} className={expand ? 'active icon_p' : 'icon_p'}>
                <i className="fa-thin fa-chevron-down"></i>
            </p>
        </div>
    )
}

export default Store;