import styles from '../../../assets/css/ProductPreview.module.css'
import { useState } from 'react'
import ReactImageZoom from 'react-image-zoom';
import { IMAGE_URL } from "../../../Config/Config";

const ImagePreview = ({all_data, imgSrc, setImgPreview }) => {
    const props = {width: 800 ,zoomPosition:'right',zoomWidth:500, img: imgSrc};

    const [scale, setScale] = useState(1);
    const [active_src, setActive_src] = useState(imgSrc);


  const handleWheel = (event) => {
    event.preventDefault();
    const zoomStep = 0.1;
    const newScale = event.deltaY < 0 ? scale + zoomStep : scale - zoomStep;
    setScale(newScale > 0.5 ? newScale : 0.5); // Prevent scaling below 0.5
  };

    return (
        <div className={styles.review_popup}>
            <span className={styles.closeCart} onClick={() => setImgPreview(false)}><i className="fa-thin fa-xmark"></i></span>
            {all_data.length > 1 &&
            <div className={styles.mini_item_container}>
                {all_data.map(v =>
                    <img className={styles.mini_image} onClick={() => { setActive_src(IMAGE_URL + v)}} src={IMAGE_URL + v} alt='' />
                )}
            </div>
            }
            <div className={styles.review_container}>
                <div className={styles.img_previewContainer} onWheel={handleWheel}
                  style={{
                    transform: `scale(${scale})`,
                    transition: 'transform 0.3s ease',
                    cursor: 'zoom-in',
                  }}
                >
                    <img className={styles._image_view} src={active_src} alt='Preview' />
                    {/*<ReactImageZoom {...props} />*/}
                </div>
            </div>
        </div>
    )
}

export default ImagePreview;