import React from "react";
import { useSelector } from 'react-redux'
import Group from "./SidebarItems/Group";
import Deliveries from "./SidebarItems/Deliveries";
// import OutsideClickHandler from 'react-outside-click-handler';
import Loader from "../Loader";
// import { getPopularItems } from "../../Utils/method";
// import { useNavigate, useParams } from "react-router-dom";
// import { ImGift } from "react-icons/im";
// import { TiHeartFullOutline } from "react-icons/ti";
// import { isMobile } from "react-device-detect";
// import FilterOption from "../pages/Items/FilterOption";


function Sidebar() {
    // const dispatch = useDispatch();
    // const params = useParams();
    // const navigate = useNavigate();
    // const stores = useSelector((state) => state.rootReducer.stores);
    // const active_item = useSelector((state) => state.rootReducer.active_item);
    const product_groups = useSelector((state) => state.rootReducer.product_groups);
    const side_bar_loader = useSelector((state) => state.rootReducer.home_loader);
    const footer_text = useSelector((state) => state.rootReducer.website_setting.footer_text);

    const navigation = () => {
        const el = document.getElementById('left_sidebar');
        console.log(el.classList)
        if (!el.classList.contains('hide_nav')) {
            el.classList.add('hide_nav');
        }
    }

    // <OutsideClickHandler onOutsideClick={navigation}>
    // 
    return (
        <aside className="left_sidebar hide_nav" id="left_sidebar">
            <div className="left_inner">
                <div className="aside_container">
                    <Deliveries />
                    {/* <div className="top_category">
                        {isMobile && <div className={"topCate_products"} onClick={() => { navigate('/'); navigation() }}>
                            <div className="fav_img_holder">
                                <i className="fa-thin fa-house"></i>
                            </div>
                            <div className="top_items">Home</div>
                        </div>}
                        {isMobile && <div className={"topCate_products"} onClick={() => { dispatch({ type: 'set_mobile_filter', payload: true }); navigation() }}>
                            <div className="fav_img_holder">
                                <i className="fa-thin fa-sliders-simple"></i>
                            </div>
                            <div className="top_items">Filter</div>
                        </div>}
                        {(isMobile && user) && <div className={"topCate_products"} onClick={() => { dispatch({ type: 'set_mobile_orders', payload: true }); navigation() }}>
                            <div className="fav_img_holder">
                                <i className="fa-thin fa-bags-shopping"></i>
                            </div>
                            <div className="top_items">Orders</div>
                        </div>}
                        <div className={active_item === "popular" ? "active topCate_products" : "topCate_products"} onClick={updateDocs}>
                            <div className="fav_img_holder">
                                <TiHeartFullOutline color="#012965" size={20} />
                            </div>
                            <Link to="/popular-products">Popular Products</Link>
                        </div>

                        {side_bar_loader ? '' : product_groups.filter(t => t.name === 'Exclusive & Luxury').map((v, k) =>
                            v.products.length === 1 && v.products[0].name === 'Exclusive & Luxury' ?
                                <div className={params._id === v.products[0]._id ? "active topCate_products" : "topCate_products"} key={v._id}>
                                    <div className="fav_img_holder">
                                        <ImGift color="#012965" />
                                    </div>
                                    <Link to={"/product/" + v.products[0]._id}>Exclusive & Luxury</Link>
                                </div> : ''
                        )}

                        {!isMobile && <FilterOption />}

                    </div> */}
                    <div className="category_list">
                        {side_bar_loader ? <Loader /> : product_groups.filter(t => t.name != 'Exclusive & Luxury').map((v, k) => <Group data={v} navigation={navigation} key={k} />)}
                    </div>
                </div>
                <div className="aside_foot">
                    <p>{new Date().getFullYear()} &#169; {footer_text}</p>
                </div>
            </div>
            <div className="hide_hendler" onClick={navigation}> </div>
        </aside>
    )
}
// {/* </OutsideClickHandler> */}

export default Sidebar;