/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { CardElement, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js";
import styles from '../../../assets/css/Checkout.module.css';
import { useCart } from "react-use-cart";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import visa from '../../../assets/images/pay/visa.png'
import mastercard from '../../../assets/images/pay/masterCard.png'
import {
  add_card,
  pay_order_payment,
  create_order,
  getUniqCartId,
  get_card_list,
  select_card,
} from "../../../Utils/method";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import classNames from "classnames";
// import Stripe from 'stripe';

export default function CheckoutForm(props) {
  const { emptyCart } = useCart();
  const deliveryAddress = useSelector((state) => state.rootReducer.selectedDLAddress);
  console.log('deliveryAddress:', deliveryAddress);
  let currency_data = JSON.parse(localStorage.getItem("currency_data"));
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
    );
    // const stripe_new = new Stripe(clientSecret);

  // console.log('stripe:', stripe);

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  useEffect(() => {
    if (!stripe) {
      return;
    }

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!stripe || !elements) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      setIsLoading(true);

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: {
          address: {},
          name: deliveryAddress.firstName + " " + deliveryAddress.lastname,
          phone: deliveryAddress.phone,
        },
      });

      console.log('paymentMethod: ', paymentMethod);

      let add_card_res = await add_card({
        payment_method: paymentMethod.id,
        expiry_month: paymentMethod.card.exp_month,
        expiry_year: paymentMethod.card.exp_year,
        payment_id: props.paymentGetWay._id,
        last_four: paymentMethod.card.last4,
        card_type: paymentMethod.card.brand,
      });

      console.log('add_card_res:', add_card_res);
      if (!add_card_res.success) {
        toast.error(add_card_res.error_code, { autoClose: 1000 });
        return;
      }

      await select_card(add_card_res.card._id)

      let pay = await pay_order_payment({
        is_payment_mode_cash: false,
        order_payment_id: props.invoice.order_payment._id,
        payment_mode: "card",
        payment_id: props.paymentGetWay._id,
        payment_currency: currency_data.payment_currency,
        view_price_currency: currency_data.view_price_currency,
        exchange_rate_website: currency_data.exchange_rate_website,
      });
      console.log(94, pay);

      var is_payment_paid = pay.is_payment_paid;
      props.invoice["destination_addresses"] = deliveryAddress;
      console.log(is_payment_paid);
      let order;
      if (is_payment_paid) {
        order = await create_order({
          ...props.invoice,
          payment_currency: currency_data.payment_currency,
          view_price_currency: currency_data.view_price_currency,
          exchange_rate_website: currency_data.exchange_rate_website,
        });
        console.log(order);
      } else {
        let client_secret = pay.client_secret;
        let payment_method = pay.payment_method;
        let payment_intent_id = pay.payment_intent_id;
        if (client_secret && payment_method) {
          // var client_secret = res_data.data.client_secret;
          console.log(133, elements.getElement(CardElement))
          await stripe
            .confirmCardPayment(client_secret, {
              // payment_method: payment_method,
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  // address: deliveryAddress,
                  name: deliveryAddress.firstName + " " + deliveryAddress.lastname,
                  // phone: deliveryAddress.phone,
                },
              },
            })
            .then(async (result) => {
              console.log(result);
              if (result.error) {

              } else {
                order = await create_order({
                  ...props.invoice,
                  payment_currency: currency_data.payment_currency,
                  view_price_currency: currency_data.view_price_currency,
                  exchange_rate_website: currency_data.exchange_rate_website,
                });
                console.log(order);
              }
            });
        } else if (payment_intent_id.includes("ch_")) {
          order = await create_order({
            ...props.invoice,
            payment_currency: currency_data.payment_currency,
            view_price_currency: currency_data.view_price_currency,
            exchange_rate_website: currency_data.exchange_rate_website,
          });
          console.log(order);
        }
      }
      console.log(order);
      if (order.success) {
        console.log("order", order);
        console.log("invoice", props.invoice);
        toast.success(order.status_phrase, { autoClose: 1000 });
        //let clear = await clear_cart({cart_id:'cart_id'});
        getUniqCartId(false);
        emptyCart();
        dispatch({ type: "set_lastOrder", payload: order });
        // dispatch({ type: "set_timelineStage", payload: 3 });
        localStorage.removeItem('total_cart_amount');
        navigate('/payment-success', { state: { parameter: 'Test parameter data' } })
      }
      console.log(pay);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      if (error.type === "card_error" || error.type === "validation_error") {
        setMessage(error.message);
      } else {
        setMessage("An unexpected error occurred.");
      }
      setIsLoading(false);
    }
  };

  const pay_now = async () => {
    try {
      setIsLoading(true);
      // const paymentMethod = await stripe_new.paymentMethods.retrieve(
      //   props.confirm_button ? props.sCard[0].payment_token : '',
      //   {
      //     apiKey: clientSecret
      //   }
      // );
      // console.log('paymentMethod:', paymentMethod);


      let pay = await pay_order_payment({
        is_payment_mode_cash: false,
        order_payment_id: props.invoice.order_payment._id,
        payment_mode: "card",
        payment_id: props.paymentGetWay._id,
        payment_currency: currency_data.payment_currency,
        view_price_currency: currency_data.view_price_currency,
        exchange_rate_website: currency_data.exchange_rate_website,
      });
      console.log(94, pay);

      var is_payment_paid = pay.is_payment_paid;
      props.invoice["destination_addresses"] = deliveryAddress;
      console.log(is_payment_paid);
      let order;
      if (is_payment_paid) {
        order = await create_order({
          ...props.invoice,
          payment_currency: currency_data.payment_currency,
          view_price_currency: currency_data.view_price_currency,
          exchange_rate_website: currency_data.exchange_rate_website,
        });
        console.log(order);
      } else {
        let client_secret = pay.client_secret;
        let payment_method = pay.payment_method;
        let payment_intent_id = pay.payment_intent_id;
        if (client_secret && payment_method) {
          await stripe
            .confirmCardPayment(client_secret, {
              payment_method: props.confirm_button ? props.sCard[0].payment_id : '',
              payment_method_options: {
                card: {
                  token: props.confirm_button ? props.sCard[0].payment_token : '',
                },
              },
              // payment_method: {
              //   card: {
              //     token: props.confirm_button ? props.sCard[0].payment_token : '',
              //   },
              //   billing_details: {
              //     // address: deliveryAddress,
              //     name: deliveryAddress.firstName + " " + deliveryAddress.lastname,
              //     // phone: deliveryAddress.phone,
              //   },
              // },
            })
            .then(async (result) => {
              console.log(result);
              if (result.error) {

              } else {
                order = await create_order({
                  ...props.invoice,
                  payment_currency: currency_data.payment_currency,
                  view_price_currency: currency_data.view_price_currency,
                  exchange_rate_website: currency_data.exchange_rate_website,
                });
                console.log(order);
              }
            });
        } else if (payment_intent_id.includes("ch_")) {
          order = await create_order({
            ...props.invoice,
            payment_currency: currency_data.payment_currency,
            exchange_rate_website: currency_data.exchange_rate_website,
            view_price_currency: currency_data.view_price_currency,
          });
          console.log(order);
        }
      }
      console.log(order);
      if (order.success) {
        console.log("order", order);
        console.log("invoice", props.invoice);
        toast.success(order.status_phrase, { autoClose: 1000 });
        //let clear = await clear_cart({cart_id:'cart_id'});
        getUniqCartId(false);
        emptyCart();
        dispatch({ type: "set_lastOrder", payload: order });
        // dispatch({ type: "set_timelineStage", payload: 3 });
        localStorage.removeItem('total_cart_amount');
        navigate('/payment-success', { state: { parameter: 'Test parameter data' } })
      }
      console.log(pay);

      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  }

  const [loader, setLoader] = useState(props.confirm_button ? true : false)
  const [s_card, setS_card] = useState('')
  const [card_list, setCard_list] = useState([])
  const [default_card, setDefault_card] = useState([])
  const selectCard = async (id) => {
    try {
      setLoader(true)
      setS_card(id)
      let apires = await select_card(id)
      console.log(86, apires);
      setDefault_card([apires.card])
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  const get_cards = async () => {
    try {
      setLoader(true)
      let apires = await get_card_list()
      console.log('get_card_list:', apires);
      if (apires.success) {
        setS_card(apires.cards.filter(f => f.is_default)[0]._id)
        setDefault_card(apires.cards.filter(f => f.is_default))
        setCard_list(apires.cards)
      } else {
        setDefault_card([])
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (props.confirm_button) {
      get_cards()
    }
  }, [])

  if (props.confirm_button) {
    return (
      loader ?
        <Loader />
        :
        <div id="payment-form">
          <div className={styles.saved_cards} style={{ height: 'calc(100vh - 443px)' }}>
            {card_list.map(v =>
              <div className={styles.card_item} key={v._id}>
                <div className={styles.card_left} onClick={() => selectCard(v._id)}>
                  <div className={styles.stdmethod_mark}>
                    <span className={classNames(styles.markerDot, s_card === v._id && styles.active)}></span>
                  </div>
                  <div className={styles.card_logo}>
                    <img src={v.card_type === 'visa' ? visa : v.card_type === 'mastercard' ? mastercard : ''} alt='card' />
                  </div>
                  <div className={styles.card_details}>
                    <p>**** {v.last_four}, Exp. {v.card_expiry_date}</p>
                    {/* <p>{v.card_holder_name}</p> */}
                  </div>
                </div>
                <div className={styles.card_right}>
                  {/*<span style={{ color: '#ff0000' }} onClick={() => deleteCard(v._id)}><i className="fa-thin fa-trash-can"></i></span>*/}
                </div>
              </div>
            )}
          </div>
          <button style={{ backgroundColor: '#012965' }} id="submit" onClick={pay_now}>
            <span id="button-text">
              {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
            </span>
          </button>
        </div>
    )
  } else {
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        {/* <ToastContainer newestOnTop /> */}
        <CardElement options={CARD_ELEMENT_OPTIONS} id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit" style={{ backgroundColor: '#012965' }}>
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    );
  }
}
