import classNames from 'classnames';
import styles from '../../assets/css/Popup.module.css';
const TravelPopup = ({ setMenu_popup }) => {
    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header}>
                    <p className={styles.main_title}> Find Your Host</p>
                </div>
                <div className={styles.popup_body}>
                    <div className={styles.popup_body_content}>
                        <p className={styles.sub_title}>Discover Authentic Bangladeshi Hospitality in London!</p>
                        <p className={styles.body_text}>Connects Bangladeshi visitors with accommodating Bangladeshi hosts. Experience London's vibrant atmosphere while staying with Bangladeshi hosts who understand your needs, language, and traditions. A personalised connection in London, offering a warm welcome, insider tips, and a genuine cultural exchange for Bangladeshi visitors.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TravelPopup;