import React from "react";
import { useSelector } from 'react-redux';
// import {user_get_legal} from "../../../Utils/method"
import styles from '../../../assets/css/About.module.css';
import { useNavigate } from "react-router-dom";
function About() {
    const navigate = useNavigate();
    const about_us = useSelector((state) => state.rootReducer.website_setting.about_us)

    return (
        <div className={styles.AboutContainer}>
            <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.aboutUs} dangerouslySetInnerHTML={{ __html: about_us }}></div>
        </div>
    )
}

export default About;