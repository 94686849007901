const baseURL = process.env.REACT_APP_baseURL
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL
const appStatic = {
    country: "Bangladesh",
    address:"Dhaka, Bangladesh",
    city:"Dhaka District",
    latitude: 23.810332,
    longitude: 90.4125181,
    IS_USE_AWS : true,
    GOOGLE_KEY: 'AIzaSyA0j_iI4ofSPVmpFotExikp-7YzCzqjOYE',
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    FIREBASE_CONFIG : {
        apiKey: "AIzaSyA0j_iI4ofSPVmpFotExikp-7YzCzqjOYE",
        authDomain: "lithe-record-248412.firebaseapp.com",
        databaseURL: "https://lithe-record-248412-default-rtdb.firebaseio.com",
        projectId: "lithe-record-248412",
        storageBucket: "lithe-record-248412.appspot.com",
        messagingSenderId: "992368544641",
        appId: "1:992368544641:web:8eda65b603031673aa7680"
    }
}
const store_Registration = 'https://store.ukbd.uk/store/register'
export {
    baseURL,
    appStatic,
    store_Registration,
    IMAGE_URL
}; 