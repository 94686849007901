import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import styles from '../../../assets/css/Profile.module.css'
import { useNavigate } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import AddressForm from '../Checkout/AddressForm';
import Moment from 'react-moment';
import 'moment-timezone';
import CurrencyFormat from 'react-currency-format';
import { getUserData, user_update, delete_save_cart, get_detail, get_favourite_item_list, remove_favourite_item, get_save_carts, AddToCart, get_single_save_cart } from '../../../Utils/method'
import { useDispatch, useSelector } from 'react-redux';
import DeactivatePopup from './DeactivatePopup';
import { getCurrencySign } from '../../../Utils/common';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { IMAGE_URL } from '../../../Config/Config';
import AddButton from '../../AddButton';
import { useCart } from 'react-use-cart';
import Loader from '../../Loader';
import SignoutPopup from './SignoutPopup';
import { isMobile } from 'react-device-detect';
import StudyApplication from './StudyApplications';
import JobApplication from './JobApplications';
import SponsorshipApplication from './SponsorshipApplication';
import AccomodationApplications from './AccomodationApplications';
import DocumentPreview from './DocumentPreview';
import { _domain } from '../../../Config/Domains';

// import Orders from '../../Orders';

function Profile(props) {
    const {
        addItem,
        items,
        inCart,
        updateItemQuantity
    } = useCart();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const stores = useSelector((state) => state.rootReducer.stores);
    const city = useSelector((state) => state.rootReducer.city);
    const country = useSelector((state) => state.rootReducer.country);
    // const currency_sign = useSelector((state) => state.rootReducer.currency_sign);
    const ordersList = useSelector((state) => state.rootReducer.ordersList);
    const user_data = useSelector((state) => state.rootReducer.user_data);
    const allfavourite_items = useSelector((state) => state.rootReducer.allfavourite_items);
    const favourite_items = useSelector((state) => state.rootReducer.favourite_items);

    const [header, setHeader] = useState('Order History');
    const [activeTab, setActiveTab] = useState('order');
    const [newAddress, setNewAddress] = useState(false);
    const [apiLoader, setApiLoader] = useState(false)
    const [infoBtn, setInfoBtn] = useState(false)
    const [emailBtn, setEmailBtn] = useState(false)
    const [passwordBtn, setPasswordBtn] = useState(false)
    const [deactivePopup, setDeactivePopup] = useState(false)
    const [signoutPopUp, setSignoutPopUp] = useState(false)
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [shoppingBags, setShoppingBags] = useState([]);
    const [document, setDocument] = useState(false)
    const [_file, set_File] = useState('')
    
    const [errMsg, setErrMsg] = useState({
        email: '',
        conf_email: '',
        password: '',
        conf_password: ''
    })

    const [email_marketing, setEmail_marketing] = useState(false)
    const [personalDetail, setPersonalDetail] = useState({
        first_name: '',
        last_name: '',
        old_password: ''
    })

    const [emailAddress, setEmailAddress] = useState({
        email: '',
        conf_email: '',
        old_password: ''
    })

    const [passwordChange, setPasswordChange] = useState({
        new_password: '',
        conf_password: '',
        old_password: ''
    })

    const userUpdateOnChange = (e) => {
        let { name, value } = e.target;

        if (name === 'email') {
            if (value !== '' && !isEmail(value.trim())) {
                console.log(41, 'It\'s not Email.')
                setErrMsg({ ...errMsg, email: 'Enter valid email address' });
            } else {
                setErrMsg({ ...errMsg, email: '' });
            }
        } else if (name === 'conf_email') {
            if (value !== '' && (emailAddress.email.trim() !== value.trim())) {
                console.log(41, 'It\'s not Email.')
                setErrMsg({ ...errMsg, conf_email: 'The email address didn\'t match' });
            } else {
                setErrMsg({ ...errMsg, conf_email: '' });
            }
        }
        setEmailAddress({ ...emailAddress, [name]: value })
    }

    useEffect(() => {
        let status = false;
        if (emailAddress.email === '' || !isEmail(emailAddress.email)) {
            status = false
        } else if (emailAddress.conf_email === '' || (emailAddress.email !== emailAddress.conf_email)) {
            status = false
        } else if (emailAddress.old_password === '') {
            status = false
        } else {
            status = true;
        }
        setEmailBtn(status)
    }, [emailAddress])

    useEffect(() => {
        let status = false;
        if (personalDetail.first_name === '' || !isNaN(personalDetail.first_name.charAt(0))) {
            status = false
        } else if (personalDetail.last_name === '' || !isNaN(personalDetail.last_name.charAt(0))) {
            status = false
        } else if (personalDetail.old_password === '') {
            status = false
        } else {
            status = true;
        }
        setInfoBtn(status)
    }, [personalDetail])

    const passwordOnChange = (e) => {
        let { name, value } = e.target;
        if (name === 'conf_password') {
            if (value !== '' && (passwordChange.new_password !== value)) {
                setErrMsg({ ...errMsg, 'conf_password': 'Password didn\'t match' })
            } else {
                setErrMsg({ ...errMsg, 'conf_password': '' })
            }
        }
        setPasswordChange({ ...passwordChange, [name]: value })
    }

    useEffect(() => {
        let status = false;
        if (passwordChange.old_password === '') {
            status = false
        } else if (passwordChange.new_password === '') {
            status = false
        } else if (passwordChange.conf_password === '') {
            status = false
        } else {
            status = true;
        }
        setPasswordBtn(status)
    }, [passwordChange])


    // is_email_marketing
    // /store/add_new_provider

    const updateUser = async (data) => {
        try {
            setApiLoader(true);
            console.log(33, data)
            if (activeTab === 'email') delete data.conf_email;
            if (activeTab === 'password') delete data.conf_password;
            console.log(155, data)
            let apires = await user_update({ ...user_data, ...data });
            console.log(34, apires);
            setApiLoader(false);
            if (apires.success) {
                getUserData({ data: apires.user, type: 'set' });
                dispatch({ type: 'set_user_data', payload: apires.user });
                toast.success(apires.status_phrase, { autoClose: 1000 });
            } else {
                toast.error(apires.status_phrase, { autoClose: 1000 });
            }
        } catch (error) {
            setApiLoader(false);
            console.log(error)
        }
    }

    const getSaved_carts = async () => {
        try {
            let data = {
                user_id: user_data._id,
                server_token: user_data.server_token
            }
            let apires = await get_save_carts(data);
            console.log('get_save_carts :', apires);
            if (apires.success) {
                setShoppingBags(apires.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getFavourite = async () => {
        try {
            let apires = await get_favourite_item_list();
            // console.log('set_favourite_items :', apires.favourite_items);
            if (apires.success) {
                let ids = [];
                for (let index = 0; index < apires.favourite_items.length; index++) {
                    const element = apires.favourite_items[index];
                    ids.push(element._id)
                }
                dispatch({ type: "set_allfavourite_items", payload: apires.favourite_items })
                dispatch({ type: "set_favourite_items", payload: ids })
            }
            getSaved_carts()
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const getDetails = async () => {
            try {
                let apires = await get_detail();
                setDeliveryAddress([...apires.user.favourite_addresses]);
                setEmail_marketing(apires.user.is_email_marketing !== undefined ? apires.user.is_email_marketing : false)
                console.log('get_detail', apires);
                getFavourite()
            } catch (error) {
                console.log(167, error)
            }
        }
        getDetails()

    }, [])

    const removeFavourite = async (v) => {
        console.log(v);
        try {
            let data = {
                user_id: user_data._id,
                item_id: v._id,
            }
            let apires = await remove_favourite_item(data);
            console.log(53, apires);
            if (apires.success) {
                let d = allfavourite_items.filter(f => f._id !== v._id)
                let d2 = favourite_items.filter(f => f !== v._id)
                dispatch({ type: "set_allfavourite_items", payload: d });
                dispatch({ type: "set_favourite_items", payload: d2 });
                toast.success('Item removed from your favourite list.', { autoClose: 1000 });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const [activeBag, setActiveBag] = useState('')
    const [bagItems, setBagItems] = useState([])
    const [savedLoader, setSavedLoader] = useState(false)

    const get_single_cart = async (id) => {
        setActiveBag(id)
        setSavedLoader(true)
        try {
            let data = {
                user_id: user_data._id,
                save_cart_id: id,
                server_token: user_data.server_token
            }
            let apires = await get_single_save_cart(data)
            setBagItems(apires.items)
            setSavedLoader(false)
        } catch (error) {
            setSavedLoader(false)
            console.log(error);
        }
    }

    const removeShoppingBag = async (id) => {
        try {
            let data = {
                user_id: user_data._id,
                save_cart_id: id,
                server_token: user_data.server_token
            }

            await delete_save_cart(data)
            // console.log(apires);
            setShoppingBags(shoppingBags.filter(f => f._id !== id))
            toast.success('Delete Shopping Bag successfully.', { autoClose: 1000 });
        } catch (error) {
            console.log(error);
        }
    }

    const addtoCartthisItem = async (data, id, q) => {
        try {
            const product_details = stores.filter(v => v._id === data.store_id)[0].products.filter(v => v._id === data.product_id)[0]
            const store_details = stores.filter(v => v._id === data.store_id)[0]
            // addItem({ ...data, id: data._id, price: data.price, product_details: product_details }, q)
            if (q === 'initial') {
                addItem({ ...data, id: data._id, price: data.price, product_details: product_details },)
            } else {
                updateItemQuantity(id, q)
            }
            await AddToCart({ items: items.filter(v => v.id !== data._id), item: data, store_details, product_details, city, country, q: q === 'initial' ? 1 : q })
        } catch (error) {
            toast.error(error.status_phrase, { autoClose: 1000 });
            console.log(error);
        }
    }

    const addItemsToCart = async (v) => {
        try {
            let data = {
                user_id: user_data._id,
                save_cart_id: v._id,
                server_token: user_data.server_token
            }
            let apires = await get_single_save_cart(data)
            console.log(apires.items);
            let items = apires.items;
            for (let i = 0; i < items.length; i++) {
                let element = items[i];
                if (inCart(element._id)) {
                    console.log(330, 11);
                    addtoCartthisItem(element, element._id, 1)
                } else {
                    console.log(330, 22);
                    addtoCartthisItem(element, element._id, 'initial')
                }
            }
            toast.success('Bag items added to your cart successfully.', { autoClose: 1000 });
        } catch (error) {
            console.log(error);
        }
    }

    const [mobile_menu, setMobile_menu] = useState(false)

    const closeSection = () => {
        if (mobile_menu) {
            setMobile_menu(false)
        } else {
            navigate(-1)
        }
    }

    const selectTab = (type, head) => {
        setHeader(head)
        setActiveTab(type)
        setMobile_menu(false)
    }

    return (
        <div className={styles.profileContainer}>
            <ToastContainer newestOnTop />
            <div className={styles.innerContainer}>

                <div className={classNames(styles.tabLeft, mobile_menu && styles._active)}>
                    <div className={classNames(styles.tabs, activeTab === 'order' ? styles.active : '')} onClick={() => selectTab('order', 'Order History')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-list"></i></span>
                        <span className={styles.tabName}> Order History </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'saved' ? styles.active : '')} onClick={() => selectTab('saved', 'Saved items')}>
                        <span className={styles.tabIcon}><i className="fa-solid fa-heart" style={{ color: '#012965' }}></i></span>
                        <span className={styles.tabName}> Saved items ({allfavourite_items.length}) </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'bags' ? styles.active : '')} onClick={() => selectTab('bags', 'Saved Shopping Bags')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-bag-shopping"></i></span>
                        <span className={styles.tabName}> Shopping Bags ({shoppingBags.length}) </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'info' ? styles.active : '')} onClick={() => selectTab('info', 'Personal details')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-square-info"></i></span>
                        <span className={styles.tabName}> Personal details </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'email' ? styles.active : '')} onClick={() => selectTab('email', 'Email Address')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-envelope"></i></span>
                        <span className={styles.tabName}> Email Address </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'job' ? styles.active : '')} onClick={() => selectTab('job', 'Job Application')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-briefcase"></i></span>
                        <span className={styles.tabName}> Job Application </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'sponsor' ? styles.active : '')} onClick={() => selectTab('sponsor', 'Sponsorship')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-briefcase"></i></span>
                        <span className={styles.tabName}> Sponsorship </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'study' ? styles.active : '')} onClick={() => selectTab('study', 'Admission')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-school"></i></span>
                        <span className={styles.tabName}> Admission </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'accomodation' ? styles.active : '')} onClick={() => selectTab('accomodation', 'Accomodation')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-school"></i></span>
                        <span className={styles.tabName}> Accomodation </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'password' ? styles.active : '')} onClick={() => selectTab('password', 'Password')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-lock"></i></span>
                        <span className={styles.tabName}> Password </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'address' ? styles.active : '')} onClick={() => selectTab('address', 'Address Book')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-location-dot"></i></span>
                        <span className={styles.tabName}> Address Book </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'cards' ? styles.active : '')} onClick={() => selectTab('cards', 'Saved Cards')}>
                        <span className={styles.tabIcon}><i className="fa-duotone fa-credit-card"></i></span>
                        <span className={styles.tabName}> Saved Cards </span>
                    </div>
                    <div className={classNames(styles.tabs, activeTab === 'marketing' ? styles.active : '')} onClick={() => selectTab('marketing', 'Marketing Preferences')}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-envelope-open-text"></i></span>
                        <span className={styles.tabName}> Marketing Preferences </span>
                    </div>
                    <div className={classNames(styles.tabs, styles.deleteAccount)} onClick={() => { setDeactivePopup(true); setMobile_menu(false) }}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-user-slash"></i></span>
                        <span className={styles.tabName}> Delete Account </span>
                    </div>
                    <div className={classNames(styles.tabs, styles.signOut)} onClick={() => { setSignoutPopUp(true); setMobile_menu(false) }}>
                        <span className={styles.tabIcon}><i className="fa-thin fa-arrow-right-from-bracket"></i></span>
                        <span className={styles.tabName}> Sign Out </span>
                    </div>
                </div>
                <div className={styles.profile_mobile_menu}>
                    <span className={styles.icon_style} onClick={() => setMobile_menu(!mobile_menu)}>
                        <i className="fa-light fa-bars"></i>
                    </span>
                    <div className={styles.mobile_menu_title}>
                        <h2>{header}</h2>
                    </div>
                    <span className={styles.closeCart} onClick={closeSection}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <div className={styles.tabRight}>
                    {!isMobile &&
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: 40 }} >
                            <span className={styles.closeCart} onClick={() => navigate(-1)}>
                                <i className="fa-thin fa-xmark"></i>
                            </span>
                        </div>
                    }
                    <div className={classNames(styles.tabBody, styles[activeTab])}>
                        {
                            activeTab === 'order' ?
                                <div className={classNames(styles.tabBodyInner, styles.orderSection)}>
                                    <div className={styles.orderItem}>
                                        <p className={styles.orderCounter}>Sl.</p>
                                        <p className={styles.orderDate}>Date</p>
                                        <p className={styles.orderPrice}>Amount</p>
                                        <p className={styles.orderId}>ID</p>
                                    </div>
                                    <div className={styles.profileOrdersCont}>
                                        {
                                            ordersList.length > 0 ?
                                                ordersList.map((v, i) =>
                                                    <div className={styles.orderItem} onClick={() => { navigate('/map/track-order/' + v._id) }} key={v._id}>
                                                        <p className={styles.orderCounter}>{i + 1}.</p>
                                                        <p className={styles.orderDate}><Moment date={v.created_at} format="ddd, MMM Do YYYY, hh:mm A" /></p>
                                                        <p className={styles.orderPrice}><CurrencyFormat value={v.total} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} /></p>
                                                        <p className={styles.orderId}>{v.order_serial_id ? v.order_serial_id : v._id}</p>
                                                    </div>
                                                )
                                                :
                                                <p className={styles.emptyMsg}>No orders placed</p>
                                        }
                                    </div>
                                </div>
                                : activeTab === 'job' ?
                                    <div className={classNames(styles.tabBodyInner, styles.orderSection)}>
                                        <JobApplication set_File={set_File} setDocument={setDocument}/>
                                    </div>
                                    : activeTab === 'sponsor' ?
                                        <div className={classNames(styles.tabBodyInner, styles.orderSection)}>
                                            <SponsorshipApplication />
                                        </div>
                                        :
                                        activeTab === 'study' ?
                                            <div className={classNames(styles.tabBodyInner, styles.orderSection)}>
                                                <StudyApplication />
                                            </div>
                                            :
                                            activeTab === 'accomodation' ?
                                                <div className={classNames(styles.tabBodyInner, styles.orderSection)}>
                                                    <AccomodationApplications />
                                                </div>
                                                :
                                                activeTab === 'saved' ?
                                                    allfavourite_items.length > 0 ?
                                                        <div className={classNames(styles.tabBodyInner, styles.savedSection)}>
                                                            <div className={styles.profileOrdersCont}>
                                                                {
                                                                    allfavourite_items.map((v, i) =>
                                                                        <div className={styles.savedItem} key={v._id}>
                                                                            <div className={styles.savedCounter}>{i + 1}.</div>
                                                                            <div className={styles.savedItemImg}><img src={IMAGE_URL + v.image_url[0]} alt='saved item' /></div>
                                                                            <div className={styles.savedItemName}>{v.name[0]}</div>
                                                                            <div className={styles.savedPrice}>
                                                                                <CurrencyFormat value={v.price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                                                            </div>
                                                                            <div className={styles.savedAction}>
                                                                                <AddButton data={v} theme="search" />
                                                                                <div className={styles.removeSavedItem} onClick={() => removeFavourite(v)} title='Remove'><i className="fa-thin fa-xmark"></i></div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className={classNames(styles.tabBodyInner, styles.savedSection)}>
                                                            <p className={styles.emptyMsg}>You don't have any saved items. Start browsing products to save here.</p>
                                                        </div>
                                                    :
                                                    activeTab === 'bags' ?
                                                        shoppingBags.length > 0 ?
                                                            <div className={classNames(styles.tabBodyInner, styles.savedSection)}>
                                                                <div className={styles.profileOrdersCont}>
                                                                    {
                                                                        shoppingBags.map((v, i) =>
                                                                            <div key={v._id}>
                                                                                <div className={classNames(styles.savedItem, activeBag === v._id ? styles.active : '')}>
                                                                                    <div className={styles.savedCounter}>{i + 1}.</div>
                                                                                    <div className={styles.savedItemName} onClick={() => get_single_cart(v._id)} >Shopping Bag {v.unique_id}</div>
                                                                                    <div className={styles.savedQty} onClick={() => get_single_cart(v._id)}>{v.items_id.length} Items</div>
                                                                                    <div className={styles.savedPrice} onClick={() => get_single_cart(v._id)}><Moment date={v.created_at} format="ddd, MMM Do YYYY, hh:mm A" /></div>
                                                                                    <div className={styles.savedAction}>
                                                                                        <button className={styles.addItemBtn} onClick={() => addItemsToCart(v)}>Add to Basket</button>
                                                                                        <div className={styles.removeSavedItem} onClick={() => removeShoppingBag(v._id)} title='Remove'><i className="fa-thin fa-xmark"></i></div>
                                                                                    </div>
                                                                                </div>
                                                                                {activeBag === v._id &&
                                                                                    <div className={styles.bagItems}>
                                                                                        {savedLoader ?
                                                                                            <Loader />
                                                                                            :
                                                                                            bagItems.map((v, j) =>
                                                                                                <div className={styles.savedItem} key={v._id}>
                                                                                                    <div className={styles.savedCounter}>{i + 1}.{j + 1}.</div>
                                                                                                    <div className={styles.savedItemImg}><img src={IMAGE_URL + v.image_url[0]} alt='saved item' /></div>
                                                                                                    <div className={styles.savedItemName}>{v.name[0]}</div>
                                                                                                    <div className={styles.savedPrice}>
                                                                                                        <CurrencyFormat value={v.price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                                                                                    </div>
                                                                                                    <div className={styles.savedAction}>
                                                                                                        <AddButton data={v} theme="search" />
                                                                                                        {/* <div className={styles.removeSavedItem} onClick={() => removeFavourite(v)} title='Remove'><i className="fa-thin fa-xmark"></i></div> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )}
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>
                                                            :
                                                            <div className={classNames(styles.tabBodyInner, styles.savedSection)}>
                                                                <p className={styles.emptyMsg}>You don't have any saved items. Start browsing products to save here.</p>
                                                            </div>
                                                        :
                                                        activeTab === 'info' ?
                                                            <div className={classNames(styles.tabBodyInner, styles.infoSection)}>
                                                                <div className={styles.infoForm}>
                                                                    <div className={styles.inputContainer}>
                                                                        <input type={'text'} className={styles.inputs} value={personalDetail.first_name} onChange={(e) => setPersonalDetail({ ...personalDetail, 'first_name': e.target.value })} placeholder="First Name *" autoComplete="new-password" />
                                                                    </div>
                                                                    <div className={styles.inputContainer}>
                                                                        <input type={'text'} className={styles.inputs} value={personalDetail.last_name} onChange={(e) => setPersonalDetail({ ...personalDetail, 'last_name': e.target.value })} placeholder="Last Name *" autoComplete="new-password" />
                                                                    </div>
                                                                    <div className={styles.inputContainer}>
                                                                        <input type={'password'} className={styles.inputs} value={personalDetail.old_password} onChange={(e) => setPersonalDetail({ ...personalDetail, 'old_password': e.target.value })} placeholder="Password *" autoComplete="new-password" />
                                                                    </div>
                                                                    <div className={styles.inputContainer}>
                                                                        <button className={classNames(styles.save_button, !infoBtn ? styles.inactive : '', apiLoader ? styles.onProcoess : '')} type="button" onClick={() => updateUser(personalDetail)}>SAVE CHANGES <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            activeTab === 'email' ?
                                                                <div className={classNames(styles.tabBodyInner, styles.emailSection)}>
                                                                    <div className={styles.infoForm}>
                                                                        <div className={styles.inputContainer}>
                                                                            <input type={'email'} className={styles.inputs} value={emailAddress.email} onChange={userUpdateOnChange} name={'email'} placeholder="New Email Address *" autoComplete="new-password" />
                                                                            <span className={styles.errMsg}>{errMsg.email}</span>
                                                                        </div>
                                                                        <div className={styles.inputContainer}>
                                                                            <input type={'email'} className={styles.inputs} value={emailAddress.conf_email} onChange={userUpdateOnChange} name={'conf_email'} placeholder="Confirm New Email Address *" autoComplete="new-password" />
                                                                            <span className={styles.errMsg}>{errMsg.conf_email}</span>
                                                                        </div>
                                                                        <div className={styles.inputContainer}>
                                                                            <input type={'password'} className={styles.inputs} value={emailAddress.old_password} onChange={userUpdateOnChange} name={'old_password'} placeholder="Password *" autoComplete="new-password" />
                                                                        </div>
                                                                        <div className={styles.inputContainer}>
                                                                            <button className={classNames(styles.save_button, !emailBtn ? styles.inactive : '', apiLoader ? styles.onProcoess : '')} onClick={() => updateUser(emailAddress)} type="button">SAVE CHANGES <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                activeTab === 'password' ?
                                                                    <div className={classNames(styles.tabBodyInner, styles.passwordSection)}>
                                                                        <div className={styles.infoForm}>
                                                                            <div className={styles.inputContainer}>
                                                                                <input type={'password'} className={styles.inputs} value={passwordChange.old_password} onChange={passwordOnChange} name={'old_password'} placeholder="Current Password *" autoComplete="new-password" />
                                                                            </div>
                                                                            <div className={styles.inputContainer}>
                                                                                <input type={'password'} className={styles.inputs} value={passwordChange.new_password} onChange={passwordOnChange} name={'new_password'} placeholder="New Password *" autoComplete="new-password" />
                                                                            </div>
                                                                            <div className={styles.inputContainer}>
                                                                                <input type={'password'} className={styles.inputs} value={passwordChange.conf_password} onChange={passwordOnChange} name={'conf_password'} placeholder="Confirm New Password *" autoComplete="new-password" />
                                                                                <span className={styles.errMsg}>{errMsg.conf_password}</span>
                                                                            </div>
                                                                            <div className={styles.inputContainer}>
                                                                                <button className={classNames(styles.save_button, !passwordBtn ? styles.inactive : '', apiLoader ? styles.onProcoess : '')} onClick={() => updateUser(passwordChange)} type="button">SAVE CHANGES <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    activeTab === 'address' ?
                                                                        <>
                                                                            {newAddress ?
                                                                                <div className={classNames(styles.tabBodyInner, styles.addressSection)}>
                                                                                    <AddressForm type={'profile'} setNewAddress={setNewAddress} setDeliveryAddress={setDeliveryAddress} />
                                                                                </div>
                                                                                :
                                                                                <div className={classNames(styles.tabBodyInner, styles.addressSection)}>
                                                                                    <div className={styles.addAddress}>
                                                                                        <button className={styles.addressButton} onClick={() => setNewAddress(true)}>ADD NEW ADDRESS</button>
                                                                                    </div>
                                                                                    <div className={styles.addressListContainer}>
                                                                                        {deliveryAddress.length > 0 ?
                                                                                            deliveryAddress.map((v) =>
                                                                                                <div className={classNames(styles.delivery_address)} key={v._id} >
                                                                                                    <div className={styles.addresses}>
                                                                                                        <h2>{v.address_name}</h2>
                                                                                                        <address>
                                                                                                            <p>{v.address}</p>
                                                                                                            {/* <p> 485 </p>
                                                                                    <p> Great Northern Road </p>
                                                                                    <p> ABERDEEN, AB24 2EE </p>
                                                                                    <p> Tel: 01825990440 </p> */}
                                                                                                        </address>
                                                                                                    </div>
                                                                                                    <div className={styles.stdmethodRate}>
                                                                                                        {/* <span>Edit</span> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                            )
                                                                                            : ''}
                                                                                    </div>
                                                                                </div>}
                                                                        </>
                                                                        :
                                                                        activeTab === 'cards' ?
                                                                            <div className={classNames(styles.tabBodyInner, styles.cardsSection)}>
                                                                                <p className={styles.emptyMsg}>No Saved Card Details</p>
                                                                            </div>
                                                                            :
                                                                            activeTab === 'marketing' ?
                                                                                <div className={classNames(styles.tabBodyInner, styles.marketingSection)}>
                                                                                    <div className={styles.marketingSeparetor}>
                                                                                        <p>We would like to keep you up to date with offers, products and store news. In order to do so we need your consent: please let us know how you'd like us to contact you below.</p>
                                                                                    </div>
                                                                                    <div className={styles.marketingSeparetor}>
                                                                                        <h2>Marketing Emails</h2>
                                                                                        <label className={styles.rememberContainer}> Please check this box if you wish to receive email newsletters with offers, product and store news from Wilko. If you wish to stop receiving these you can unsubscribe at any time. For full details of how we handle your personal information please see our Privacy Policy.
                                                                                            <input type="checkbox" checked={email_marketing} onChange={(e) => setEmail_marketing(e.target.checked)} />
                                                                                            <span className={styles.checkmark}></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className={styles.marketingSeparetor}>
                                                                                        <button style={{ float: 'right' }} className={classNames(styles.addressButton, apiLoader ? styles.onProcoess : '')} onClick={() => updateUser({ is_email_marketing: email_marketing })}>SAVE CHANGES <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                                                                    </div>
                                                                                </div>
                                                                                : ''
                        }
                    </div>
                </div>
            </div>
            {deactivePopup && <DeactivatePopup setDeactivePopup={setDeactivePopup} />}
            {signoutPopUp && <SignoutPopup setSignoutPopUp={setSignoutPopUp} />}
            {document && <DocumentPreview setDocument={setDocument} _file={_file} set_File={set_File}/>}
        </div>
    )
}

export default Profile;