import React, { useState } from "react";
import { useSelector } from 'react-redux';
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import OutsideClickHandler from 'react-outside-click-handler';
import { Link, useLocation } from "react-router-dom";
import { _domain } from "../../../Config/Domains";

function Deliveries() {
    const location = useLocation()
    const loader = useSelector((state) => state.rootReducer.home_loader);
    const deliveries = useSelector((state) => state.rootReducer.delivery);
    const [isListShow, setIsListShow] = useState(false)

    // const [select_item, setSelect_item] = useState(localStorage.getItem('delivery_id') || "")

    // function changeDeliveries(v) {
    //     localStorage.setItem('delivery_id', v);
    //     setSelect_item(v)
    //     window.location.href = "/"
    //     // if (v === select_item) return
    //     // dispatch({
    //     //     type: 'set_deliveryWarning', payload: {
    //     //         warning: true,
    //     //         delivery_id: v
    //     //     }
    //     // })
    // }

    // console.log(28, deliveries)
    return (
        <>
            {loader ? '' : deliveries.length > 0 ?
                <OutsideClickHandler onOutsideClick={() => { setIsListShow(false) }}>
                    <div className="deliveries_options">
                        <div className="selection_point" onClick={() => setIsListShow(!isListShow)}>
                            {/* <span className="selected_value">{select_item === "" ? deliveries[0].delivery_name : deliveries.filter(v => v._id === select_item)[0].delivery_name}</span> */}
                            <span className="selected_value">
                                {_domain().name === 'ukbd.app' ?
                                    'UK & EU Brands'
                                    : _domain().name === 'deshi.ukbd.app' ?
                                        'Deshi Products'
                                        : _domain().name === 'job.ukbd.app' ?
                                            'Job/Sponsorship'
                                            : 'UK & EU Brands'
                                }
                            </span>
                            <span className={'icon'}>
                                <IoEllipsisHorizontalSharp size="14" color="#fff" />
                            </span>
                        </div>
                        {/* {isListShow ? */}
                        <ul className="list_container">
                            {/* {deliveries.filter(f => f.delivery_name[0] === 'UK & EU Brands').map(v =>
                                <li key={v._id} onClick={() => changeDeliveries(v._id)}>{v.delivery_name}</li>
                            )} */}

                            <li><Link to={'https://ukbd.app'}> UK & EU Brands </Link></li>
                            <li><Link to={'https://deshi.ukbd.app'}> Deshi Products </Link></li>
                            <li><Link to={'https://job.ukbd.app'}>Job/Sponsorship </Link></li>
                            <li><Link to={'/study'}>Study </Link></li>
                            <li className="disableItem"><Link to={'/travel'}>Travel </Link>  <span className="comingSoon">Up coming</span> </li>
                            <li className="disableItem"><Link to={'/property'}>Property </Link></li>
                            {/* <li className="disableItem">Host in Dhaka</li> */}
                            {/* <li className="disableItem"> Settle in the UK</li> */}
                            {/* <li className="disableItem">Send Money</li> */}
                        </ul>
                        {/* :
                             ""} */}
                    </div>
                </OutsideClickHandler>
                : ''
            }
        </>

    )
}

export default Deliveries;