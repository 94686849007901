import axios from "axios";
import { baseURL, appStatic } from "../Config/Config";
var langIndex = 0;
let user_details = localStorage.getItem('user_data');
let jwt_token = '';
user_details ? jwt_token = user_details.jwt_token : jwt_token = '';
let headers = {
    'lang': langIndex.toString(),
    'type': '7',
    'jwt_token': jwt_token,
}
const API = {
    geolocation: 'https://api.db-ip.com/v2/free/self',
    get_delivery_list_for_nearest_city: baseURL + 'api/user/get_delivery_list_for_nearest_city',
    get_delivery_store_list: baseURL + 'api/user/get_delivery_store_list',
    user_get_store_product_item_list: baseURL + 'api/user/user_get_store_product_item_list',
    get_popular_items_list: baseURL + 'api/user/get_popular_items_list',
    get_all_data_for_home: baseURL + 'api/user/get_all_data_for_home',
    get_only_categories: baseURL + 'api/user/get_only_categories',
    // get_only_rendom_items: baseURL + 'api/user/get_only_rendom_items',
    get_item_from_product: baseURL + 'api/user/get_item_from_product',
    get_only_popular_items: baseURL + 'api/user/get_only_popular_items',
    get_single_item: baseURL + 'api/user/get_single_item',
    user_send_otp: baseURL + 'api/user/user_send_otp',
    login: baseURL + 'api/user/login',
    logout: baseURL + 'api/user/logout',
    deactive_user: baseURL + 'api/user/deactive_user',
    add_item_in_cart: baseURL + 'api/user/add_item_in_cart',
    get_cart: baseURL + 'api/user/get_cart',
    get_order_cart_invoice: baseURL + 'api/user/get_order_cart_invoice',
    get_stripe_payment_intent: baseURL + 'api/user/get_stripe_payment_intent',
    pay_order_payment: baseURL + 'api/user/pay_order_payment',
    pay_Bkash_order_payment: baseURL + 'api/user/pay_Bkash_order_payment',
    create_order: baseURL + 'api/user/create_order',
    get_payment_gateway: baseURL + 'api/user/get_payment_gateway',
    get_website_settings: baseURL + 'api/user/get_website_settings',
    register: baseURL + 'api/user/register',
    driver_register: baseURL + 'api/store/add_new_provider',
    get_legal: baseURL + 'admin/get_legal',
    add_card: baseURL + 'api/user/add_card',
    get_stripe_add_card_intent: baseURL + 'api/user/get_stripe_add_card_intent',
    get_detail: baseURL + 'api/user/get_detail',
    update: baseURL + 'api/user/update',
    get_favoutire_addresses: baseURL + 'api/user/get_favoutire_addresses',
    add_favourite_address: baseURL + 'api/user/add_favourite_address',
    update_favourite_address: baseURL + 'api/user/update_favourite_address',
    delete_favourite_address: baseURL + 'api/user/delete_favourite_address',
    email_or_phone_verification: baseURL + 'api/user/email_or_phone_verification',
    get_order_detail: baseURL + 'api/user/get_order_detail',
    get_orders: baseURL + 'api/user/get_orders',
    clear_cart: baseURL + 'api/user/clear_cart',
    search_items: baseURL + 'api/user/search_items',
    signup_request: baseURL + 'api/user/signup_request',
    forgot_password: baseURL + 'api/admin/forgot_password',
    forgot_password_verify: baseURL + 'api/admin/forgot_password_verify',
    new_password: baseURL + 'api/admin/new_password',
    get_item_from_category: baseURL + 'api/user/get_item_from_category',
    add_favourite_item: baseURL + 'api/user/add_favourite_item',
    get_favourite_item_list: baseURL + 'api/user/get_favourite_item_list',
    remove_favourite_item: baseURL + 'api/user/remove_favourite_item',
    save_new_cart: baseURL + 'api/user/save_new_cart',
    get_save_carts: baseURL + 'api/user/get_save_carts',
    get_single_save_cart: baseURL + 'api/user/get_single_save_cart',
    update_single_save_cart: baseURL + 'api/user/update_single_save_cart',
    delete_save_cart: baseURL + 'api/user/delete_save_cart',
    get_note: baseURL + 'api/user/get_note',
    add_note_or_update: baseURL + 'api/user/add_note_or_update',
    get_card_list: baseURL + 'api/user/get_card_list',
    delete_card: baseURL + 'api/user/delete_card',
    select_card: baseURL + 'api/user/select_card',
    filter_items: baseURL + 'api/user/filter_items',
    get_brand: baseURL + 'api/store/get_brand',
    get_supplier: baseURL + 'api/store/get_supplier',
    get_all_data_for_delivery: baseURL + 'api/user/get_all_data_for_delivery',
    get_domain_by_name: baseURL + 'api/domain/get_domain_by_name',
    // Migration Section
    add_new_job_application: baseURL + 'api/job/add_new_job_application',
    verify_job_email: baseURL + 'api/job/verify_job_email',
    resend_job_otp: baseURL + 'api/job/resend_job_otp',
    upload_file: baseURL + 'api/application_doc/upload_file',
    // Sponsorship
    add_new_sponsorship_application: baseURL + 'api/sponsorship/add_new_sponsorship_application',
    verify_sponsorship_email: baseURL + 'api/sponsorship/verify_sponsorship_email',
    resend_sponsorship_otp: baseURL + 'api/sponsorship/resend_sponsorship_otp',
    // Accomodation
    add_new_applicaiton: baseURL + 'api/study/add_new_applicaiton',
    verify_study_email: baseURL + 'api/study/verify_study_email',
    resend_study_otp: baseURL + 'api/study/resend_study_otp',
    // Admission
    add_new_admission: baseURL + 'api/study_admission/add_new_admission',
    resend_study_admission_otp: baseURL + 'api/study_admission/resend_study_admission_otp',
    verify_study_admission_email: baseURL + 'api/study_admission/verify_study_admission_email',
    // Profile
    get_job_applications_by_user: baseURL + 'api/user/get_job_applications_by_user',
    get_sponsorship_by_user: baseURL + 'api/user/get_sponsorship_by_user',
    get_accommodation_by_user: baseURL + 'api/user/get_accommodation_by_user',
    get_admission_applications_by_user: baseURL + 'api/user/get_admission_applications_by_user',
    get_provider_location: baseURL + 'api/user/get_provider_location',

    add_new_form: baseURL + 'api/dynamic_form/add_new_form',
    upload_file_application: baseURL + 'api/dynamic_form/upload_file',
    get_forms: baseURL + 'api/dynamic_form/all_form',
    get_single_forms: baseURL + 'api/dynamic_form/get_single_form',
    edit_form: baseURL + 'api/dynamic_form/edit_form',
    submit_public_form: baseURL + 'api/dynamic_form/submit_public_form',
    get_single_public_form: baseURL + 'api/dynamic_form/get_single_public_form',
}

const postMETHOD = (key, data) => {
    // console.log(56, baseURL);
    return new Promise((resolve, reject) => {
        axios.post(API[key], data, { headers })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    })

}
const getMETHOD = (key, params = "") => {
    return new Promise((resolve, reject) => {
        axios.get(API[key] + params)
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    })

}
export {
    postMETHOD,
    getMETHOD
} 