import { useDispatch, useSelector } from 'react-redux';
import styles from '../../src/assets/css/Filters.module.css'
import Orders from './Orders';

const OrderPopup = () => {
    const dispatch = useDispatch();
    const orderList = useSelector(state => state.rootReducer.ordersList)
    // dispatch({ type: 'set_mobile_filter', payload: false })
    return (
        <section className={styles.filter_section}>
            <div className={styles.filter_inner_section}>
                <div className={styles.filter_header}>
                    <h3>Orders </h3>
                    <span className={styles.closeCart} onClick={() => dispatch({ type: 'set_mobile_orders', payload: false })}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <div className={styles.order_popup_body}>
                    <Orders orderList={orderList} from={'head'} setShowOrderList={''} />
                </div>
            </div>
        </section>
    )
}

export default OrderPopup;