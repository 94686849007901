import React, { useEffect, useState } from 'react'
// import styles from '../../../assets/css/Popular.module.css'
// import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector, useDispatch } from 'react-redux'
import { getPopularItems } from "../../../Utils/method"
import Loader from "../../Loader";
import Breadcrumb from "../../Breadcrumb";
// import PopularItem from './PopularItem';
import Item from '../Items/Item';
import SiteMap from '../Items/SiteMap';
import FooterSlider from '../Items/FooterSlider';
import { isMobile } from 'react-device-detect';
import PopularItem from './PopularItem';


export default function Popular() {
    const dispatch = useDispatch()
    const stores = useSelector((state) => state.rootReducer.stores);
    const paginate_data = useSelector((state) => state.rootReducer.paginate_data);
    const items = useSelector((state) => state.rootReducer.items);
    const active_product = useSelector((state) => state.rootReducer.active_product);
    const right_side_loader = useSelector((state) => state.rootReducer.right_side_loader);
    const [hasNextPage, setHasNextPage] = useState(paginate_data.nextPage);
    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setHasNextPage(paginate_data.nextPage)
    }, [paginate_data])

    async function fetchMoreData(page) {
        setLoader(true)
        try {
            if (paginate_data.hasNextPage) {
                let data = await getPopularItems({
                    store_id: stores[0]._id,
                    page: page,
                    limit: paginate_data.limit
                })
                dispatch({ type: 'add_items', payload: data });
            }

            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    return (
        <>
            <Breadcrumb />
            <div className="product_section" style={{ display: 'flex', flexFlow: 'row wrap', justifyContent: 'flex-start' }}>
                {/* {right_side_loader ? <Loader /> :
                    <InfiniteScroll
                        dataLength={items.length}
                        next={fetchMoreData}
                        hasMore={paginate_data.hasNextPage}
                        className="parent_for_product"
                        loader={<Loader />}
                        scrollableTarget="main_content_div"
                    >
                        {items.map(v => <Item data={v} key={v._id} />)}
                    </InfiniteScroll>

                } */}

                <div className="parent_for_product">
                    {isMobile ?
                        items.map(v => <PopularItem data={v} key={v._id} />)
                        :
                        items.map(v => <Item data={v} key={v._id} />)
                    }
                </div>

                <div className="pagination_section">
                    {loader ?
                        <Loader />
                        :
                        hasNextPage &&
                        <div>
                            <div className='total_items_count'>{items.length} / {paginate_data.totalDocs}</div>
                            <div className='loadMore_data' onClick={() => fetchMoreData(hasNextPage)}>Load More</div>
                        </div>
                    }
                </div>

                <div className="bottom_banner_section">
                    <FooterSlider />
                </div>
                <div className="sitemap_section">
                    <SiteMap />
                </div>
            </div>
        </>
    )
}
