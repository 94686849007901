import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom'
import styles from '../../../assets/css/Profile.module.css'
import { deactiveUser } from '../../../Utils/method'


export default function SignoutPopup(props) {
    const navigate = useNavigate();
    const signout = () => {
        navigate('/logout');
    }

    return (
        <div className={styles.wrapper}>
            <span className={styles.closeCart} onClick={() => props.setSignoutPopUp(false)}>
                <i className="fa-thin fa-xmark"></i>
            </span>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <h2 className={styles.accountStatusTitle}>Sign Out </h2>
                    <p className={styles.accountStatusMsg}>Do you really want to sign out?</p>
                </div>
                <div className={styles.timerSection}>
                    <h2 className={styles.accountStatusBtns}>
                        <button className={styles.cancelAction} onClick={() => props.setSignoutPopUp(false)}>Cancel</button>
                        <button className={styles.accountAction} onClick={signout}>Yes</button>
                    </h2>
                </div>
            </div>
        </div>
    )
}