import { useState, useRef } from 'react';
import styles from '../../assets/css/OTPVerification.module.css'
import Countdown from "react-countdown";
import { resend_job_otp, resend_sponsorship_otp, resend_study_admission_otp, resend_study_otp, verify_job_email, verify_sponsorship_email, verify_study_admission_email, verify_study_email } from '../../Utils/method';
import { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTPVerification = ({ type, email, setPage_state, reset_form, application_id }) => {
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [sendtime, setSendTime] = useState(Date.now() + 120000);
    const [spining, setSpining] = useState(false);
    const [otp, setOtp] = useState('');

    console.log(14, application_id);

    const verify = async (data) => {

        const otpString = otpValues.join('');
        try {
            if (otpString.length === 6) {
                setSendTime(Date.now() + 120000);
                let apires;
                if (type === 'job') {
                    apires = await verify_job_email({ application_id: application_id, otp: otpString });
                } else if (type === 'sponsor') {
                    apires = await verify_sponsorship_email({ application_id: application_id, otp: otpString });
                } else if (type === 'study') {
                    apires = await verify_study_email({ application_id: application_id, otp: otpString });
                } else if (type === 'admission') {
                    apires = await verify_study_admission_email({ application_id: application_id, otp: otpString });
                }
                console.log(apires);
                if (apires.success) {
                    // setVerification(false)
                    // setSuccess(true)
                    setPage_state(5)
                    reset_form()
                } else {
                    setOtpValues(['', '', '', '', '', ''])
                    toast.error(apires.message, { autoClose: 1000 });
                }
            }

        } catch (error) {
            setSendTime(Date.now() + 120000);
            console.log(error);
        }
    }


    const resend_verification = async () => {
        try {
            setSendTime(Date.now() + 120000);
            let apires;
            if (type === 'job') {
                apires = await resend_job_otp({ application_id: application_id });
            } else if (type === 'sponsor') {
                apires = await resend_sponsorship_otp({ application_id: application_id });
            } else if (type === 'study') {
                apires = await resend_study_otp({ application_id: application_id });
            } else if (type === 'admission') {
                apires = await resend_study_admission_otp({ application_id: application_id });
            }
            console.log('resend:', apires);
            // if (apires.success) {
            //     setVerification(false)
            //     setSuccess(true)
            //     reset_form()
            // }

        } catch (error) {
            setSendTime(Date.now() + 120000);
            console.log(error);
        }
    }



    const handleOtpChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        // Automatically focus on the next input field if not the last one
        if (index < inputRefs.current.length - 1 && value !== '') {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otpValues[index] === '') {
            const newOtpValues = [...otpValues];
            newOtpValues[index - 1] = '';
            setOtpValues(newOtpValues);

            // Delay focusing to ensure the updated state is reflected
            setTimeout(() => {
                inputRefs.current[index - 1].focus();
            }, 0);
        }
    };

    useEffect(() => {
        const handlePaste = (event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text');

            // Check if the pasted text is a 6-digit number
            if (/^\d{6}$/.test(pastedText)) {
                event.preventDefault(); // Prevent default paste behavior

                const digitsArray = pastedText.split('').map(Number);
                console.log('pastedText:', digitsArray);
                setOtpValues(digitsArray);
            }
        };

        // Add event listener for Ctrl+V (paste) event
        document.addEventListener('paste', handlePaste);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    return (
        <div className={styles.wrapperContainer}>
            <ToastContainer />
            <span className={styles.closePopup} onClick={() => setPage_state(3)}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.opt_container}>
                <h3 className={styles.main_title}>Verify Your Email</h3>
                <p className={styles.sub_title}>Please check your inbox for the verification <br /> code send to {email}</p>
                <p className={styles.opt_label}>Enter Code</p>
                <div className={styles.opt_input_container}>
                    {otpValues.map((v, i) => (
                        <input
                            key={i}
                            type="number"
                            maxLength="1"
                            value={v}
                            onChange={(e) => handleOtpChange(i, e.target.value)}
                            onKeyDown={(e) => handleBackspace(i, e)}
                            className={styles.opt_input}
                            ref={(input) => (inputRefs.current[i] = input)}
                            autoFocus={i === 0 ? true : false}
                        />
                    ))}

                    <p className={styles.opt_resend}>
                        {/* Resend code in: 60 sec */}
                        <Countdown date={sendtime} key={sendtime} renderer={props => props.completed ?
                            <span className={styles.resendBTN} onClick={resend_verification}><i className="fa-thin fa-arrow-rotate-right"></i> Resend</span>
                            :
                            <span className={styles.resendText} style={{ textDecoration: 'none' }} >Resend code in {props.minutes}:{props.seconds} sec</span>} />

                    </p>
                </div>
                <div className={styles.opt_btn_container}>
                    <button className={styles.opt_verify_btn} style={otpValues.join('').length === 6 ? {} : { pointerEvents: 'none', opacity: '0.6' }} onClick={verify}>VERIFY</button>
                </div>
                <p className={styles.opt_spam_label}>Can't find it? Please check your spam folder.</p>
            </div>
        </div>
    )
}

export default OTPVerification;