import React, { useRef, useState, useEffect } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../../../assets/css/BannerSection.module.css'
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { IMAGE_URL } from '../../../Config/Config';

function FooterSlider() {
    const domain_data = localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null
    const [Banner, setBanner] = useState([])
    const divRef = useRef(null);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        setBanner(domain_data ? domain_data.footer_banner_images : [])
    }, [])

    const settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        cssEase: 'linear',
        pauseOnHover: true,
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        className: styles.bannerSlideItem,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1
    };


    useEffect(() => {
        const updateHeight = () => {
            if (divRef.current) {
                const width = divRef.current.offsetWidth;
                const newHeight = (width * 5) / 16; // 16:5 aspect ratio
                // console.log('newHeight : ', newHeight);
                setHeight(newHeight);
            }
        };

        // Initial call to set height based on initial width
        updateHeight();

        // Attach a listener to update the height whenever the window is resized
        window.addEventListener('resize', updateHeight);

        // Cleanup the listener when the component unmounts
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);

    return (
        <div className={styles.FooterbannerSection} ref={divRef} style={{ width: '100%', height: `${height}px` }}>
            <div className={classNames('bannerSlider', styles.bannerSliderSection)} style={{ width: '100%' }}>
                <Slider {...settings}>
                    {Banner.map(i =>
                        <img className={styles.sliderImg} key={i.key + '_k'} src={IMAGE_URL + i.path} alt='' />
                    )}
                </Slider>
            </div>
        </div>
    )
}

export default FooterSlider;