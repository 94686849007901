import styles from '../../assets/css/OTPVerification.module.css'

const Message = ({ setPage_state }) => {
    const handleClick = () => {
        setPage_state(1)
    }
    return (
        <div className={styles.wrapperContainer}>
            <span className={styles.closePopup} onClick={() => setPage_state(1)}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.opt_container}>
                <h3 className={styles.main_title}>Thank You</h3>
                <p className={styles.message_box}>Your application and CV have been submitted for review by our recruitment specialist and Tier 2 Visa consultant. Our dedicated support team will contact you if you have been selected by our Immigration and UK visa consultants.</p>
                <div className={styles.btn_container}>
                    <button className={styles.opt_verify_btn} onClick={handleClick}>OKAY</button>
                </div>
            </div>
        </div>
    )
}

export default Message;