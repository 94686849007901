/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import main_logo from "../../../assets/UKBD.png"
import { IMAGE_URL } from "../../../Config/Config"
import { useSelector } from "react-redux";
import { useCart } from "react-use-cart";
import styles from '../../../assets/css/Item.module.css'
import AddButton from "../../AddButton";
import { Link, useNavigate } from "react-router-dom";
import { previousPrice } from '../../../Utils/common'
// import { AddToCart } from '../../../Utils/method'
import classNames from "classnames";
import ItemPrice from "./ItemPrice";
import { isMobile } from "react-device-detect";
import { _domain } from "../../../Config/Domains";

function Item(props) {
    let navigate = useNavigate();
    const [offer_text, setOfferText] = useState('');
    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const { inCart } = useCart();
    const currency_sign = useSelector((state) => state.rootReducer.currency_sign);

    const previewItem = (data) => {
        navigate('/single-item/' + data._id);
    }
    const activeDelivery = select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0];

    const findOffer = () => {
        if (props.data.offer_percentage !== '0' && props.data.offer_percentage !== undefined) {
            if (props.data.offer_percentage === '50') {
                setOfferText('BUY 1 GET 1')
            } else if (props.data.offer_percentage === '10') {
                setOfferText('10% OFF')
            } else if (props.data.offer_percentage === '20') {
                setOfferText('20% OFF')
            } else if (props.data.offer_percentage === '30') {
                setOfferText('30% OFF')
            } else if (props.data.offer_percentage === 'custom1') {
                setOfferText(props.data.custom_tag1)
            } else {
                setOfferText(props.data.custom_tag2)
            }
        }
    }

    useEffect(() => {
        findOffer();
    }, [])

    // const city = useSelector((state) => state.rootReducer.city);
    // const country = useSelector((state) => state.rootReducer.country);

    // const throughToast = (type) => {
    //     if (type === 'plus') {
    //         toast.success('Item added to your cart.', { autoClose: 1000 });
    //     } else {
    //         toast.success('Item removed from your cart.', { autoClose: 1000 });
    //     }
    // }

    // const addItemToCart = async (data) => {
    //     let q = 1;
    //     const product_details = stores.filter(v => v._id === data.store_id)[0].products.filter(v => v._id === data.product_id)[0]
    //     const store_details = stores.filter(v => v._id === data.store_id)[0]
    //     if (inCart(data._id)) {
    //         items.filter(k => k.id === data._id).map(v =>
    //             updateItemQuantity(data._id, v.quantity + 1)
    //         )
    //         q = items.filter(k => k.id === data._id)[0].quantity + 1;
    //     } else {
    //         addItem({ ...data, id: data._id, price: data.price, product_details: product_details })
    //     }
    //     throughToast('plus')
    //     await AddToCart({ items: items.filter(v => v.id !== data._id), item: props.data, store_details, product_details, city, country, q: q })
    // }

    return (
        <div style={!isMobile && _domain().name !== 'ukbd.app' ? { flexBasis: '20%' } : {}} className={classNames(styles.product_grid, !props.data.is_item_in_stock && styles.out_of_stock_item, inCart(props.data._id) && styles.pInCart)} key={props.data._id}>
            {/* onClick={() => addItemToCart(props.data)} */}
            <div className={styles.product_overlay} onClick={() => previewItem(props.data)}>
                <span className={styles.bigBasketIcon}><i className="fa-light fa-bag-shopping"></i></span>
            </div>
            {!props.data.is_item_in_stock && <div className={styles.out_of_stock}>Out of Stock</div>}
            <Link to={'/single-item/' + props.data._id} className={styles.findDetails}>
                <span>Details...</span>
            </Link>
            <div className={styles.product}>
                <div className={styles.product_img} onClick={() => previewItem(props.data)}>
                    <img src={IMAGE_URL + props.data.image_url[0]} onError={(event) => { event.currentTarget.src = main_logo }} loading="lazy" alt="product_image" />
                </div>
                <div className={styles.productDescription}>
                    {offer_text && <span className={styles.productTag}>{offer_text}</span>}
                    <div className={styles.product_title} >
                        <p className='product_t' onClick={() => previewItem(props.data)}>{props.data.name}</p>
                    </div>
                    <ItemPrice type={'item'} data={props.data} activeDelivery={activeDelivery} currency_sign={currency_sign} offer_text={offer_text} previousPrice={previousPrice} />
                    {props.data.delivery_time_static !== '' && <p className={classNames(styles.vatTaxLabel, props.type === 'sitem' ? 'singleItem_deliveryTime' : '')} ><span className={styles.deliveryTimeIcon}><i className="fa-thin fa-truck"></i></span> {props.data.delivery_time_static} </p>}
                    {/* <div className={styles.productOrigin}>
                        <div className={styles.originLogo}>
                            <span className={styles.supplierLabel}>Source:</span>
                            {imageError ? (
                                <p className={styles.supplier_name}>{props.data.supplier_name}</p>
                            ) : (
                                <img src={IMAGE_URL + props.data.supplier_icon} onError={handleImageError} alt="Supplier" />
                            )}
                        </div>
                        <div className={styles.originLogo}>
                            {props.data.brand_icon !== '' && <img src={IMAGE_URL + props.data.brand_icon} alt="Band" />}
                        </div>
                    </div> */}
                    <AddButton data={props.data} theme="basic" />
                </div>
            </div>
        </div>
    )
}

export default Item;