import { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../assets/css/Popup.module.css';
import { searchItems } from '../../Utils/method';
import loader from '../../assets/loader.svg';
import CurrencyFormat from 'react-currency-format';
import InfiniteScroll from 'react-infinite-scroll-component';
import classNames from 'classnames';
import { IMAGE_URL } from '../../Config/Config';
import AddButton from '../AddButton';
import { getCurrencySign } from '../../Utils/common';
import { useCart } from 'react-use-cart';
import { Link } from 'react-router-dom';
import { domains, _domain } from '../../Config/Domains';

const DeshiPopup = ({ setMenu_popup }) => {
    const {
        inCart
    } = useCart();
    // const location = useLocation()
    const stores = useSelector((state) => state.rootReducer.stores);
    const [searchValue, setSearchValue] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState();
    const [searchedItemList, setSearchedItemList] = useState([]);
    const [paginate_data, setPaginate_data] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 0 });
    const [nextPage, setNextPage] = useState();

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }


    const fetchMoreData = async () => {
        try {
            if (hasNextPage) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: searchValue,
                    page: nextPage,
                    limit: 20,
                    domain_id: domains['deshi.ukbd.app'] // Deshi Domain Id //JSON.parse(localStorage.getItem('current_domain'))._id
                });
                if (apires.status) {
                    setSearchedItemList([...searchedItemList, ...apires.items]);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                console.log('Fetch data: ', apires);
            }
        } catch (error) {
            console.log(error)
        }
    }

    let timer;
    const searchOnKeyup = (e) => {
        clearTimeout(timer);
        timer = setTimeout(async function () {
            setSearchLoader(true);
            var vLength = e.target.value.length
            if (vLength > 0) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: e.target.value,
                    page: 1,
                    limit: 50,
                    domain_id: domains['deshi.ukbd.app'] // Deshi Domain Id //JSON.parse(localStorage.getItem('current_domain'))._id
                });
                if (apires.status) {
                    setSearchedItemList(apires.items);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                setSearchLoader(false);
                console.log(apires)
            }
        }, 500);
    }


    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header} style={{ height: 'auto' }}>
                    <p className={styles.main_title}>Deshi Products</p>
                    <input className={styles.header_search_input} style={{ width: '100%', margin: '20px 0px' }} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup} type='text' placeholder='Search for Deshi Products' autoFocus />
                </div>
                {searchValue.length > 0 &&
                    <div className={styles.search_body_section} style={{ margin: '0px' }}>
                        <div className={styles.search_result_container} style={{ width: '100%', margin: '0px' }} id="searchItemContainer">
                            {searchLoader ?
                                <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                </div>
                                :
                                <InfiniteScroll
                                    dataLength={searchedItemList.length}
                                    next={fetchMoreData}
                                    hasMore={hasNextPage}
                                    // className={styles.searchItemContainer}
                                    loader={
                                        <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                            <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                        </div>
                                    }
                                    scrollableTarget="searchItemContainer"
                                >
                                    {
                                        searchedItemList.map((v, i) =>
                                            <div className={classNames(styles.srItemHolder, inCart(v._id) ? styles.itemInCart : '')} key={'sri_' + i}>
                                                <Link to={'/deshi/single-item/' + v._id} className={styles.srItem} onClick={() => { setSearchValue(''); setMenu_popup('') }}>
                                                    <div className={styles.sri_image}>
                                                        <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
                                                    </div>
                                                    <div className={styles.sriDetails}>
                                                        <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
                                                        <div className={styles.sri_price}>
                                                            <CurrencyFormat value={v.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                                                        </div>
                                                    </div>
                                                </Link>
                                                <div className={styles.srItemAdd}>
                                                    <AddButton data={v} theme="search" />
                                                </div>
                                            </div>
                                        )
                                    }
                                </InfiniteScroll>
                            }
                        </div>
                    </div>
                }
                {searchValue.length === 0 &&
                    <div className={styles.popup_body}>
                        <div className={styles.popup_body_content}>
                            <p className={styles.sub_title}>Experience the authentic taste of Bangladeshi food!</p>
                            <p className={styles.body_text}>Delivering exclusive Bangladeshi products and seasonal fruits directly to doorsteps in the UK is a great way to provide customers with unique and authentic offerings. By focusing on farmers' products, you ensure that customers receive high-quality items directly from the source. This not only supports local farmers but also provides customers in the UK with a taste of the authentic flavours and produce from Bangladesh. If you have any specific questions or if there's anything else I can assist you with, please let me know!</p>
                        </div>
                    </div>}
            </div>
        </div>
    )
}

export default DeshiPopup;