import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { Signup_request } from "../../../Utils/method"
import isEmail from 'validator/lib/isEmail';
import Countdown from "react-countdown";
import styles from '../../../assets/css/SignIn.module.css'
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DriverSignUp(params) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const store_id = useSelector((state) => state.rootReducer.stores[0]._id)
    const [passVisibility, setPassVisibility] = useState(false);
    const [acceptCondition, setAcceptCondition] = useState(false);
    const [buttonState, setButtonState] = useState(false);
    const [spining, setSpining] = useState(false);
    const [activeStep, setActiveStep] = useState(1);
    const [sendtime, setSendTime] = useState(Date.now() + 180000);
    const [inputOtp, setInputOtp] = useState('');

    // /store/add_new_provider

    console.log(18, store_id)

    const [inputData, setInputData] = useState({
        type: 'Driver',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        country_phone_code: '',
        country_code: '',
        // password: '',
        // store_id: store_id,
        // country_id: '',
        // city_id: '',
        // zipcode: '',
        // provider_type: '',
        // image_url: '',
        // address: '',
        // login_by: 'Manual',
        // social_id: "",
        // device_token: getDeviceToken(),
        // device_type: 'web',
        // referral_code: '',
    })

    const [errMsg, setErrMsg] = useState({
        email: '',
        password: ''
    })

    const onChangeFormData = (e) => {
        let { name, value } = e.target;

        if (name === 'first_name' || name === 'last_name') {
            if (Number(value.charAt(0))) {
                return;
            }
        } else if (name === 'email') {
            if (value !== '' && !isEmail(value)) {
                console.log(41, 'It\'s not Email.')
                setErrMsg({ ...errMsg, email: 'Enter valid email address' });
            } else {
                setErrMsg({ ...errMsg, email: '' });
            }
        }
        setInputData({ ...inputData, [name]: value })
    }

    const getPhoneData = (value, data, event, formattedValue) => {
        let { countryCode, dialCode, name } = data;
        setInputData({
            ...inputData,
            phone: value.slice(data.dialCode.length),
            country_phone_code: '+' + dialCode,
            country_code: countryCode
        })
    }

    useEffect(() => {
        let state = false;
        if (inputData.first_name === '') {
            state = false;
        } else if (inputData.last_name === '') {
            state = false;
        } else if (inputData.phone === '') {
            state = false;
        } else if (inputData.email === '' || !isEmail(inputData.email)) {
            state = false;
        } else
            // if (inputData.password === '') {
            //     state = false;
            // } else if (inputData.country_id === '') {
            //     state = false;
            // } else if (inputData.city_id === '') {
            //     state = false;
            // } else if (inputData.zipcode === '') {
            //     state = false;
            // } else if (inputData.address === '') {
            //     state = false;
            // } else 
            if (!acceptCondition) {
                state = false;
            } else {
                state = true
            }

        setButtonState(state);
        // setInputState(state);
    }, [inputData, acceptCondition]);

    useEffect(() => {
        console.log(37, inputData)
    }, [inputData])

    const driverSignUp = async () => {
        try {
            if (buttonState) {
                setSpining(prev => !prev);
                let apires = await Signup_request(inputData);
                console.log(123, apires)
                if (apires.success) {
                    dispatch({ type: 'set_signupWarning', payload: { warning: true, data: inputData } })
                    // toast.success(apires.status_phrase, { autoClose: 1000 });

                    // getUserData({ data: apires.user, type: 'set' });
                    // dispatch({ type: 'set_user_data', payload: apires.user });
                    // if (login_modal.from === 'checkout') {
                    //     dispatch({ type: 'set_login_modal', payload: { from: '', signin: false, signup: false } });
                    //     navigate("/" + login_modal.from);
                    // } else {
                    //     navigate("/");
                    // }
                    setSpining(prev => !prev);
                } else {
                    setSpining(prev => !prev);
                    // toast.error(apires.status_phrase, { autoClose: 1000 });
                }
                console.log(99, apires);
            }

        } catch (error) {
            console.log(131, error)
        }
    }

    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer newestOnTop />
            <div className={styles.carting_head}>
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            {/* <div className={styles.inputCont}>
                            <input className={styles.inputs} value={inputData.password} minLength={6} onChange={onChangeFormData} type={!passVisibility ? "password" : "text"} placeholder="Password" name="password" autoComplete="new-password" />
                            <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-duotone", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span>
                        </div> */}
            {/* <div className={styles.inputCont}>
                            <select className={styles.inputs} onChange={onChangeFormData} name="country_id" autoComplete="new-password">
                                <option>Country</option>
                                <option>Bangladesh</option>
                                <option>India</option>
                            </select>
                        </div>
                        <div className={styles.inputCont}>
                            <select className={styles.inputs} onChange={onChangeFormData} name="city_id" autoComplete="new-password">
                                <option>City</option>
                                <option>Dhaka</option>
                                <option>Chattagram</option>
                            </select>
                        </div> */}
            {/* <div className={styles.inputCont}>
                            <input className={styles.inputs} value={inputData.address} onChange={onChangeFormData} type="text" placeholder="Address" name="address" autoComplete="new-password" />
                        </div> */}
            {/* <div className={styles.inputCont}>
                            <input className={styles.inputs} value={inputData.zipcode} onChange={onChangeFormData} type="text" placeholder="Zipcode" name="zipcode" autoComplete="new-password" />
                        </div>
                        <div className={styles.inputCont}>
                            <input className={styles.inputs} value={inputData.referral_code} onChange={onChangeFormData} type="text" placeholder="Referral Code" name="referral_code" autoComplete="new-password" />
                        </div> */}
            <div className={styles.signin_partition}>
                <div className={classNames(styles.form_content, styles.signup_content)} style={{ backgroundColor: '#F8FAFF' }}>
                    <div className={styles.signUp_form}>
                        <h1 className={styles.signup_title}>Driver Sign Up</h1>
                        {activeStep === 1 ?
                            <div className={classNames(styles.inputCont, styles.phoneInput)}>
                                <PhoneInput
                                    containerClass={styles.phone_module}
                                    inputClass={styles.inputs}
                                    buttonClass={styles.codeDropDown}
                                    dropdownClass={styles.countryList}
                                    inputProps={{
                                        name: 'phone',
                                        required: true,
                                    }}
                                    country={'bd'}
                                    value={''}
                                    onChange={(value, data, event, formattedValue) => getPhoneData(value, data, event, formattedValue)}
                                />
                            </div>
                            : activeStep === 2 ?
                                <div className={styles.inputCont}>
                                    <input className={styles.inputs} value={inputData.email} onChange={onChangeFormData} type="email" placeholder="E-mail" name="email" autoComplete="new-password" />
                                    <span className={styles.errMsg}>{errMsg.email}</span>
                                </div>
                                : activeStep === 3 ?
                                    <div className={styles.inputCont}>
                                        <p className={styles.inputLabel}>
                                            OTP has been sent to your email.
                                            <label className="verificationLabel">
                                                <Countdown date={sendtime} key={sendtime} renderer={props => props.completed ?
                                                    <span className={styles.resendBTN}><i className="fa-thin fa-arrow-rotate-right"></i> Resend</span>
                                                    :
                                                    <span className={styles.resendBTN} style={{ textDecoration: 'none' }} >{props.minutes}:{props.seconds}</span>} />
                                            </label>
                                        </p>
                                        <input className={classNames(styles.inputs)} value={inputOtp} onChange={(e) => setInputOtp(e.target.value)} type="number" placeholder="Enter OTP" name="emailOtp" required autoFocus />
                                        <span className={styles.errMsg}>{errMsg.otp}</span>
                                    </div>
                                    :
                                    <>
                                        <div className={styles.inputCont}>
                                            <input className={styles.inputs} value={inputData.first_name} onChange={onChangeFormData} type="text" placeholder="First Name" name="first_name" autoComplete="new-password" />
                                        </div>
                                        <div className={styles.inputCont}>
                                            <input className={styles.inputs} value={inputData.last_name} onChange={onChangeFormData} type="text" placeholder="Last Name" name="last_name" autoComplete="new-password" />
                                        </div>
                                        <div className={styles.inputCont}>
                                            <label className={styles.rememberContainer}> By Continuing you may agree to out <Link to={'/terms-conditions'} target={'_blank'}> Terms Conditions </Link> and <Link to={'/privacy-policy'} target={'_blank'}> Privacy Policy </Link>
                                                <input type="checkbox" onChange={(e) => setAcceptCondition(e.target.checked)} name="conditions" />
                                                <span className={styles.checkmark}></span>
                                            </label>
                                        </div>
                                    </>
                        }
                        <div className={classNames(styles.inputCont, spining ? styles.onProcoess : '')}>
                            <button type="submit" className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} onClick={driverSignUp}>
                                {activeStep === 4 ? 'Sign Up' : 'Next'}
                                <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DriverSignUp;