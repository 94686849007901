import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import styles from '../assets/css/BannerSection.module.css'
import { IMAGE_URL } from '../Config/Config';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { _domain } from '../Config/Domains';

function BannerSection() {
    const domain_data = localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null
    const [Banner, setBanner] = useState([])

    useEffect(() => {
        setBanner(domain_data ? domain_data.home_banner_images : [])
    }, [])

    const settings = {
        autoplay: true,
        autoplaySpeed: 7000,
        cssEase: 'linear',
        pauseOnHover: true,
        dots: false,
        dotsClass: "slick-dots slick-thumb",
        className: styles.bannerSlideItem,
        infinite: true,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <div className={styles.bannerSection}>
            <div className={classNames('bannerSlider', styles.bannerSliderSection)}>
                <Slider {...settings}>
                    {Banner.map(i =>
                        <img className={styles.sliderImg} key={i.key + '_k'} src={IMAGE_URL + i.path} alt='' />
                    )}
                </Slider>
            </div>
            <div className={styles.bannerContentSection}>
                <p className={styles.bannerContent1}>SEND IT TO YOUR LOVED ONE BACK HOME</p>
                {_domain().name === 'ukbd.app' ?
                    <p className={styles.bannerContent2}>UKBD is an open platform that connects <br /> Bangladeshis on both sides, London & <br /> Dhaka through trade, education, <br /> migration & investment.</p>
                    :
                    <p className={styles.bannerContent2}>USBD is an open platform that connects <br /> Bangladeshis on both sides, US cities & <br /> Dhaka through trade, education, <br /> migration & investment.</p>
                }
                {_domain().name === 'ukbd.app' ?
                    <p className={styles.bannerContent3}>We deliver 100% authentic British <br /> products to the doorsteps of <br /> Bangladesh where dreams become <br /> reality.</p>
                    :
                    <p className={styles.bannerContent3}>We deliver 100% authentic American <br /> products to the doorsteps of <br /> Bangladesh where dreams become <br /> reality.</p>
                }
                <p className={styles.bannerContent4}>An easy & trustworthy real-time tracking <br /> system will make your shopping more <br /> joyful & relaxing.</p>
            </div>
        </div>
    )
}

export default BannerSection