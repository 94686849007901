import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { user_get_legal } from "../../../Utils/method"
import styles from '../../../assets/css/TermsConditions.module.css';
import { useNavigate } from "react-router-dom";
function TermsConditions() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user_terms = useSelector((state) => state.rootReducer.user_terms)
    const fetchData = async () => {
        try {
            let data = await user_get_legal();
            dispatch({ type: 'set_user_terms', payload: data.legal.user_terms });
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className={styles.TermsConditionsContainer}>
            <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.TermsConditions} dangerouslySetInnerHTML={{ __html: user_terms }}></div>
        </div>
    )
}

export default TermsConditions;