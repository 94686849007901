import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'
// import FacebookLogin from 'react-facebook-login';
import { UserLogin, getUserData, removeUserData } from "../../../Utils/method"
import isEmail from 'validator/lib/isEmail';
import styles from '../../../assets/css/SignIn.module.css';
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getBrowserFingerprint from 'get-browser-fingerprint';
import { _domain } from "../../../Config/Domains";

function SignIn() {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const fingerprint = getBrowserFingerprint();
    console.log(fingerprint);
    const login_modal = useSelector((state) => state.rootReducer.login_modal)
    const [passVisibility, setPassVisibility] = useState(false);
    const [spining, setSpining] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [buttonState, setButtonState] = useState(false);

    useEffect(() => {

        let state = false;
        if (email === '' || !isEmail(email)) {
            state = false;
        } else if (password === '') {
            state = false;
        } else {
            state = true
        }

        setButtonState(state);
    }, [email, password]);

    const LoginUser = async () => {
        if (buttonState) {
            try {
                setSpining(prev => !prev);
                let data = await UserLogin({ email: email, password: password, is_iosapp: false, fingerprint: fingerprint.toString() });
                console.log(40, data.user)
                getUserData({ data: data.user, type: 'set' });
                dispatch({ type: 'set_user_data', payload: data.user });
                toast.success(data.status_phrase, { autoClose: 1000 });
                if (login_modal.from === 'checkout') {
                    dispatch({ type: 'set_login_modal', payload: { from: '', signin: false, signup: false } });
                    navigate("/" + login_modal.from);
                } else {
                    navigate("/");
                }
                setSpining(prev => !prev);
            } catch (error) {
                setSpining(prev => !prev);
                removeUserData();
                toast.error(error.status_phrase, { autoClose: 1000 });
            }
        }
    }

    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer newestOnTop />
            <div className={styles.carting_head}>
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.signin_partition}>
                <div className={styles.form_content}>
                    <div className={classNames(styles.signin_form, styles.signin_form_left)}>
                        <div className={styles.container_login}>
                            <h1 className={styles.signin_title}>Sign In</h1>
                            <div className={styles.inputContSignin}>
                                <input className={styles.inputs} type="text" placeholder="Email or Phone Number" name="uname" onChange={(event) => setEmail(event.target.value)} required value={email} />
                            </div>
                            <div className={styles.inputContSignin}>
                                <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-thin", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span> {/*<i className="fa-thin fa-eye-slash"></i> */}
                                <input className={styles.inputs} type={!passVisibility ? "password" : "text"} minLength={6} placeholder="Password" name="psw" onChange={(event) => setPassword(event.target.value)} required value={password} />
                            </div>
                            <span className={styles.psw}><Link to="/forgot-password">Forgotten your password?</Link></span>
                            <label className={styles.rememberContainer}>Remember me
                                <input type="checkbox" />
                                <span className={styles.checkmark}></span>
                            </label>
                            <div className={classNames(styles.inputContSignin, spining ? styles.onProcoess : '')}>
                                <button className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} type="button" onClick={LoginUser}>LOGIN <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                <div className={styles.loginText_bottom}>
                                    <Link to={'/sign-in-with-otp'}>Sign in with OTP</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames(styles.signin_form, styles.signin_form_right)}>
                        <h1 className={styles.signin_title}>New to {_domain().title} App . . .</h1>
                        <p className={styles.signintextMsg}>Creating an account is quick and simple and allows  you to track, change or return your order.</p>
                        <Link to={"/sign-up"}> <button className={styles.signin_button} type="button" >CREATE AN ACCOUNT</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;