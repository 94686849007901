/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styles from '../../../assets/css/Popular.module.css'
import { IMAGE_URL } from "../../../Config/Config"
import main_logo from "../../../assets/UKBD.png"
import { Link, useNavigate } from 'react-router-dom';
import { useCart } from "react-use-cart";
import { useSelector } from 'react-redux';
import {
    previousPrice,
    getCurrencySign,
    priceConvertToAlternate,
    rate_convr_GBPtoBDT,
    alternateCurrency
} from "../../../Utils/common"
import CurrencyFormat from 'react-currency-format';
import classNames from 'classnames';
import AddButton from '../../AddButton';
import { AddToCart } from '../../../Utils/method';
import { toast } from 'react-toastify';
import { _domain } from '../../../Config/Domains';

export default function PopularItem(props) {
    const navigate = useNavigate()
    const [offer_text, setOfferText] = useState('');
    const select_item = localStorage.getItem('delivery_id');
    const stores = useSelector((state) => state.rootReducer.stores);
    const { addItem, inCart, items, updateItemQuantity } = useCart();
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const [activeDelivery, setactiveDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const city = useSelector((state) => state.rootReducer.city);
    const country = useSelector((state) => state.rootReducer.country);

    const findOffer = () => {
        if (props.data.offer_percentage !== '0' && props.data.offer_percentage !== undefined) {
            if (props.data.offer_percentage === '50') {
                setOfferText('BUY 1 GET 1')
            } else if (props.data.offer_percentage === '10') {
                setOfferText('10% OFF')
            } else if (props.data.offer_percentage === '20') {
                setOfferText('20% OFF')
            } else if (props.data.offer_percentage === '30') {
                setOfferText('30% OFF')
            } else if (props.data.offer_percentage === 'custom1') {
                setOfferText(props.data.custom_tag1)
            } else {
                setOfferText(props.data.custom_tag2)
            }
        }
    }

    useEffect(() => {
        findOffer();
    }, [])

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    const throughToast = (type) => {
        if (type === 'plus') {
            toast.success('Item added to your cart.', { autoClose: 1000 });
        } else {
            toast.success('Item removed from your cart.', { autoClose: 1000 });
        }
    }

    const addItemToCart = async (data) => {
        let q = 1;
        const product_details = stores.filter(v => v._id === data.store_id)[0].products.filter(v => v._id === data.product_id)[0]
        const store_details = stores.filter(v => v._id === data.store_id)[0]
        if (inCart(data._id)) {
            items.filter(k => k.id === data._id).map(v =>
                updateItemQuantity(data._id, v.quantity + 1)
            )
            q = items.filter(k => k.id === data._id)[0].quantity + 1;
        } else {
            addItem({ ...data, id: data._id, price: data.price, product_details: product_details })
        }
        throughToast('plus')
        await AddToCart({ items: items.filter(v => v.id !== data._id), item: props.data, store_details, product_details, city, country, q: q })
    }

    const previewItem = (data) => {
        navigate('/single-item/' + data._id);
    }

    return (
        <div className={styles.popularItemGrid}>
            <div className={classNames(styles.popularItem, 'popularItem', inCart(props.data._id) ? styles.pInCart : '')}>
                {offer_text ? <span className={styles.productTag}>{offer_text}</span> : ''}
                <Link to={'/single-item/' + props.data._id} className={styles.findDetails}>
                    <span>Details...</span>
                </Link>
                {/* onClick={() => addItemToCart(props.data)} */}
                <div className={styles.overlay}>
                    <span className={styles.bigBasketIcon} onClick={() => previewItem(props.data)}><i className="fa-light fa-bag-shopping"></i></span>
                </div>
                <div className={styles.itemImg}>
                    <img src={IMAGE_URL + props.data.image_url[0]} alt="product_image" onError={(event) => { event.currentTarget.src = main_logo }} />
                </div>
                <div className={styles.itemDetails}>
                    <p className={styles.itemTitle}>{props.data.name}</p>
                    <div className={styles.priceSection}>
                        <div className={styles.activePrice}>
                            <CurrencyFormat value={props.data.price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.second_price}>{fractionCheck(value)}</p>} />
                            {offer_text !== '' && props.data.offer_percentage !== 'custom1' && props.data.offer_percentage !== 'custom2' ?
                                <p className={classNames(styles.prev_price)} > WAS <span> <CurrencyFormat value={previousPrice(props.data.price, Number(props.data.offer_percentage))} displayType={'text'} thousandSeparator={true} prefix={alternateCurrency(_domain().currency) + ' '} renderText={v => v} /> </span></p>
                                : ''}
                        </div>
                        <div className={styles.activePrice}>
                            <CurrencyFormat value={priceConvertToAlternate(props.data.price, 'GBP', rate_convr_GBPtoBDT(activeDelivery.conversion_rate))} displayType={'text'} thousandSeparator={true} prefix={alternateCurrency(_domain().currency) + ' '} renderText={value => <p className={styles.first_price}>{value}</p>} />
                            <p className={classNames(styles.deliveryTime, props.type === 'sitem' ? 'singleItem_deliveryTime' : '')} > Excl. shipping & duties. </p>
                        </div>
                    </div>
                    {props.data.delivery_time_static !== '' && <p className={classNames(styles.deliveryTime, styles.deliveryTime_org)} ><span className={styles.deliveryTimeIcon}><i className="fa-thin fa-truck"></i></span> {props.data.delivery_time_static} </p>}
                </div>
                <div className={styles.overlayButton}>
                    <AddButton data={props.data} theme="basic" />
                </div>
            </div>
        </div>
    )
}
