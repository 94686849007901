/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import CartItems from "./CartItems";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "react-use-cart";
import Summary from "../Checkout/Summery";
import styles from '../../../assets/css/Cart.module.css';
import { useNavigate } from "react-router-dom";
// import Comment from "./Comment";
import { toast } from 'react-toastify';
import { save_new_cart, add_note_or_update, get_note } from "../../../Utils/method";
// import ukbd from '../../../assets/UKBD.png'

export default function Cart(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user_data = useSelector((state) => state.rootReducer.user_data);
    const {
        cartTotal,
        isEmpty,
        items
    } = useCart();

    const [commentPopup, setCommentPopup] = useState({
        status: false,
        item_id: '',
        note: ''
    });

    useEffect(() => {
        dispatch({ type: 'set_timelineStage', payload: 1 })
        if (cartTotal === 0) {
            navigate('/');
        }
    }, [])

    const saveShoppingBag = async () => {
        let ids = [];
        for (let i = 0; i < items.length; i++) {
            const element = items[i];
            ids.push(element._id)
        }
        try {
            let data = {
                user_id: user_data._id,
                items_id: ids,
                server_token: user_data.server_token
            }
            let apires = await save_new_cart(data)
            toast.success('Shopping Bag saved successfully.', { autoClose: 1000 });
            console.log(44, apires);
        } catch (error) {
            console.log();
        }
    }
    let [itemNotes, setItemNotes] = useState([])

    const getNotes = async () => {
        try {
            let apires = await get_note({ user_id: user_data._id, server_token: user_data.server_token })
            // console.log(63, apires.data);
            setItemNotes(apires.data)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getNotes()
    }, [])

    const submitComment = async () => {
        try {
            let data = {
                user_id: user_data._id,
                item_id: commentPopup.item_id,
                note: commentPopup.note,
                server_token: user_data.server_token
            }
            let apires = await add_note_or_update(data);
            console.log(apires);
            toast.success('Comment added successfully.', { autoClose: 1000 });
            setCommentPopup({ ...commentPopup, status: false, item_id: '' })
            getNotes();
        } catch (error) {
            console.log(error);
        }
    }

    const proceedCheckout = () => {
        if (items.length > 0) {
            navigate('/checkout');
        }
    }

    return (
        <div className={styles.carting_popup}>
            <div className={styles.carting_head}>
                {/* <div className={styles.pageTitleSection}>
                    <Link className={styles.pageLogoHolder} to={'https://ukbd.app'}>
                        <img className={styles.pageLogo} src={ukbd} alt="" />
                    </Link>
                    <div className={styles.pageTitle} onClick={() => navigate('/')}>
                        <h2>UK & EU Brands</h2>
                    </div>
                </div> */}
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.cartItemContainer}>
                <div className={styles.itemPart}>
                    <div className={styles.sectionTitle}>YOUR SHOPPING BAG</div>
                    <div className={styles.cartItemsContainer}>
                        {/* {commentPopup.status && <Comment submitComment={submitComment} commentPopup={commentPopup} setCommentPopup={setCommentPopup} />} */}
                        <CartItems commentPopup={commentPopup} submitComment={submitComment} setCommentPopup={setCommentPopup} itemNotes={itemNotes} />
                        {isEmpty && <h2 className={styles.emptyBag}>Your Bag is Empty</h2>}
                    </div>
                </div>
                <Summary type="cart" setShow={props.setShow} />
            </div>
            <div className={styles.carting_footer}>
                <button className={styles.proceed_to_chec} style={items.length > 0 ? {} : { opacity: '0.6' }} onClick={proceedCheckout}>PROCEED TO CHECKOUT</button>
                    {items.length > 0 && <button className={styles.bagSaveBtn} style={{    marginTop: '25px',position: 'absolute',right: '100px'}} onClick={saveShoppingBag}>Save Your Shopping Bag</button>}
            </div>
        </div>
    )
}