import React, { useEffect, useRef } from "react";
import MainContent from "./layouts/MainContent";
import { useLocation } from "react-router-dom";
import { _domain } from "../Config/Domains";


function RightSide() {
    const hashRef = useRef(null);
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            console.log(14, 'Its Working');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className="right_side_container" ref={hashRef} id="ukeubrands"
            style={
                !_domain().is_left_side_view ? { width: '100%' } :
                    location.pathname !== '/profile' && location.pathname.indexOf('/map') === -1 ? {} : { width: '100%', height: '100%' }
            }>
            <MainContent />
        </div>
    )
}
export default RightSide;