import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../../assets/css/Profile.module.css'
import {clear_cart, getUniqCartId, getUserData } from '../../Utils/method'
import { useCart } from "react-use-cart";


export default function DeliveryWarning(props) {
    const dispatch = useDispatch();
    const deliveryWarning = useSelector((state) => state.rootReducer.deliveryWarning)
    let cart_id = getUserData({type:'get'}).cart_id;
    const {
        emptyCart,
      } = useCart();
    const actionOnClick = async(t) => {
        if (t === 'yes') {
            if (getUserData({type:'get'}) === false | cart_id === null) {
                console.log('False Or Null Data')
                getUniqCartId(false);
                emptyCart();
                localStorage.setItem('delivery_id', deliveryWarning.delivery_id);

                dispatch({
                    type: 'set_deliveryWarning', payload: {
                        warning: false,
                        delivery_id: ''
                    }
                })
                
                window.location.href = "/"
            } else {

                try {
                    let apires = await clear_cart({cart_id:cart_id});        
                    if (apires.success) {
                        getUniqCartId(false);
                        emptyCart();
                        localStorage.setItem('delivery_id', deliveryWarning.delivery_id);
                        console.log('Cart already cleared.')
    
                        dispatch({
                            type: 'set_deliveryWarning', payload: {
                                warning: false,
                                delivery_id: ''
                            }
                        })
                    }
                    window.location.href = "/"
                    console.log(16, apires)        
                } catch (error) {
                    console.log(error)
                }
            }
        } else {
            dispatch({
                type: 'set_deliveryWarning', payload: {
                    warning: false,
                    delivery_id: ''
                }
            })
        }
    }

    return (
        <div className={styles.wrapper} style={{width:'100%'}}>
            <span className={styles.closeCart} onClick={() => actionOnClick('no')}>
                <i className="fa-thin fa-xmark"></i>
            </span>
            <div className={styles.mainContainer}>
                <div className={styles.mainContent}>
                    <h2 className={styles.accountStatusTitle}>Warning</h2>
                    <p className={styles.accountStatusMsg}>Some other store items are already in cart, clear your cart to place order</p>
                </div>

                <div className={styles.timerSection}>
                    <h2 className={styles.accountStatusBtns}>
                        <button className={styles.cancelAction} onClick={() => actionOnClick('no')}>CANCEL</button>
                        <button className={styles.accountAction} onClick={() => actionOnClick('yes')}>OK</button>
                    </h2>
                </div>
            </div>
        </div>
    )
}
