import { useEffect, useState } from 'react';
import { upload_file } from '../../Utils/method';
import styles from '../../assets/css/Documents.module.css';
import Loader from '../Loader';
import pdf from '../../assets/pdf.svg'

const Documents = ({ setPage_state, form_data, setForm_data }) => {

    const [loader, setLoader] = useState(false)
    const fileOnChange = async (e) => {
        const { name, value, files } = e.target;
        console.log(name, files);
        let data = new FormData()
        data.append('files[0]', files[0])
        setLoader(true)
        try {
            let apires = await upload_file(data)
            console.log(apires);
            if (apires.success) {
                setForm_data({ ...form_data, [name]: { name: value.split('\\')[value.split('\\').length - 1], image: URL.createObjectURL(e.target.files[0]), url: apires.data } })
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const [buttonState, setButtonState] = useState(false)

    useEffect(() => {
        let state = false;
        if (form_data.doc_passport.url === '') {
            state = false
        } else if (form_data.doc_nid.url === '') {
            state = false
        } else if (form_data.doc_last_degree.url === '') {
            state = false
        } else if (form_data.doc_birth_certificate.url === '') {
            state = false
        } else if (form_data.doc_cv.url === '') {
            state = false
        } else {
            state = true
        }

        console.log(form_data);
        setButtonState(state)
    }, [form_data])

    const cancelDoc = () => {
        setPage_state(1)
    }

    const continueDoc = () => {
        setPage_state(3)
    }

    return (
        <div className={styles.documents_popup}>
            <span className={styles.closeCart} onClick={cancelDoc}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.doc_header}>
                <p className={styles.doc_header_title}>Documents</p>
            </div>
            <div className={styles.doc_body}>
                <div className={styles.doc_info_label}>Please upload your supporting documents. Each attachment should not exceed 2MB in size. </div>
                <div className={styles.doc_container}>
                    {loader && <div className={styles.loaderContain} ><Loader /></div>}
                    <label className={styles.uploader} htmlFor="passport">
                        <input className={styles.uploader_input} type='file' onChange={fileOnChange} name='doc_passport' id='passport' accept='image/*,.pdf' hidden />
                        {form_data.doc_passport.url !== '' ?
                            <div className={styles.uploader_label} title={form_data.doc_passport.name} style={form_data.doc_passport.url !== '' ? { backgroundColor: '#012965', color: '#fff', wordBreak: 'break-all' } : {}}>
                                <img src={form_data.doc_passport.image} alt='doc_passport'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = pdf;
                                    }}
                                />
                            </div>
                            :
                            <div className={styles.uploader_label}>Upload your Passport</div>
                        }
                    </label>
                    <label className={styles.uploader} htmlFor="nid">
                        <input className={styles.uploader_input} type='file' onChange={fileOnChange} name='doc_nid' id='nid' accept='image/*,.pdf' hidden />
                        {form_data.doc_nid.url !== '' ?
                            <div className={styles.uploader_label} title={form_data.doc_nid.name} style={form_data.doc_nid.url !== '' ? { backgroundColor: '#012965', color: '#fff', wordBreak: 'break-all' } : {}}>
                                <img src={form_data.doc_nid.image} alt='doc_nid'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = pdf;
                                    }}
                                />
                            </div>
                            :
                            <div className={styles.uploader_label}>Upload your NID</div>
                        }
                    </label>
                    <label className={styles.uploader} htmlFor="certificate">
                        <input className={styles.uploader_input} type='file' onChange={fileOnChange} name='doc_last_degree' id='certificate' accept='image/*,.pdf' hidden />
                        {form_data.doc_last_degree.url !== '' ?
                            <div className={styles.uploader_label} title={form_data.doc_last_degree.name} style={form_data.doc_last_degree.url !== '' ? { backgroundColor: '#012965', color: '#fff', wordBreak: 'break-all' } : {}}>
                                <img src={form_data.doc_last_degree.image} alt='doc_last_degree'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = pdf;
                                    }}
                                />
                            </div>
                            :
                            <div className={styles.uploader_label}>Upload your certificate of last degree</div>
                        }
                    </label>
                    <label className={styles.uploader} htmlFor="birth_certificate" >
                        <input className={styles.uploader_input} type='file' onChange={fileOnChange} name='doc_birth_certificate' id='birth_certificate' accept='image/*,.pdf' hidden />
                        {form_data.doc_birth_certificate.url !== '' ?
                            <div className={styles.uploader_label} title={form_data.doc_birth_certificate.name} style={form_data.doc_birth_certificate.url !== '' ? { backgroundColor: '#012965', color: '#fff', wordBreak: 'break-all' } : {}}>
                                <img src={form_data.doc_birth_certificate.image} alt='doc_birth_certificate'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = pdf;
                                    }}
                                />
                            </div>
                            :
                            <div className={styles.uploader_label}>Upload your birth certificate</div>
                        }
                    </label>
                    <label className={styles.uploader} htmlFor="cv" >
                        <input className={styles.uploader_input} type='file' onChange={fileOnChange} name='doc_cv' id='cv' accept=".pdf" hidden />
                        {form_data.doc_cv.url !== '' ?
                            <div className={styles.uploader_label} title={form_data.doc_cv.name} style={form_data.doc_cv.url !== '' ? { backgroundColor: '#012965', color: '#fff', wordBreak: 'break-all' } : {}}>
                                <img src={form_data.doc_cv.image} alt='doc_cv'
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = pdf;
                                    }}
                                />
                            </div>
                            :
                            <div className={styles.uploader_label}>Upload your CV with references (PDF)</div>
                        }
                    </label>
                </div>
            </div>
            <div className={styles.doc_footer}>
                <button className={styles.doc_btn} onClick={cancelDoc}>Back</button>
                <button className={styles.doc_btn} onClick={continueDoc} style={(buttonState) ? {} : { pointerEvents: 'none', opacity: '0.6' }}>Continue</button>
            </div>
        </div>
    )
}

export default Documents;