import React from "react";
import { useSelector } from 'react-redux';
import styles from '../../../assets/css/HelpFAQ.module.css';
import { useNavigate } from "react-router-dom";
function HelpFAQs() {
    const navigate = useNavigate();
    const faq = useSelector((state) => state.rootReducer.website_setting.faq)
    return (
        <div className={styles.HelpFAQContainer}>
            <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.FaqStyle} dangerouslySetInnerHTML={{ __html: faq }}></div>
            {/* <a style={{
                "position": "fixed",
                "bottom": "40px",
                "right": "50px",
                "textDecoration": "auto",
                "textTransform": "uppercase",
                "fontSize": "10px",
                "cursor": "pointer",
                "color": "#012965",
                "fontFamily": "var(--robotoC)",
                "fontStyle": "normal",
                "fontWeight": "300"
            }} href="/product.html" target={"_blank"}>product list</a> */}
        </div>
    )
}

export default HelpFAQs;