import classNames from 'classnames';
import styles from '../../assets/css/Header.module.css'
import { useEffect, useState } from 'react';
import { geolocation } from '../../Utils/method';
import { currencyData } from '../../Utils/common';

const Currency = () => {
    const active_currency = (typeof window !== 'undefined') ?
        (localStorage.getItem('active_currency') ?
            (function () {
                try {
                    return JSON.parse(localStorage.getItem('active_currency'));
                } catch (error) {
                    console.error(11, "Error parsing 'active_currency' from localStorage:", error);
                    return null;
                }
            })() : null
        ) : null;

    // const [loader, setloader] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState(active_currency ? active_currency : currencyData[0]);

    // useEffect(() => {
    //     let _isMounted = true;

    //     async function inital() {
    //         setloader(true)
    //         try {
    //             let geo = await geolocation();

    //             if (_isMounted) {
    //                 console.log(geo.countryCode);
    //                 for (const obj of currencyData) {
    //                     if (geo.countryCode.toLowerCase() === obj.icon) {
    //                         setSelectedCurrency(obj)
    //                     }
    //                 }
    //             }

    //             setloader(false)
    //         } catch (error) {
    //             setloader(false)
    //             console.log(error);
    //         }
    //     }
    //     inital()

    //     return () => {
    //         _isMounted = false
    //     }
    // }, [])


    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const selectCurrency = (currency) => {
        localStorage.setItem('active_currency', currency)
        setSelectedCurrency(currency);
        setIsOpen(false);
        window.location.reload()
    };

    return (
        <div className={classNames(styles.dropdown_container, isOpen && styles.open)}>
            <div className={styles.selected_currency} onClick={toggleDropdown}>
                <img
                    src={require(`../../assets/flags/${selectedCurrency.icon}.svg`)}
                    alt={selectedCurrency.name}
                    width="16"
                    height="16"
                    style={{ marginRight: '8px' }}
                />
                {selectedCurrency.code}
            </div>
            {isOpen && (
                <div className={styles.currency_options}>
                    {currencyData.map((currency) => (
                        <div
                            key={currency.code}
                            className={styles.currency_option}
                            onClick={() => selectCurrency(currency)}
                        >
                            <img
                                src={require(`../../assets/flags/${currency.icon}.svg`)}
                                alt={currency.name}
                                width="16"
                                height="16"
                            />
                            {currency.code}
                        </div>
                    ))}
                </div>
            )}
        </div>
    )
}

export default Currency;