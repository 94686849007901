import React from 'react'
import styles from '../../assets/css/FloatOption.module.css'

export default function ScrollToTop(props) {
  return (
    <div className={styles.scrollTop} onClick={()=> props.goToTop()}>
        <span className={styles.scrollIcon}>
        <i className="fa-thin fa-chevron-up"></i>
        </span>
    </div>
  )
}
