import classNames from 'classnames';
import styles from '../../assets/css/Popup.module.css';
const PropertyPopup = ({ setMenu_popup }) => {
    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header}>
                    <p className={styles.main_title}>Property Management</p>
                </div>
                <div className={styles.popup_body}>
                    <div className={styles.popup_body_content}>
                        <p className={styles.sub_title}>Dedicated care & protection for your assets in Bangladesh!</p>
                        <p className={styles.body_text}>Let us take the burden off your shoulders & turn your dreams into a reality. We understand the challenges faced by non-resident Bangladeshis in managing their properties and assets. Our comprehensive business and marketing solutions empower you to protect and grow your investments, making your dreams a tangible success. Leave the worries behind and entrust us with the care and management of your properties and assets. Our expert team is committed to transforming your aspirations into actuality, offering unrivaled support every step of the way. Together, we'll navigate the challenges, bringing your vision to life with unparalleled expertise and support. Maximize the potential of your properties and assets back home with our exclusive professionals and enginering support for non-resident Bangladeshis. With our comprehensive range of property management solutions will cater your properties and assets with the utmost care and expertise. We'll help you achieve your goals and transform your dreams into a flourishing reality. Let us be your trusted partner in turning those aspirations into tangible accomplishments.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PropertyPopup;