import ukbd from '../assets/UKBD.png'
import usbd from '../assets/USBD.jpg'
import us from '../assets/US.png'
import uk from '../assets/UK.png'

const domains = {
    "ukbd.app": {
        id: "6502b5d5b7448422bd3d62de",
        name: "ukbd.app",
        is_left_side_view: false,
        title: 'UKBD',
        logo: ukbd,
        flag: uk,
        currency: 'GBP',
        rate: 145,
        baseURL: 'https://api.ukbd.app/v3/',
        imageURL: 'https://api.ukbd.app/',
        app: 'https://ukbd.app',
        service: [
            { name: 'UK & EU BRANDS', icon: '', url: '/', title: '' },
            { name: 'DESHI PRODUCTS', icon: '', url: 'https://deshi.ukbd.app', title: '' },
            { name: 'BED & BREAKFAST', icon: '', url: '/travel', title: 'Coming soon...' },
            { name: 'EDUCATION', icon: '', url: 'https://edu.ukbd.app', title: '' },
            { name: 'MIGRATION', icon: '', url: 'https://job.ukbd.app', title: '' },
            { name: 'PROPERTY', icon: '', url: '/property', title: 'Coming soon...' },
        ]
    },
    "usbd.app": {
        id: "65263cfc39b8bd1f2f87630d",
        name: "usbd.app",
        is_left_side_view: false,
        title: 'USBD',
        logo: usbd,
        flag: us,
        currency: 'USD',
        rate: 145,
        baseURL: 'https://api.usbd.app/v3/',
        imageURL: 'https://api.usbd.app/',
        app: 'https://usbd.app',
    },
    "deshi.ukbd.app": {
        id: "650aa65c310b7b67523c099b",
        name: "deshi.ukbd.app",
        is_left_side_view: false,
        title: 'UKBD',
        logo: ukbd,
        flag: uk,
        currency: 'GBP',
        rate: 145
    },
    "edu.ukbd.app": {
        id: "650c2a40603f540bda3b4bdf",
        name: "edu.ukbd.app",
        is_left_side_view: false,
        title: 'UKBD',
        logo: ukbd,
        flag: uk,
    },
    "job.ukbd.app": {
        id: "650c2a4e603f540bda3b4be0",
        name: "job.ukbd.app",
        is_left_side_view: false,
        title: 'UKBD',
        logo: ukbd,
        flag: uk,
    },
    "sterlingjob.uk": { id: "6502cf221cc712b2ebf90870", name: "sterlingjob.uk", is_left_side_view: false },
}

let hostname = window.location.hostname;
let pathname = window.location.pathname;
hostname = hostname.indexOf('.') > -1 ? hostname : 'ukbd.app'
let custom_host =
    (hostname === 'ukbd.app' && pathname === '/') ? 'ukbd.app'
        : (hostname === 'usbd.app' && pathname === '/') ? 'usbd.app'
            : (hostname === 'deshi.ukbd.app' && pathname === '/') ? 'deshi.ukbd.app'
                : (hostname === 'ukbd.app' && pathname === '/deshi') ? 'deshi.ukbd.app'
                    // : (hostname === 'usbd.app' && pathname === '/deshi') ? 'deshi.ukbd.app'
                    : (hostname === 'ukbd.app' && (pathname === '/migration' || pathname.indexOf('/migration/') > -1)) ? 'ukbd.app'
                        : (hostname === 'usbd.app' && (pathname === '/migration' || pathname.indexOf('/migration/') > -1)) ? 'usbd.app'
                            : (hostname === 'ukbd.app' && pathname === '/study') ? 'ukbd.app'
                                : (hostname === 'usbd.app' && pathname === '/study') ? 'usbd.app'
                                    : (hostname === 'job.ukbd.app') ? 'job.ukbd.app'
                                        : (hostname === 'job.ukbd.app') ? 'job.usbd.app'
                                            : (hostname === 'edu.ukbd.app') ? 'edu.ukbd.app'
                                                : (hostname === 'edu.ukbd.app') ? 'edu.usbd.app'
                                                    : 'ukbd.app';

// console.log(2121, custom_host, pathname);

const _domain = () => {
    return domains[custom_host]
}

export { domains, custom_host, _domain };