/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../../Utils/method"
import { useDispatch } from 'react-redux';
import getBrowserFingerprint from 'get-browser-fingerprint';

function Logout() {
    const fingerprint = getBrowserFingerprint();
    let navigate = useNavigate();
    const dispatch = useDispatch()
    useEffect(() => {
        async function logoutFun() {
            await logoutUser(fingerprint.toString());
            dispatch({ type: 'set_user_data', payload: false });
            navigate("/");
        }
        logoutFun();
    }, [])
    return (
        <></>
    )
}

export default Logout;