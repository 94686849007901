/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styles from '../../../assets/css/Checkout.module.css';
import classNames from "classnames";
import { create_order, get_payment_gateway, logoutUser, pay_order_payment } from "../../../Utils/method"
import { alternateCurrency } from "../../../Utils/common"
import { googleGETmethod } from "../../../Utils/googleMapMethod"
import { useDispatch, useSelector } from 'react-redux';
import { getUniqCartId, get_order_cart_invoice, get_stripe_add_card_intent } from '../../../Utils/method'
import LoaderICo from "../../Loader"
import { useCart } from "react-use-cart";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Stripe from './Stripe';
import { useLocation, useNavigate } from 'react-router-dom';
import ukbd from '../../../assets/UKBD.png'
// import { useStripe } from '@stripe/react-stripe-js';

function Payment(props) {
    const {
        items,
        emptyCart,
    } = useCart();
    const location = useLocation();
    // const stripe = useStripe();
    // const parameter = location.state.parameter;
    const navigate = useNavigate()
    const total_cart_amount = localStorage.getItem('total_cart_amount');
    // const deliveryAddress = useSelector((state) => state.rootReducer.selectedDLAddress);
    const stores = useSelector((state) => state.rootReducer.stores);
    const city = useSelector((state) => state.rootReducer.city);
    // const [isProceed, setIsProceed] = useState(false);
    const [isPaymentCash, setIsPaymentCash] = useState(false);
    const [paymentGetWay, setPaymentGetWay] = useState([]);
    const [wallet, setWalletAmount] = useState(0);
    const [wallet_currency_code, setWalletCurrency_code] = useState('');
    const [currency_data, setCurrency_data] = useState(JSON.parse(localStorage.getItem('currency_data')))
    const [loader, setLoader] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    const [clienSecret, setClientSecret] = useState('');
    // const [bkash_ref, setbkash_ref] = useState('');
    // const [payloader, setPayloader] = useState(false);
    const [billingAddress, setBillingAddress] = useState([]);
    // const makeBkashPayment = async () => {
    //     if (bkash_ref.trim().length < 5) {
    //         console.log(delivery)
    //         toast.error("Please enter your valid Bkash number or Transaction ID", { autoClose: 1000 });
    //         return false;
    //     }
    //     try {
    //         setPayloader(true);
    //         let pay = await pay_bkash_order_payment(
    //             {
    //                 is_payment_mode_cash: true,
    //                 order_payment_id: invoice.order_payment._id,
    //                 bkash_ref: bkash_ref,
    //                 bkash_amount: priceConvertToAlternate(cartTotal, 'GBP', 1)
    //             }
    //         );
    //         console.log(pay)
    //         let order = await create_order({
    //             destination_addresses: deliveryAddress,
    //             ...invoice,
    //             payment_mode_type: currentMethod === 2 ? 'bkash_manual' : 'card',
    //             payment_currency: currency_data.payment_currency,
    //             view_price_currency: currency_data.view_price_currency,
    //             exchange_rate_website: currency_data.exchange_rate_website
    //         });
    //         setPayloader(false);
    //         if (order.success) {
    //             console.log('order', order)
    //             console.log('invoice', invoice)

    //             toast.success(order.status_phrase, { autoClose: 1000 });
    //             //let clear = await clear_cart({cart_id:'cart_id'});
    //             getUniqCartId(false);
    //             emptyCart();
    //             dispatch({ type: 'set_lastOrder', payload: order })
    //             dispatch({ type: 'set_timelineStage', payload: 3 })
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    const [invoice, setInvoice] = useState(false)
    const order_invoice = async () => {
        console.log('stores:', stores);
        try {
            setLoader(true)
            let APIres = await get_order_cart_invoice({ store: stores[0], items: items });
            console.log('invoice', APIres)
            if (APIres.success) {
                setInvoice(APIres)
            } else {
                toast.error(APIres.status_phrase, { autoClose: 1000 });
                if (APIres.status_phrase.indexOf('Token') > -1) {
                    await logoutUser();
                    navigate("/");
                } else {
                    emptyCart();
                    getUniqCartId(false)
                    navigate("/");
                }
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    useEffect(() => {
        localStorage.setItem('currency_data', JSON.stringify({
            payment_currency: currency_data.payment_currency,
            view_price_currency: currency_data.view_price_currency,
            exchange_rate_website: currency_data.exchange_rate_website,
        }))
        console.log(60, currency_data)

    }, [currency_data])

    const getPaymentData = async () => {
        try {
            navigator.geolocation.getCurrentPosition(async function (position) {
                try {
                    const pos = { lat: position.coords.latitude, lng: position.coords.longitude, };
                    let APIres = await googleGETmethod(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${pos.lat},${pos.lng}&key=`);
                    console.log('Map APIres:',APIres)
                    let reqPara = {
                        address: '',
                        city1: '',
                        city2: '',
                        city3: '',
                        city_code: '',
                        city_id: city._id,
                        country: '',
                        country_code: '',
                        country_code_2: '',
                        delivery_type: 1,
                        latitude: '',
                        longitude: '',
                        store: stores[0]._id
                    }
                    reqPara.latitude = pos.lat;
                    reqPara.longitude = pos.lng;
                    reqPara.address = APIres.results?.[0]?.['formatted_address'];
                    APIres.results?.[0]?.['address_components'].forEach(element => {
                        var type = element.types[0]
                        switch (type) {
                            case 'country':
                                reqPara.country = element.long_name;
                                reqPara.country_code = element.short_name;
                                reqPara.country_code_2 = element.short_name;
                                break;
                            case 'administrative_area_level_1':
                                // this.current_location.state_code = element.short_name;
                                reqPara.city2 = element.long_name;
                                break;
                            case 'administrative_area_level_2':
                                reqPara.city1 = element.short_name;
                                reqPara.city3 = element.short_name;
                                break;
                            case 'postal_code':
                                break;
                            default:
                                break;
                        }
                    });

                    let res = await get_payment_gateway(reqPara);
                    console.log(73, res)
                    setIsPaymentCash(res.is_cash_payment_mode);
                    let intentCard = {
                        payment_id: res.payment_gateway[0]._id,
                        is_web: true,
                        url: '/checkout'
                    }
                    let get_stripp_intent = await get_stripe_add_card_intent(intentCard)
                    // console.log(127,get_stripp_intent)
                    setClientSecret(get_stripp_intent.client_secret)
                    setPaymentGetWay(res.payment_gateway);
                    setWalletAmount(res.wallet);
                    setWalletCurrency_code(res.wallet_currency_code);
                    setLoader(false)
                } catch (error2) {
                    setLoader(false)
                    console.log(error2)
                }
            });
        } catch (error) {
            console.log(error);
        }
    }

    const [confirm_button, setConfirm_button] = useState(location.state ? location.state.length > 0 ? true : false : false)

    useEffect(() => {
        console.log(208, location.state);
        console.log(208, location.state.length);
        if (!location.state) {
            navigate('/')
        } else {
            order_invoice()
            getPaymentData();
        }
    }, [])

    const fractionCheck = (v) => {
        // let data = v.split(' ');
        // let data = v;
        if (v.indexOf('.') === -1) {
            return v;
        } else {
            let frc = v.split('.')
            return <p style={{}}><span>{frc[0]}</span>.<span className={styles.fractionValue} style={{ fontSize: 13 }}>{frc[1]}</span></p>
        }
    }

    // const setBkash = () => {
    //     setCurrentMethod(2);
    //     updateCurrency('BDT');
    //     dispatch({ type: 'set_payment_mode_type', payload: 'bkash' });
    // }

    useEffect(() => {
        console.log(192, billingAddress.length, billingAddress)
    }, [billingAddress])

    // const pay_now = async () => {
    //     try {
    //         setIsLoading(true);
    //         let pay = await pay_order_payment({
    //             is_payment_mode_cash: false,
    //             order_payment_id: invoice.order_payment._id,
    //             payment_mode: "card",
    //             payment_id: props.paymentGetWay._id,
    //             payment_currency: currency_data.payment_currency,
    //             view_price_currency: currency_data.view_price_currency,
    //             exchange_rate_website: currency_data.exchange_rate_website,
    //         });
    //         console.log(94, pay);

    //         var is_payment_paid = pay.is_payment_paid;
    //         invoice["destination_addresses"] = deliveryAddress;
    //         console.log(is_payment_paid);
    //         let order;
    //         if (is_payment_paid) {
    //             order = await create_order({
    //                 ...invoice,
    //                 payment_currency: currency_data.payment_currency,
    //                 view_price_currency: currency_data.view_price_currency,
    //                 exchange_rate_website: currency_data.exchange_rate_website,
    //             });
    //             console.log(order);
    //         } else {
    //             let client_secret = pay.client_secret;
    //             let payment_method = pay.payment_method;
    //             let payment_intent_id = pay.payment_intent_id;
    //             if (client_secret && payment_method) {
    //                 stripe.confirmCardPayment(client_secret, {
    //                     payment_method: {
    //                         card: {
    //                             token: 'tok_visa',
    //                         },
    //                         billing_details: {
    //                             // address: deliveryAddress,
    //                             name: deliveryAddress.firstName + " " + deliveryAddress.lastname,
    //                             // phone: deliveryAddress.phone,
    //                         },
    //                     },
    //                 }).then(async (result) => {
    //                     console.log(result);
    //                     if (result.error) {

    //                     } else {
    //                         order = await create_order({
    //                             ...invoice,
    //                             payment_currency: currency_data.payment_currency,
    //                             view_price_currency: currency_data.view_price_currency,
    //                             exchange_rate_website: currency_data.exchange_rate_website,
    //                         });
    //                         console.log(order);
    //                     }
    //                 });
    //             } else if (payment_intent_id.includes("ch_")) {
    //                 order = await create_order({
    //                     ...invoice,
    //                     payment_currency: currency_data.payment_currency,
    //                     view_price_currency: currency_data.view_price_currency,
    //                     exchange_rate_website: currency_data.exchange_rate_website,
    //                 });
    //                 console.log(order);
    //             }
    //         }
    //         console.log(order);
    //         if (order.success) {
    //             console.log("order", order);
    //             console.log("invoice", invoice);
    //             toast.success(order.status_phrase, { autoClose: 1000 });
    //             //let clear = await clear_cart({cart_id:'cart_id'});
    //             getUniqCartId(false);
    //             emptyCart();
    //             dispatch({ type: "set_lastOrder", payload: order });
    //             localStorage.removeItem('total_cart_amount');
    //             navigate('/payment-success', { state: { parameter: 'Test parameter data' } })
    //         }
    //         console.log(pay);
    //         setIsLoading(false);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }


    return (
        <>
            {/* {loader ? <LoaderICo /> : */}
            <div className={styles.checkout_Popup}>
                <div className={styles.carting_head}>
                    <span className={styles.closeCart} onClick={() => navigate(-1)}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <ToastContainer newestOnTop />

                {/* {(billingAddress.length === 0 && !addNewAddress) &&
                        <div className={classNames(styles.payment_currency)} >
                            <button className={classNames(styles.addressSelectionBtn)} onClick={() => UseDefaultAddress()}> Use delivery address as billing address </button>
                            <button className={classNames(styles.addressSelectionBtn)} onClick={() => setAddNewAddress(true)}> + Add new billing address </button>
                        </div>
                    }

                    {billingAddress.length > 0 &&
                        <div className={styles.paymentMethodConatiner}>
                            <p className={styles.method_head}>
                                <h2>Billing Address</h2>
                            </p>
                            <div className={classNames(styles.billing_address)} >
                                <div className={styles.addresses}>
                                    <h2>{billingAddress[0].address_name}</h2>
                                    <address>
                                        <p> Phone: {billingAddress[0].phone} </p>
                                        <p>{billingAddress[0].address}</p>
                                    </address>
                                </div>
                                {!isProceed && <div className={styles.addressProced}>
                                    <button onClick={() => setIsProceed(true)}>PROCEED</button>
                                </div>}
                            </div>
                        </div>
                    }
                    {addNewAddress &&
                        <div className={styles.paymentMethodConatiner}>
                            <p className={styles.method_head}>
                                <h2>Billing Address</h2>
                            </p>
                            <AddressForm type={'billing'} setAddNewAddress={setAddNewAddress} setBillingAddress={setBillingAddress} />
                        </div>
                    } */}

                {/* {isProceed &&
                        <div className={styles.paymentMethodConatiner}>
                            <div className={classNames(styles.stripeContainer)} >
                                <Stripe paymentGetWay={paymentGetWay[0]} ck={clienSecret} invoice={invoice} />
                            </div>
                            {currentMethod !== 2 ?
                                ''
                                :
                                <div className={classNames(styles.stripeContainer)} >
                                    <div className={styles.bKashContainer}>
                                        <p className={styles.bKashlabel}>Please Make payment with send money option on your bkash. </p>
                                        <p className={styles.bKashAccount}>Bkash: {delivery[0].bkash_number || '01827885295'}</p>
                                        <div className={styles.bKashInputContainer}>
                                            <input className={styles.bKashInput} type={'text'} placeholder='Enter your Bkash number or Transaction ID' onKeyUp={(event) => setbkash_ref(event.target.value)} />
                                        </div>
                                        <div className={styles.bKashInputContainer}>
                                            {payloader ? <button className={styles.bkashPlaceOrder} >Loading...</button> : <button className={styles.bkashPlaceOrder} onClick={makeBkashPayment}>Place Order</button>}

                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    } */}
                {loader ? <LoaderICo /> :
                    <div className={styles.paymentConatiner}>
                        <div className={styles.totalPaymentAmount}>Total Amount: {alternateCurrency('BDT')} {fractionCheck(total_cart_amount)}</div>
                        <div className={classNames(styles.stripeContainer)} >
                            <div className={styles.stripe_form}>
                                {/* {confirm_button ?
                                    <div style={{ width: '100%', padding: '0 25%' }}>
                                        <button style={{ backgroundColor: '#012965' }} id="submit" onClick={pay_now}>
                                            <span id="button-text">
                                                {isLoading ? <div className="spinner" id="spinner"></div> : "Pay Now"}
                                            </span>
                                        </button>
                                    </div>
                                    :
                                } */}
                                <Stripe paymentGetWay={paymentGetWay[0]} confirm_button={confirm_button} sCard={location.state} ck={clienSecret} invoice={invoice} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            {/* } */}
        </>
    )
}

export default Payment