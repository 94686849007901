import React from "react";
import { IMAGE_URL } from "../../../Config/Config"
import main_logo from "../../../assets/main-logo.svg"
import { Link } from "react-router-dom";
import { useSelector } from 'react-redux'

function SubCategory({ data, navigation }) {
    const active_product_data = useSelector((state) => state.rootReducer.active_product_data);
    // const tst = useSelector((state) => state.rootReducer);
    // console.log(6, tst)
    // console.log(6, props)
    return (
        <li onClick={navigation} className={active_product_data ? active_product_data._id === data._id ? 'active' : '' : ''}>
            <Link to={"/product/" + data._id} >
                <img src={IMAGE_URL + data.image_url} alt="cate_img" onError={(event) => { event.currentTarget.src = main_logo }} />
                <p>{data.name[0]}</p>
            </Link>
        </li>
    )
}

export default SubCategory;