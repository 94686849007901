import classNames from 'classnames';
import styles from '../../assets/css/Popup.module.css';
const MoneyPopup = ({ setMenu_popup }) => {
    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header}>
                    <p className={styles.main_title}>Send Money</p>
                </div>
                <div className={styles.popup_body}>
                    <div className={styles.popup_body_content}>
                        <p className={styles.sub_title}>Transfer funds instantly to your loved ones back home!</p>
                        <p className={styles.body_text}>Introducing our securely & hassle-free money transfer service, designed to bring peace of mind and convenience to your life. With our platform, you can now send money promptly and securely to your beloved family members back in your home country.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MoneyPopup;