/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import styles from '../../../assets/css/Landing.module.css'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import gApp from '../../../assets/landing/play_store.png';
import iApp from '../../../assets/landing/app_store.png';
import students from '../../../assets/landing/Students.png';
import { get_all_data_for_delivery } from '../../../Utils/method';
import { isMobile } from 'react-device-detect';
import { _domain } from '../../../Config/Domains';

function Landing() {
    const domain_data = localStorage.getItem('_domain') ? JSON.parse(localStorage.getItem('_domain')) : null
    const colors = ['rgb(0 48 25 / 90%)', 'rgb(255 0 0 / 80%)', 'rgb(0 29 92 / 90%)'];
    const [main_slide_imgs, setMain_slide_imgs] = useState([]);
    const [ColorIndex1, setColorIndex1] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex2, setColorIndex2] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex3, setColorIndex3] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex4, setColorIndex4] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex5, setColorIndex5] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex6, setColorIndex6] = useState(Math.floor(Math.random() * colors.length));
    const [ColorIndex7, setColorIndex7] = useState(Math.floor(Math.random() * colors.length));

    const [ImageIndex1, setImageIndex1] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex2, setImageIndex2] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex3, setImageIndex3] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex4, setImageIndex4] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex5, setImageIndex5] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex6, setImageIndex6] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [ImageIndex7, setImageIndex7] = useState(Math.floor(Math.random() * main_slide_imgs.length));
    const [isMouseOver, setIsMouseOver] = useState(false);
    const [item_slides, setItem_slides] = useState([]);

    useEffect(() => {
        const colorInterval = setInterval(() => {
            if (!isMouseOver) {
                setColorIndex1((prev) => (prev + 1) % colors.length);
                setColorIndex2((prev) => (prev + 1) % colors.length);
                setColorIndex3((prev) => (prev + 1) % colors.length);
                setColorIndex4((prev) => (prev + 1) % colors.length);
                setColorIndex5((prev) => (prev + 1) % colors.length);
                setColorIndex6((prev) => (prev + 1) % colors.length);
                setColorIndex7((prev) => (prev + 1) % colors.length);
            }
        }, 3500);

        const imageInterval = setInterval(() => {
            if (!isMouseOver) {
                setImageIndex1((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex2((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex3((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex4((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex5((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex6((prev) => (prev + 1) % main_slide_imgs.length)
                setImageIndex7((prev) => (prev + 1) % main_slide_imgs.length)
            }
        }, 7000);

        return () => {
            clearInterval(colorInterval);
            clearInterval(imageInterval);
        };
    }, [isMouseOver, main_slide_imgs]);

    const handleMouseOver = () => {
        setIsMouseOver(true);
    };

    const handleMouseLeave = () => {
        setIsMouseOver(false);
    };

    // const getDeliveries = async () => {
    //     try {
    //         let apires = await get_all_data_for_delivery();
    //         if (apires.success) {;
    //             setItem_slides(apires.delivery_list[0].homepage_product_preview)
    //             let imgs = []
    //             for (let i = 0; i < apires.delivery_list[0].landing_page_images.length; i++) {
    //                 const element = apires.delivery_list[0].landing_page_images[i];
    //                 imgs.push(element.path)
    //             }
    //             setMain_slide_imgs(imgs)
    //         }
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }

    useEffect(() => {
        if (domain_data) {
            setItem_slides(domain_data.homepage_product_preview)
            let imgs = []
            for (let i = 0; i < domain_data.landing_page_images.length; i++) {
                const element = domain_data.landing_page_images[i];
                imgs.push(element.path)
            }
            setMain_slide_imgs(imgs)
        } else {
            setItem_slides([])
            setMain_slide_imgs([])
        }
        // getDeliveries()
    }, []);

    const settings = {
        arrows: false,
        autoplay: false,
        cssEase: 'linear',
        pauseOnHover: true,
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 1500,
        autoplaySpeed: 12000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: 'landingSlider',
    };

    const gotoTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }

    return (
        <div className={styles.mainContainer} id='landing'>
            {!isMobile &&
                <div className={styles.leftContainer} >
                    <Slider {...settings}>
                        <div>
                            <div className={styles.slideOne} style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex1]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color} style={{ backgroundColor: colors[ColorIndex1] }}>
                                    {_domain().title === 'UKBD' ?
                                        <div className={styles.ukbd_text}>
                                            <p className={styles.ukbd_text_eng}>
                                                UKBD is a comprehensive hub for <br /> <span>products</span> & <span>services</span> that connect <br /> Bangladeshis on both sides, <br /> <span>London</span> & <span>Dhaka</span>.
                                            </p>
                                            <p className={styles.ukbd_text_bangla}>
                                                পণ্য ও পরিষেবার একটি বাজার যা লন্ডন এবং ঢাকা <br /> উভয় দিকের বাংলাদেশীদের সংযুক্ত করে
                                            </p>
                                        </div>
                                        :
                                        <div className={styles.ukbd_text}>
                                            <p className={styles.ukbd_text_eng}>
                                                USBD is a comprehensive hub for <br /> <span>products</span> & <span>services</span> that connect <br /> Bangladeshis on both sides, <br /> <span>America</span> & <span>Dhaka</span>.
                                            </p>
                                            <p className={styles.ukbd_text_bangla}>
                                                পণ্য ও পরিষেবার একটি বাজার যা আমেরিকা এবং ঢাকা <br /> উভয় দিকের বাংলাদেশীদের সংযুক্ত করে
                                            </p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex2]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex2] }}
                                >
                                    <Link to={'/deshi-products'} className={styles.logoText}>Deshi Products</Link>
                                    <div className={styles.topTextN}>
                                        <p className={styles.topinnertext}>
                                            <span className={styles.slide_text_title}>Experience the authentic taste of Bangladeshi food!</span>
                                            <br></br><br></br>
                                            {_domain().title === 'UKBD' ?
                                                <span className={styles.slide_text_body}>
                                                    Delivering exclusive Bangladeshi products and seasonal fruits directly to doorsteps in the UK is a great way to provide customers with unique and authentic offerings. By focusing on farmers' products, you ensure that customers receive high-quality items directly from the source. This not only supports local farmers but also provides customers in the UK with a taste of the authentic flavours and produce from Bangladesh. If you have any specific questions or if there's anything else I can assist you with, please let me know!
                                                </span>
                                                :
                                                <span className={styles.slide_text_body}>
                                                    Immerse yourself in the essence of Bangladesh with our exceptional delivery service, bringing authentic Bangladeshi products and seasonal fruits directly to your doorsteps in the USA. We take immense pride in curating a distinct and genuine collection of items, carefully sourced from local farmers. By supporting these farmers, we guarantee the utmost quality and freshness in each product. The true flavours and experience the rich produce of Bangladesh, as we bring a delightful taste of home to customers in the USA. For any inquiries or further assistance, please don't hesitate to contact us. Your satisfaction is our top priority!
                                                </span>
                                            }
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex3]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex3] }}
                                >
                                    <Link to={"/find-your-host"} className={styles.logoText}> Find Your Host</Link>
                                    <div className={styles.topTextN}>
                                        {_domain().title === 'UKBD' ?
                                            <p className={styles.topinnertext}>
                                                <span className={styles.slide_text_title} >Discover Authentic Bangladeshi Hospitality in London!</span>
                                                <br></br><br></br>
                                                <span className={styles.slide_text_body}>
                                                    Connects Bangladeshi visitors with accommodating Bangladeshi hosts. Experience London's vibrant atmosphere while staying with Bangladeshi hosts who understand your needs, language, and traditions. A personalised connection in London, offering a warm welcome, insider tips, and a genuine cultural exchange for Bangladeshi visitors.
                                                </span>
                                            </p>
                                            :
                                            <p className={styles.topinnertext}>
                                                <span className={styles.slide_text_title} >Experience the genuine hospitality of Bangladesh in USA!</span>
                                                <br></br><br></br>
                                                <span className={styles.slide_text_body}>
                                                    Connects Bangladeshi visitors with accommodating Bangladeshi hosts. Experience America's vibrant atmosphere while staying with Bangladeshi hosts who understand your needs, language, and traditions. A personalised connection in States, offering a warm welcome, insider tips, and a genuine cultural exchange for Bangladeshi visitors.
                                                </span>
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex4]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex4] }}
                                >
                                    <Link to={"/study-in-uk"} className={styles.logoText}>
                                        {_domain().title === 'UKBD' ?
                                            'Study in the UK'
                                            :
                                            'Study in the USA'
                                        }
                                    </Link>
                                    <div className={styles.topText}>
                                        {_domain().title === 'UKBD' ?
                                            <p className={styles.topinnertext}>
                                                We empower Bangladeshi students to make
                                                well-informed decisions that ensure successful admission to prestigious UK universities.
                                            </p>
                                            :
                                            <p className={styles.topinnertext}>
                                                We are committed to enhancing opportunities for Bangladeshi students to access American universities.
                                            </p>
                                        }
                                    </div>
                                    <div className={styles.imageBox}>
                                        <img src={students} className={styles.flag} alt="flag-UK" />
                                    </div>
                                    <div className={styles.bottomText}>
                                        {_domain().title === 'UKBD' ?
                                            <p className={styles.bottominnertext}>Finding suitable <span>accommodation</span> in the UK can pose a significant challenge for international students. We specialise in helping Bangladeshi students discover <span>cost-effective, secure</span>, & <span>strategically located</span> housing options for their academic journey.</p>
                                            :
                                            <p className={styles.bottominnertext}>Our dedicated team provides comprehensive guidance and support throughout the admissions process. With expert advice and a diverse range of options, we empower students to make informed decisions about their academic journey. By leveraging our assistance, Bangladeshi students can elevate their prospects and maximize their chances of securing admission to esteemed American universities.</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex5]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex5] }}
                                >
                                    <Link to={"/work-in-uk"} className={styles.logoText}>
                                        {_domain().title === 'UKBD' ?
                                            <p className={styles.title}>Work & Settle in the UK</p>
                                            :
                                            <p className={styles.title}>Work & Settle in the USA</p>
                                        }
                                    </Link>
                                    <div className={styles.topTextN}>
                                        {_domain().title === 'UKBD' ?
                                            <p className={styles.topinnertext}>
                                                <span className={styles.slide_text_title}>The most effective path to becoming a British citizen!</span>
                                                <br></br><br></br>
                                                <span className={styles.slide_text_body}>
                                                    Brexit has created new opportunities for individuals from countries outside the EU, including Bangladeshi talents and job seekers. Obtaining a job and British citizenship is a significant step towards building a bright future for you and your family. It offers a wide array of benefits, rights, and opportunities that can positively impact various aspects of your personal, professional, and social life.
                                                </span>
                                            </p>
                                            :
                                            <p className={styles.topinnertext}>
                                                <span className={styles.slide_text_title}>Become an American Citizen!</span>
                                                <br></br><br></br>
                                                <p className={styles.slide_text_body}>
                                                    <span> Let us handle the intricacies of U.S. immigration laws and regulations, ensuring a seamless and efficient path to citizenship. We will expertly complete all the required forms and documentation, saving you valuable time and reducing the risk of errors or delays. Trust our expertise to navigate the complexities of the process, providing you with a hassle-free journey towards achieving U.S. citizenship.</span>
                                                    <br />
                                                    <span>Connect with welcoming Bangladeshi hosts and immerse yourself in the vibrant American atmosphere! Experience a unique stay with hosts who understand your needs, language, and traditions. Enjoy a personalized connection in the United States, where you'll receive a warm welcome, insider tips, and an authentic cultural exchange tailored specifically for Bangladeshi visitors. Discover a home away from home, where you can truly feel comfortable and embrace the best of both worlds.</span>
                                                </p>
                                            </p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex6]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex6], display: 'flow-root' }}
                                >
                                    <Link to={"/property-management"} className={styles.logoText}>
                                        <p className={styles.title}>Property Management</p>
                                    </Link>
                                    <div className={styles.topTextN}>
                                        <p className={styles.topinnertext}>
                                            <span className={styles.slide_text_title}>Dedicated care & protection for your assets in Bangladesh!</span>
                                            <br></br><br></br>
                                            <span className={styles.slide_text_body}>
                                                Let us take the burden off your shoulders & turn your dreams into a reality. We understand the challenges faced by non-resident Bangladeshis in managing their properties and assets. Our comprehensive business and marketing solutions empower you to protect and grow your investments, making your dreams a tangible success. Leave the worries behind and entrust us with the care and management of your properties and assets. Our expert team is committed to transforming your aspirations into actuality, offering unrivaled support every step of the way. Together, we'll navigate the challenges, bringing your vision to life with unparalleled expertise and support. Maximize the potential of your properties and assets back home with our exclusive professionals and enginering support for non-resident Bangladeshis. With our comprehensive range of property management solutions will cater your properties and assets with the utmost care and expertise. We'll help you achieve your goals and transform your dreams into a flourishing reality. Let us be your trusted partner in turning those aspirations into tangible accomplishments.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className={styles.slideTwo}
                                style={{ backgroundImage: `url(${_domain().imageURL}${main_slide_imgs[ImageIndex7]})` }}
                                onMouseOver={handleMouseOver}
                                onMouseLeave={handleMouseLeave}
                            >
                                <div className={styles.overlay_color}
                                    style={{ backgroundColor: colors[ColorIndex7] }}
                                >
                                    <Link to={"/send-money"} className={styles.logoText}>Send Money</Link>
                                    <div className={styles.topTextN}>
                                        <p className={styles.topinnertext}>
                                            <span className={styles.slide_text_title}>Transfer funds instantly to your loved ones back home!</span>
                                            <br></br><br></br>
                                            <span className={styles.slide_text_body}>
                                                Introducing our securely & hassle-free money transfer service, designed to bring peace of mind and convenience to your life. With our platform, you can now send money promptly and securely to your beloved family members back in your home country.
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider >
                </div>
            }
            <RightContainer item_slides={item_slides} gotoTop={gotoTop} />
            {/* <div className={styles.arrow_container}>
                <div className={styles.arrow}></div>
            </div> */}
            <div className={styles.line1_and_line2_container}>
                <div className={styles.line1_vertical}></div>
                <div className={styles.line2_vertical}></div>
                <div className={styles.line_cursor_box} onClick={() => gotoTop('ukeubrands')}></div>
            </div>
        </div >
    )
}

const RightContainer = ({ item_slides, gotoTop }) => {
    const BottomSliderSettings = {
        arrows: false,
        autoplay: true,
        autoplaySpeed: 2500,
        cssEase: "linear",
        pauseOnHover: true,
        dots: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        className: styles.imgSliderContainer,
    };

    return (
        <div className={styles.rightContainer} id='secondContainer' >
            <div className={styles.right_top_section}>
                <div className={styles.ukbd_icon}>
                    <img src={_domain().logo} alt="" />
                </div>
                <div className={styles.applinks}>
                    <div className={styles.applink}>
                        <img src={gApp} alt='app' />
                    </div>
                    <div className={styles.applink}>
                        <img src={iApp} alt='app' />
                    </div>
                </div>
                <div className={styles.productText}>
                    <p className={styles.productText_eng}>PRODUCTS & SERVICES {_domain().title} OFFERS</p>
                </div>
            </div>
            <div className={styles.btns}>
                {_domain().service.map((v, i) =>
                    <div className={styles.rightBtns} key={'service_' + i} >
                        {
                            // (v.name !== 'REMITTANCE' && v.name !== 'EXPORT' && v.name !== 'INVESTMENT') && isMobile ?
                            v.name === 'UK & EU BRANDS' ?
                                <div className={styles.btnBody} onClick={() => gotoTop('ukeubrands')} title={v.title}>{v.name}</div>
                                :
                                <Link to={v.url} className={styles.btnBody} title={v.title}>{v.name}</Link>
                            // : ''
                        }
                    </div>
                )}
            </div>
            <div className={styles.item_slider_section}>
                <Slider {...BottomSliderSettings}>
                    {item_slides.map((v, i) =>
                        <div className={styles.slides} key={i + '_slide'}>
                            <Link to={`https://ukbd.app/product/${v.product_id}`} className={styles.imgBox}>
                                <img src={`${_domain().imageURL}${v.path}`} alt={v.text} />
                            </Link>
                        </div>
                    )}
                </Slider>
            </div>
            <div className={styles.endingText}>
                <p className={styles.endingText_eng}>Delivering 100% authentic British & European <br /> products directly to the doorsteps of Bangladesh</p>
            </div>
        </div >
    )
}

export default Landing;