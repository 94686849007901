/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, useState, useEffect, useCallback } from 'react'
import { GoogleMap, useJsApiLoader, Polyline, Marker, InfoWindow } from '@react-google-maps/api'
import { useNavigate, useParams } from 'react-router-dom';
import 'moment-timezone';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import { appStatic } from '../../../Config/Config'
import { getUserData, get_order_detail, get_provider_location } from '../../../Utils/method'
import { priceConvertTo, getCurrencySign } from '../../../Utils/common'
import styles from '../../../assets/css/Map.module.css';
import classNames from 'classnames';
import Loader from '../../Loader';
import { isMobile } from 'react-device-detect';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useCart } from 'react-use-cart';
// import MapOrderDetails from './MapOrderDetails';
import { _domain } from '../../../Config/Domains';
import provider from '../../../assets/provider.png'
import circle from '../../../assets/circle.svg'
import square from '../../../assets/square.svg'

const containerStyle = {
    width: '100%',
    height: isMobile ? 'calc(100% - 36px)' : 'calc(100% - 60px)',
    marginTop: isMobile ? '36px' : '60px'
};

const center = {
    lat: 42.006,
    lng: 45.863
};
const center2 = {
    lat: 23.847,
    lng: 90.405
};
const center3 = {
    lat: 23.791,
    lng: 90.401
};

const onLoad = polyline => {
    console.log('polyline: ', polyline)
};

const path = [
    { lat: 21.637, lng: 89.110 },   //Bangladesh
    { lat: 51.502, lng: -0.122 },   //London
];

const path2 = [
    { lat: 51.502, lng: -0.122 },
    { lat: 23.847, lng: 90.405 }    //customs
];

const OrderTracking = () => {
    const params = useParams();
    const navigate = useNavigate()
    const user = getUserData({ type: 'get' });
    // const { addItem, items } = useCart();
    // const stores = useSelector((state) => state.rootReducer.stores);
    // const city = useSelector((state) => state.rootReducer.city);
    // const country = useSelector((state) => state.rootReducer.country);
    // const ordersList = useSelector((state) => state.rootReducer.ordersList);
    const [orderItems, setOrderItems] = useState(false);
    const [showOrderList, setShowOrderList] = useState(false);
    const [map, setMap] = useState(null)
    const [order, setOrder] = useState(null)
    const [loader, setLoader] = useState(true);
    const [deliveryManLocation, setDeliveryManLocation] = useState(null);


    const [response, setResponse] = useState(null);
    const directionCallback = (response) => {
        if (response !== null) {
            if (response.status === 'OK') {
                setResponse(response);
            } else {
                console.log('Directions request failed due to ' + response.status);
            }
        }
    };

    const { isLoaded, loadError } = useJsApiLoader({ googleMapsApiKey: appStatic.GOOGLE_KEY })

    const [currMapStates, setCurrMapStates] = useState(1)
    const [mapStates, setMapStates] = useState({
        // pending: false,
        accepted: false,
        prepared: false,
        waytoBd: false,
        bdCustoms: false,
        wareHouse: false,
        wayToYou: false,
        Delivered: false,
    })

    const stage = (s) => {
        const statusMap = {
            3: 1,
            5: 2,
            50: 3,
            51: 4,
            52: 5,
            7: 5,
            99: 6,
            25: 7
        };

        return statusMap[s] || 0;
    }

    const statusMappings = {
        // 1: { pending: true, accepted: false, prepared: false, waytoBd: false, bdCustoms: false, wareHouse: false, wayToYou: false, Delivered: false },
        3: { pending: false, accepted: true, prepared: false, waytoBd: false, bdCustoms: false, wareHouse: false, wayToYou: false, Delivered: false },
        5: { pending: false, accepted: true, prepared: true, waytoBd: false, bdCustoms: false, wareHouse: false, wayToYou: false, Delivered: false },
        50: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: false, wareHouse: false, wayToYou: false, Delivered: false },
        51: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: true, wareHouse: false, wayToYou: false, Delivered: false },
        52: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: true, wareHouse: true, wayToYou: false, Delivered: false },
        7: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: true, wareHouse: true, wayToYou: false, Delivered: false },
        99: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: true, wareHouse: true, wayToYou: true, Delivered: false }, //custom Number
        25: { pending: false, accepted: true, prepared: true, waytoBd: true, bdCustoms: true, wareHouse: true, wayToYou: true, Delivered: true }
    };

    useEffect(() => {
        let isMounted = true;

        const updateStatus = async () => {
            setLoader(true);
            try {
                const apires = await get_order_detail({ order_id: params._id });
                const order = apires.order;
                const { order_status: s } = order;
                const ds = order?.request_detail?.delivery_status;
                setOrder(order)
                console.log(130, s, ds);

                if (statusMappings[s]) {
                    if (s === 7 && ds && (ds === 11 || ds === 13 || ds === 15 || ds === 17 || ds === 19 || ds === 21)) {
                        setMapStates(statusMappings[99]);
                        setCurrMapStates(stage(99));
                    } else {
                        setMapStates(statusMappings[s]);
                        setCurrMapStates(stage(s));
                    }
                }

                console.log(76, order);
            } catch (error) {
                console.log(error);
            } finally {
                if (isMounted) {
                    setLoader(false);
                }
            }
        };

        updateStatus();
        return () => isMounted = false;
    }, [params._id]);

    useEffect(() => {
        const get_location = async () => {
            // console.log(303, order);
            if (order) {
                try {
                    let json = {
                        provider_id: order?.provider_detail?.[0]?._id || '',
                        server_token: user?.server_token,
                        user_id: user?._id,
                        order_id: order?._id
                    }
                    let apires = await get_provider_location(json)
                    if (apires.success) {
                        setDeliveryManLocation(apires.provider_location);
                    }
                    // console.log("🚀 ~ constget_location= ~ apires:", apires)
                } catch (error) {
                    console.log("🚀 ~ useEffect ~ error:", error)
                }
            }
        }
        get_location()

        const intervalId = setInterval(get_location, 3000);

        return () => clearInterval(intervalId);
    }, [order]);

    const onLoadMap = useCallback(function callback(map) { setMap(map) }, [])

    const onUnmount = useCallback(function callback(map) {
        setMap(null)
    }, [])

    // const addtoCartthisItem = async (data, id, q) => {
    //     try {
    //         const product_details = stores.filter(v => v._id === data.store_id)[0].products.filter(v => v._id === data.product_id)[0]
    //         const store_details = stores.filter(v => v._id === data.store_id)[0]
    //         addItem({ ...data, id: data._id, price: data.price, product_details: product_details }, q)
    //         // if (q === 'initial') {
    //         //     addItem({ ...data, id: data._id, price: data.price, product_details: product_details }, )
    //         // } else {
    //         //     updateItemQuantity(id, q)
    //         // }
    //         await AddToCart({ items: items.filter(v => v.id !== data._id), item: data, store_details, product_details, city, country, q: q === 'initial' ? 1 : q })
    //     } catch (error) {
    //         toast.error(error.status_phrase, { autoClose: 1000 });
    //         console.log(error);
    //     }
    // }

    // const addItemsToCart = async (items) => {
    //     console.log(269, items);
    //     for (let i = 0; i < items.length; i++) {
    //         let element = items[i];
    //         addtoCartthisItem(element, element._id, element.quantity)
    //     }
    //     setOrderItems(false)
    //     toast.success('Order items added to your cart successfully.', { autoClose: 1000 });
    // }

    const [selectedMarker, setSelectedMarker] = useState(null);
    const handleMarkerClick = (marker) => {
        setSelectedMarker(marker);
    };

    const closeInfoWindow = () => {
        setSelectedMarker(null);
    };

    useEffect(() => {
        console.log(371, selectedMarker);
    }, [selectedMarker])


    if (loader) {
        return <Loader />
    } else {
        if (isLoaded) {
            const options = {
                strokeColor: '#FF0000',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: '#FF0000',
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: 30000,
                paths: path,
                zIndex: 1,
                icons: [
                    {
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 3,
                            fillColor: '#ff0000',
                            fillOpacity: 1,
                            strokeColor: 'none',
                        },
                        offset: '0',
                        repeat: '100%' // Adjust the spacing between the dots as needed
                    }
                ],
            };
            const options2 = {
                strokeColor: 'green',
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: 'green',
                fillOpacity: 0.35,
                clickable: false,
                draggable: false,
                editable: false,
                visible: true,
                radius: 30000,
                paths: path2,
                zIndex: 1,
                icons: [
                    {
                        icon: {
                            path: window.google.maps.SymbolPath.CIRCLE,
                            scale: 3,
                            fillColor: 'green',
                            fillOpacity: 1,
                            strokeColor: 'none',
                        },
                        offset: '0',
                        repeat: '100%' // Adjust the spacing between the dots as needed
                    }
                ],
            };
            const driverIcon = {
                url: provider, // URL to your driver icon image
                scaledSize: new window.google.maps.Size(28, 38), // Size of the icon
                origin: new window.google.maps.Point(0, 0), // Origin point of the icon (0,0 means top-left)
                anchor: new window.google.maps.Point(20, 20) // Anchor point of the icon (20,20 means center)
            };

            return (
                <div className={styles.mapContainer}>
                    <ToastContainer newestOnTop />
                    <div className={styles.mapMainPopup}>
                        <div className={styles.map_header}>
                            <div className={styles.mapHeaderRight}>
                                <div className={styles.orderDetails}>
                                    {/* <p className={styles.home_button} onClick={() => setShowOrderList(true)}>
                                        <span className={styles.icon_style} onClick={() => navigate('/')}>
                                            <i className="fa-thin fa-house"></i>
                                        </span>
                                    </p> */}
                                    <p className={styles.order_id} onClick={() => setShowOrderList(true)}>Order ID: {order?._id}</p>
                                    <p className={styles.order_time} onClick={() => setShowOrderList(true)}><span style={{ fontWeight: '600' }}> {moment(order?.created_at).format('ddd, MMM Do YYYY, ')}</span><span>{moment(order?.created_at).format('hh:mm A')}</span> </p>
                                    <p className={styles.order_price} style={{ fontWeight: '600' }} onClick={() => setShowOrderList(true)}>
                                        {order?.payment_currency === undefined || order?.payment_currency === '' ?
                                            <CurrencyFormat value={order?.total_order_price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                            : <CurrencyFormat value={priceConvertTo(order?.total_order_price, order)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => value} />
                                        }
                                    </p>
                                    <p className={styles.items_button} onClick={() => setOrderItems(!orderItems)}>View Order Items <i className="fa-thin fa-chevron-down" style={{ fontSize: 12 }}></i></p>
                                    <span className={styles.closeCart} onClick={() => navigate(-1)}>
                                        <i className="fa-thin fa-xmark"></i>
                                    </span>
                                </div>
                            </div>
                        </div>

                        {/* {orderItems &&
                            <OutsideClickHandler onOutsideClick={() => setOrderItems(false)}>
                                <div className={styles.orderItems_popup}>
                                    <div className={styles.orderItems_LIST}>
                                        {order?.cart_detail.order_details.length > 0 ?
                                            <CartItems type={'order_item'} order={order} />
                                            :
                                            <div style={{ height: 100, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>No Items Found!</div>
                                        }
                                    </div>
                                    <div className={styles.addToBasket} style={order?.cart_detail.order_details.length > 0 ? {} : { opacity: '0.5', pointerEvents: 'none' }} onClick={() => addItemsToCart(order?.cart_detail.order_details)}>
                                        Add all to basket
                                    </div>
                                </div>
                            </OutsideClickHandler>
                        } */}

                        {/* {showOrderList &&
                            <OutsideClickHandler onOutsideClick={() => setShowOrderList(false)}>
                                <Orders orderList={ordersList} from={'map'} setShowOrderList={setShowOrderList} />
                            </OutsideClickHandler>
                        } */}
                        <div className={styles.orderState}>
                            {mapStates.pending ? <p className={classNames(mapStates.pending ? '' : styles.inactive)}>Pending</p>
                                :
                                <p className={classNames(mapStates.accepted ? '' : styles.inactive)}>Accepted</p>
                            }
                            <p className={classNames(mapStates.prepared ? '' : styles.inactive)}>Prepared</p>
                            <p className={classNames(mapStates.waytoBd ? '' : styles.inactive)}>Way to BD</p>
                            <p className={classNames(mapStates.bdCustoms ? '' : styles.inactive)}>BD Customs</p>
                            <p className={classNames(mapStates.wareHouse ? '' : styles.inactive)}>{_domain().title} Warehouse</p>
                            <p className={classNames(mapStates.wayToYou ? '' : styles.inactive)}>Way to You</p>
                            <p className={classNames(mapStates.Delivered ? '' : styles.inactive)}>Delivered</p>
                        </div>
                    </div>
                    <div className={styles.orderStateTimeline}>
                        <ul className={styles.timeline}>
                            {mapStates.pending ? <li className={classNames(styles.timeline_item, mapStates.pending ? '' : styles.inactive)} data-date="Pending"></li>
                                :
                                <li className={classNames(styles.timeline_item, mapStates.accepted ? '' : styles.inactive)} data-date="Accepted"></li>
                            }
                            <li className={classNames(styles.timeline_item, mapStates.prepared ? '' : styles.inactive)} data-date="Prepared"></li>
                            <li className={classNames(styles.timeline_item, mapStates.waytoBd ? '' : styles.inactive)} data-date="Way to BD"></li>
                            <li className={classNames(styles.timeline_item, mapStates.bdCustoms ? '' : styles.inactive)} data-date="BD Customs"></li>
                            <li className={classNames(styles.timeline_item, mapStates.wareHouse ? '' : styles.inactive)} data-date="UKBD Warehouse"></li>
                            <li className={classNames(styles.timeline_item, mapStates.wayToYou ? '' : styles.inactive)} data-date="Way to You"></li>
                            <li className={classNames(styles.timeline_item, mapStates.Delivered ? '' : styles.inactive)} data-date="Delivered"></li>
                        </ul>
                    </div>

                    {/* {orderItems && <MapOrderDetails order={order} setOrderItems={setOrderItems} />} */}
                    <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={
                            currMapStates < 4 ? center
                                : currMapStates === 4 ? center2
                                    : currMapStates === 5 ? center3
                                        : currMapStates === 7 ?
                                            { lat: order?.destination_addresses?.[0].location[0], lng: order?.destination_addresses?.[0].location[1] }
                                            : { lat: order?.destination_addresses?.[0].location[0], lng: order?.destination_addresses?.[0].location[1] }
                        }
                        zoom={currMapStates === 4 || currMapStates === 5 || currMapStates === 7 ? 15 : currMapStates === 6 ? 15 : 3}
                        // onClick={(e) => getMapdata(e)}
                        onLoad={onLoadMap}
                        onUnmount={onUnmount}
                        options={{
                            zoomControlOptions: {
                                position: window.google.maps.ControlPosition.RIGHT_CENTER // 'right-center' ,
                            },
                            zoomControl: false,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false
                        }}
                    >
                        {(currMapStates < 4) &&
                            <>
                                <Polyline onLoad={onLoad} path={path} options={options} />
                                {mapStates.waytoBd && <Polyline onLoad={onLoad} path={path2} options={options2} />}
                            </>
                        }

                        {(currMapStates === 4) &&
                            <Marker icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 10,
                                fillColor: "#ff0000",
                                fillOpacity: 1,
                                strokeColor: "#ff0000",
                                strokeWeight: 0.5
                            }}
                                position={center2}
                            />
                        }

                        {(currMapStates === 5) &&
                            <Marker icon={{
                                path: window.google.maps.SymbolPath.CIRCLE,
                                scale: 10,
                                fillColor: "#ff0000",
                                fillOpacity: 1,
                                strokeColor: "#ff0000",
                                strokeWeight: 0.5
                            }}
                                position={center3}
                            />
                        }

                        {(currMapStates === 6) &&
                            <>
                                {/* <Marker icon={{
                                    path: window.google.maps.SymbolPath.CIRCLE,
                                    scale: 10,
                                    fillColor: "#ff0000",
                                    fillOpacity: 1,
                                    strokeColor: "#ff0000",
                                    strokeWeight: 0.5
                                }}
                                    position={center3}
                                /> */}
                                {deliveryManLocation &&
                                    <>
                                        <Marker
                                            // icon={driverIcon}
                                            icon={{
                                                url: circle, // URL to your driver icon image
                                                // scaledSize: new window.google.maps.Size(28, 38), // Size of the icon
                                                origin: new window.google.maps.Point(0, 0), // Origin point of the icon (0,0 means top-left)
                                                anchor: new window.google.maps.Point(20, 20) // Anchor point of the icon (20,20 means center)
                                            }}
                                            position={{
                                                lat: deliveryManLocation?.[0],
                                                lng: deliveryManLocation?.[1]
                                            }}
                                            onClick={() => handleMarkerClick({
                                                lat: deliveryManLocation?.[0],
                                                lng: deliveryManLocation?.[1]
                                            })}
                                        />

                                        {selectedMarker && (
                                            <InfoWindow
                                                position={selectedMarker}
                                                onCloseClick={closeInfoWindow}
                                                options={{ maxWidth: 300 }}
                                            >
                                                <div className={styles.info_container}>
                                                    <h2>{`${order.provider_detail?.[0]?.first_name} ${order.provider_detail?.[0]?.last_name}`}</h2>
                                                    <p>Phone : {`${order.provider_detail?.[0]?.phone}`}</p>
                                                    <p>Email : {`${order.provider_detail?.[0]?.email}`}</p>
                                                </div>
                                            </InfoWindow>
                                        )}

                                        {/* <DirectionsService
                                            options={{
                                                destination: {
                                                    lat: order?.destination_addresses?.[0].location[0],
                                                    lng: order?.destination_addresses?.[0].location[1]
                                                },
                                                origin: new window.google.maps.LatLng(deliveryManLocation?.[0], deliveryManLocation?.[1]),
                                                travelMode: window.google.maps.TravelMode.DRIVING,
                                            }}
                                            callback={directionCallback}
                                        />
                                        {response && <DirectionsRenderer directions={response} options={{
                                            polylineOptions: {
                                                strokeColor: '#000', // Change direction color
                                                strokeWeight: 2, // Change direction weight
                                            },
                                            suppressMarkers: true, 
                                        }} />} */}
                                    </>
                                }
                                <Marker
                                    icon={{
                                        url: square, // URL to your driver icon image
                                        // scaledSize: new window.google.maps.Size(28, 38), // Size of the icon
                                        origin: new window.google.maps.Point(0, 0), // Origin point of the icon (0,0 means top-left)
                                        anchor: new window.google.maps.Point(20, 20) // Anchor point of the icon (20,20 means center)
                                    }}
                                    position={{
                                        lat: order?.destination_addresses?.[0].location[0],
                                        lng: order?.destination_addresses?.[0].location[1]
                                    }}
                                />
                            </>
                        }

                        {(currMapStates === 7) &&
                            <Marker
                                icon={"https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png"}
                                position={{
                                    lat: order?.destination_addresses?.[0].location[0],
                                    lng: order?.destination_addresses?.[0].location[1]
                                }}
                            />
                        }
                    </GoogleMap>
                </div >
            )
        } else {
            return (
                <div>Maps Loading</div>
            )
        }
    }

}

export default memo(OrderTracking);