import { useState } from 'react';
import styles from '../../assets/css/JobApplication.module.css';
import flag_bd from '../../assets/flag-BD.png';
import classNames from 'classnames';
import Select from 'react-select';
import Documents from './Documents';
import OTPVerification from './OTPVerification';
import Message from './Message';
import { add_new_job_application } from '../../Utils/method';
// import { ToastContainer, toast } from 'react-toastify';
import pdf from '../../assets/pdf.svg'
import { useEffect } from 'react';
// import Loader from '../Loader';

const getCurrentDomain = () => {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    return domainParts[0];
};

const currentDomain = getCurrentDomain();

const selectStyles = {
    control: (base, provided) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',
        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px',
    }),
    placeholder: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 15px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'
    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',

    })
    ,
    multiValue: (base) => ({
        ...base,
        background: '#ced4da',
        borderRadius: '0px',
        marginTop: '0px',
        fontWeight: '400',
        lineHeight: '18px',
        position: 'relative',
        top: '-2px',
        left: '-10px'
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    })
}

const IeltsOptions = [
    { value: '4.0', label: '4.0' },
    { value: '4.5', label: '4.5' },
    { value: '5.0', label: '5.0' },
    { value: '5.5', label: '5.5' },
    { value: '6.0', label: '6.0' },
    { value: '6.5', label: '6.5' },
    { value: '7.0', label: '7.0' },
    { value: '7.5', label: '7.5' },
    { value: '8.0', label: '8.0' },
    { value: '8.5', label: '8.5' },
    { value: '9.0', label: '9.0' },
    { value: 'Not Available', label: 'Not Available' },
]

const SalaryOptions = [
    { value: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}`, label: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}` },
    { value: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}`, label: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}` },
    { value: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}`, label: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}` },
    { value: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}`, label: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}` },
    { value: 'other', label: 'Other' },
]

const JobApplication = ({ current_job, setJob_application }) => {
    const [success, setSuccess] = useState(false)
    const [verification, setVerification] = useState(false)
    const [loader, setLoader] = useState(false);
    const [terms, setTerms] = useState(false);
    const [page_state, setPage_state] = useState(1);
    const [doc_popup, setDoc_popup] = useState(false);

    const [form_data, setForm_data] = useState({
        job_id: '',
        applicaiton_for: currentDomain,
        job_title: '',
        full_name: '',
        university_name: '',
        course_title: '',
        ielts_score: '',
        current_job: '',
        annual_salary: '',
        mobile: '',
        email: '',
        doc_passport: { name: '', image: '', url: '' },
        doc_nid: { name: '', image: '', url: '' },
        doc_last_degree: { name: '', image: '', url: '' },
        doc_birth_certificate: { name: '', image: '', url: '' },
        doc_cv: { name: '', image: '', url: '' },
    });

    const reset_form = () => {
        setForm_data({
            job_id: '',
            applicaiton_for: currentDomain,
            job_title: '',
            full_name: '',
            university_name: '',
            course_title: '',
            ielts_score: '',
            current_job: '',
            annual_salary: '',
            mobile: '',
            email: '',
            doc_passport: { name: '', image: '', url: '' },
            doc_nid: { name: '', image: '', url: '' },
            doc_last_degree: { name: '', image: '', url: '' },
            doc_birth_certificate: { name: '', image: '', url: '' },
            doc_cv: { name: '', image: '', url: '' },
        })
    }

    useEffect(() => {
        setForm_data({ ...form_data, job_title: current_job.name.toString(), job_id: current_job.source_link })
    }, [])

    const validateEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    };

    const [requiredFields, setRequiredFields] = useState([]);
    const [application_id, setApplication_id] = useState('');
    const [email_error, setEmail_error] = useState('');
    const [phone_error, setPhone_error] = useState('');

    function findEmptyKeys(obj) {
        const emptyKeys = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (key === 'doc_passport' || key === 'doc_nid' || key === 'doc_last_degree' || key === 'doc_birth_certificate' || key === 'doc_cv') {
                    // if (value.url === '') {
                    //     emptyKeys.push(key);
                    // }
                } else {
                    if (value === '') {
                        emptyKeys.push(key);
                        if (key === 'email') {
                            setEmail_error('')
                        } else if (key === 'mobile') {
                            setPhone_error('')
                        }
                    } else {
                        if (key === 'email' && !validateEmail(value)) {
                            setEmail_error('Enter valid email')
                            emptyKeys.push(key);
                        } else if (key === 'mobile' && value.length < 10) {
                            setPhone_error('Enter valid mobile')
                            emptyKeys.push(key);
                        } else {
                            setEmail_error('')
                            setPhone_error('')
                        }
                    }
                }
            }
        }
        setRequiredFields(emptyKeys)
        return emptyKeys;
    }

    const onSubmitApplication = async () => {
        try {
            const emptyKeys = findEmptyKeys(form_data);
            console.log(304, emptyKeys);

            if (emptyKeys.length > 0) {
                setPage_state(1)
                return false;
            } else {
                if (page_state === 1) {
                    setPage_state(2)
                    return false;
                }
            }

            setLoader(true)

            let full_name = form_data.full_name;
            let name_part = full_name.split(' ');
            let last_name = name_part.pop();
            let first_name = name_part.join(' ');

            let json = {
                job_id: form_data.job_id,
                job_title: form_data.job_title,
                current_job: form_data.current_job,
                applicaiton_for: form_data.applicaiton_for,
                first_name: first_name,
                last_name: last_name,
                university_name: form_data.university_name,
                course_title: form_data.course_title,
                ielts_score: form_data.ielts_score,
                current_sponsorship: form_data.current_sponsorship,
                annual_salary: form_data.annual_salary,
                mobile: '+880' + form_data.mobile,
                email: form_data.email,
                doc_passport: form_data.doc_passport.url,
                doc_nid: form_data.doc_nid.url,
                doc_last_degree: form_data.doc_last_degree.url,
                doc_birth_certificate: form_data.doc_birth_certificate.url,
                doc_cv: form_data.doc_cv.url,
            }

            // setVerification(true);
            let apires = await add_new_job_application(json);
            if (apires.success) {
                console.log(164, apires.data);
                setApplication_id(apires.data.application_id)
                if (page_state === 3) {
                    setPage_state(4)
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const inputOnChange = (e) => {
        const { name, value } = e.target;
        console.log(76, name, value);
        if (name === 'terms') {
            setTerms(e.target.checked)
        } else if (name === 'mobile') {
            if (value !== '0') {
                if (value.length <= 10 && /^\d*$/.test(value)) {
                    setForm_data({ ...form_data, [name]: value })
                }
            }
        } else if (name === 'files') {
            setForm_data({ ...form_data, [name]: e.target.files[0] })
        } else {
            setForm_data({ ...form_data, [name]: value })
        }
    }

    const [selected_itelts, setSelected_itelts] = useState(null)
    const [selected_salary, setSelected_salary] = useState(null)
    const handleIeltsScore = (selectedOption) => {
        setSelected_itelts(selectedOption)
        setForm_data({
            ...form_data,
            ielts_score: selectedOption ? selectedOption.value : '',
        })
    }

    const handleSalaryRange = (selectedOption) => {
        setSelected_salary(selectedOption)
        setForm_data({
            ...form_data,
            annual_salary: selectedOption ? selectedOption.value : '',
        })
    }

    const inputs = document.querySelectorAll('input[type="text"], input[type="number"]');

    inputs.forEach(function (input) {
        input.addEventListener('input', function () {
            const inputValue = input.value.trim();
            if (inputValue !== '') {
                input.style.backgroundColor = '#01296508';
            } else {
                input.style.backgroundColor = '#fff';
            }
        });
    });

    const cancelPopup = () => {
        if (page_state === 1) {
            setJob_application(false)
        } else if (page_state === 3) {
            setPage_state(2)
        }
    }

    return (
        <div className={styles.mainContainer}>
            <div className={styles.sponsorship_popup}>
                <span className={styles.closeCart} onClick={cancelPopup}><i className="fa-thin fa-xmark"></i></span>
                {page_state === 1 &&
                    <div className={styles.sponsor_header}>
                        <p className={styles.sponsor_header_title}>LET'S START NOW</p>
                        <p className={styles.sponsor_header_sub_title}>APPLY FOR TIER 2 VISA PRE-ASSESSMENT</p>
                        <p className={styles.assessmentJob_title}>Job Title: {form_data.job_title}</p>
                    </div>
                }
                {page_state === 3 &&
                    <div className={styles.sponsor_header}>
                        <p className={styles.sponsor_header_title} style={{ textAlign: 'left' }}>INFORMATION PREVIEW</p>
                    </div>
                }
                <div className={styles.sponsor_body}>
                    {page_state === 1 &&
                        <div className={styles.formContainer}>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('full_name') > -1 && <span className={styles.requiredLabel}>*required</span>} Full Name</label>
                                <input className={classNames(styles.input, styles.inputFull, requiredFields.indexOf('full_name') > -1 && styles.error_input)} value={form_data.full_name} name='full_name' onChange={(e) => inputOnChange(e)} type='text' placeholder='Enter your full name' autoFocus />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('university_name') > -1 && <span className={styles.requiredLabel}>*required</span>} University</label>
                                <input className={classNames(styles.input, styles.inputFull, requiredFields.indexOf('university_name') > -1 && styles.error_input)} value={form_data.university_name} name='university_name' onChange={(e) => inputOnChange(e)} type='text' placeholder='University of your latest degree' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('course_title') > -1 && <span className={styles.requiredLabel}>*required</span>} Course Title</label>
                                <input className={classNames(styles.input, requiredFields.indexOf('course_title') > -1 && styles.error_input)} value={form_data.course_title} name='course_title' onChange={(e) => inputOnChange(e)} type='text' placeholder='Enter course title' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('ielts_score') > -1 && <span className={styles.requiredLabel}>*required</span>} IELTS Score</label>
                                <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('ielts_score') > -1 && styles.error_input)}
                                    defaultValue={''}
                                    isSearchable={true}
                                    placeholder='Ielts score'
                                    name='ielts_score'
                                    onChange={handleIeltsScore}
                                    options={IeltsOptions}
                                    styles={selectStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#0030192d',
                                            primary: 'rgba(0, 48, 25, .75)',
                                        },
                                    })}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('current_job') > -1 && <span className={styles.requiredLabel}>*required</span>} Current Job</label>
                                <input className={classNames(styles.input, requiredFields.indexOf('current_job') > -1 && styles.error_input)} value={form_data.current_job} name='current_job' onChange={(e) => inputOnChange(e)} type='text' placeholder='Enter your Current Job' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('annual_salary') > -1 && <span className={styles.requiredLabel}>*required</span>} Annual Salary</label>
                                <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('annual_salary') > -1 && styles.error_input)}
                                    defaultValue={''}
                                    isSearchable={true}
                                    placeholder='Salary range'
                                    name='annual_salary'
                                    onChange={handleSalaryRange}
                                    options={SalaryOptions}
                                    styles={selectStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#0030192d',
                                            primary: 'rgba(0, 48, 25, .75)',
                                        },
                                    })}
                                />
                            </div>
                            {form_data.annual_salary === 'other' &&
                                <div className={styles.inputGroup} style={{ marginTop: '12px' }}>
                                    <input className={classNames(styles.input, requiredFields.indexOf('annual_salary_other') > -1 && styles.error_input)} value={form_data.annual_salary_other} name='annual_salary_other' onChange={(e) => inputOnChange(e)} type='text' placeholder='Enter salary range' autoFocus />
                                </div>
                            }
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('mobile') > -1 && <span className={styles.requiredLabel}>*required</span>} Mobile</label>
                                <div className={styles.logoNumber}>
                                    <img src={flag_bd} alt='' />
                                    <p>+880</p>
                                </div>
                                <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('mobile') > -1 || phone_error !== '') && styles.error_input)} maxLength={10} value={form_data.mobile} name='mobile' onChange={inputOnChange} type='number' placeholder='Enter your Mobile number' />
                                {phone_error !== '' && <p className={styles.error_label}>{phone_error}</p>}
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('email') > -1 && <span className={styles.requiredLabel}>*required</span>} Email</label>
                                <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('email') > -1 || email_error !== '') && styles.error_input)} value={form_data.email} name='email' onChange={inputOnChange} type='text' placeholder='Enter your email' />
                                {email_error !== '' && <p className={styles.error_label}>{email_error}</p>}
                            </div>
                            {/* <div className={styles.inputGroup}>
                            <label className={styles.inputLabel}>
                                {(requiredFields.indexOf('doc_passport') > -1 || requiredFields.indexOf('doc_nid') > -1 || requiredFields.indexOf('doc_last_degree') > -1 || requiredFields.indexOf('doc_birth_certificate') > -1 || requiredFields.indexOf('doc_cv') > -1) &&
                                    <span className={styles.requiredLabel}>*required</span>
                                }
                                Documents
                            </label>
                            {(form_data.doc_passport.url !== '' || form_data.doc_nid.url !== '' || form_data.doc_last_degree.url !== '' || form_data.doc_birth_certificate.url !== '' || form_data.doc_cv.url !== '') &&
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', columnGap: '10px', margin: '6px 0 8px' }}>
                                    {form_data.doc_passport.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_passport.image} alt='' /></div>}
                                    {form_data.doc_nid.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_nid.image} alt='' /></div>}
                                    {form_data.doc_last_degree.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_last_degree.image} alt='' /></div>}
                                    {form_data.doc_birth_certificate.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_birth_certificate.image} alt='' /></div>}
                                    {form_data.doc_cv.image !== '' && <div className={styles.uploaded_doc}> <img src={pdf} alt='' /></div>}
                                </div>
                            }
                            {form_data.doc_passport.url !== '' && form_data.doc_nid.url !== '' && form_data.doc_last_degree.url !== '' && form_data.doc_birth_certificate.url !== '' && form_data.doc_cv.url !== '' ?
                                <label className={styles.input} onClick={() => setDoc_popup(true)} style={{ backgroundColor: '#012965', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                    <i style={{
                                        transform: 'rotate(45deg)',
                                        fontSize: '22px',
                                        marginTop: '-1px',
                                        color: '#fff'
                                    }}
                                        className="fa-thin fa-paperclip"
                                    ></i>

                                    <span style={{
                                        width: 'calc(100% - 25px)',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        color: '#fff'
                                    }}>
                                        5 documents uploaded
                                    </span>
                                </label>
                                :
                                <label className={
                                    classNames(styles.input, (requiredFields.indexOf('doc_passport') > -1 || requiredFields.indexOf('doc_nid') > -1 || requiredFields.indexOf('doc_last_degree') > -1 || requiredFields.indexOf('doc_birth_certificate') > -1 || requiredFields.indexOf('doc_cv') > -1) && styles.error_input)
                                }
                                    onClick={() => setDoc_popup(true)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                                    <i style={{
                                        transform: 'rotate(45deg)',
                                        fontSize: '22px',
                                        marginTop: '-1px',
                                    }}
                                        className="fa-thin fa-paperclip"
                                    ></i>

                                    <span style={{
                                        width: 'calc(100% - 25px)',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden'
                                    }}>
                                        Upload your documents
                                    </span>
                                </label>
                            }
                        </div> */}
                            {/* <div className={styles.inputGroupTerms}>
                            <div className={styles.termsGroup}>
                                <input type='checkbox' id='termsAccepted' onChange={(e) => inputOnChange(e)} name='terms' />
                                <label className={styles.input_label} htmlFor='termsAccepted' style={{ position: 'unset' }}> Accepting terms & conditions</label>
                            </div>
                        </div> */}
                            <div className={styles.submitBtn} style={{ marginTop: '10px' }}>
                                <button type='submit' onClick={onSubmitApplication} > CONTINUE </button>
                            </div>
                        </div>
                    }

                    {page_state === 3 &&
                        <div className={classNames(styles.formContainer, styles.info_section)}>
                            <div className={styles.info_container}>
                                <p className={styles.info_item}>FULL NAME : {form_data.full_name}</p>
                                <p className={styles.info_item}>UNIVERSITY : {form_data.university_name}</p>
                                <p className={styles.info_item}>COURSE TITLE : {form_data.course_title}</p>
                                <p className={styles.info_item}>IELTS SCORE : {form_data.ielts_score}</p>
                                <p className={styles.info_item}>CURRENT JOB : {form_data.current_job}</p>
                                <p className={styles.info_item}>ANNUAL SALARY : {form_data.annual_salary}</p>
                                <p className={styles.info_item}>MOBILE : {form_data.mobile}</p>
                                <p className={styles.info_item}>EMAIL : {form_data.email}</p>
                                <p className={styles.info_item}>DOCUMENTS : </p>

                                <div className={styles.documents_container}>
                                    {form_data.doc_passport.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_passport.image} alt='' /></div>}
                                    {form_data.doc_nid.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_nid.image} alt='' /></div>}
                                    {form_data.doc_last_degree.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_last_degree.image} alt='' /></div>}
                                    {form_data.doc_birth_certificate.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_birth_certificate.image} alt='' /></div>}
                                    {form_data.doc_cv.image !== '' && <div className={styles.uploaded_doc}> <img src={pdf} alt='' /></div>}
                                </div>
                            </div>
                            <div className={styles.inputGroupTerms}>
                                <div className={styles.termsGroup}>
                                    <input type='checkbox' id='termsAccepted' onChange={(e) => inputOnChange(e)} name='terms' />
                                    <label className={styles.input_label} htmlFor='termsAccepted' style={{ position: 'unset' }}> Accepting terms & conditions</label>
                                </div>
                            </div>
                            <div className={styles.submitBtn} style={{ marginTop: '10px' }}>
                                <button type='submit' style={terms ? {} : { opacity: '0.6', pointerEvents: 'none' }} onClick={onSubmitApplication} > SUBMIT </button>
                            </div>
                        </div>
                    }
                </div>
            </div>
            {page_state === 2 && <Documents setPage_state={setPage_state} form_data={form_data} setForm_data={setForm_data} />}
            {page_state === 4 && <OTPVerification type={'job'} email={form_data.email} setPage_state={setPage_state} reset_form={reset_form} application_id={application_id} />}
            {page_state === 5 && <Message setPage_state={setPage_state} />}
        </div>
    )
}

export default JobApplication;