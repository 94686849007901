/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import 'nouislider/dist/nouislider.css'; // Import the noUiSlider CSS
import Nouislider from 'react-nouislider';
import styles from '../../../assets/css/Filters.module.css'
import { filter_items, get_brand, get_supplier, gethomedata } from '../../../Utils/method';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { _domain } from '../../../Config/Domains';

const FilterOption = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const [sliderValue, setSliderValue] = useState([0, 1000]);
    const stores = useSelector((state) => state.rootReducer.stores);

    const debouncedApiCall = debounce((minValue, maxValue) => {
        console.log('Calling API with:', minValue, maxValue);
        setQueryParams({
            ...queryParams,
            min_price: minValue,
            max_price: maxValue
        })
    }, 1000); // Specify the debounce delay in milliseconds

    const handleSliderChange = (values) => {
        setFilter_state(true)
        setSliderValue(values);
        debouncedApiCall(Math.round(values[0]), Math.round(values[1]));
    }

    const [filter_state, setFilter_state] = useState(false);
    const [brands, setBrands] = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    const [queryParams, setQueryParams] = useState({
        store_id: stores[0]._id,
        min_price: '',
        max_price: '',
        low_to_high: false,
        high_to_low: false,
        brand_name: '',
        page: '',
        // limit: 20,
        is_most_popular: false,
        // name: '',
        delivery_time_static: '',
        // supplier_name: '',
        offer_message_or_percentage: ''
    });

    const update_Qparams = (v, type) => {
        setFilter_state(true)
        if (type === 'filter_by') {
            if (v === 'high_to_low') {
                setQueryParams({
                    ...queryParams,
                    [v]: true,
                    low_to_high: false,
                    is_most_popular: false,
                })
            } else if (v === 'low_to_high') {
                setQueryParams({
                    ...queryParams,
                    [v]: true,
                    high_to_low: false,
                    is_most_popular: false,
                })
            } else if (v === 'is_most_popular') {
                setQueryParams({
                    ...queryParams,
                    [v]: true,
                    low_to_high: false,
                    high_to_low: false,
                })
            } else {
                setQueryParams({
                    ...queryParams,
                    high_to_low: false,
                    low_to_high: false,
                    is_most_popular: false,
                })
            }
        } else {
            setQueryParams({ ...queryParams, [type]: v })
        }
    }

    function buildQueryString(params) {
        const queryItems = [];
        for (const key in params) {
            if (params.hasOwnProperty(key)) {
                if (params[key]) {
                    queryItems.push(`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`);
                }
            }
        }
        return queryItems.join('&');
    }

    const filter_function = async (params) => {
        try {
            let query = buildQueryString(params)
            // console.log('query:', '?' + query);
            let apires = await filter_items('?' + query);
            if (apires.status) {
                console.log('apires:', apires);
                dispatch({ type: 'add_filter_items', payload: apires });
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        console.log('queryParams:', queryParams);
        if (filter_state) {
            filter_function(queryParams)
        }
    }, [queryParams])

    const getSuppliers = async () => {
        try {
            let apires = await get_supplier(`?perPage=${2000}`);
            if (apires.suppliers.length > 0) {
                let data = []
                let sort_v = apires.suppliers.sort((a, b) => a.name.localeCompare(b.name));
                for (const o of sort_v) {
                    data.push({ value: o._id, label: o.name })
                }
                setSuppliers(data)
                // setSuppliers([...data])
                // setSuppliers_all(sort_v)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_all_brands = async () => {
        try {
            let apires = await get_brand(`?perPage=${2000}`)
            if (apires.brands.length > 0) {
                let data = []
                let sort_v = apires.brands.sort((a, b) => a.name.localeCompare(b.name));
                for (const o of sort_v) {
                    data.push({ value: o._id, label: o.name })
                }
                setBrands(data)
                // setBrands([...data])
                // setBrands_all(sort_v);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const get_all_data = async () => {
        try {
            // let domain_id = JSON.parse(localStorage.getItem('current_domain'))._id
            let data = await gethomedata({
                store_delivery_id: localStorage.getItem('delivery_id') || '',
                store_id: '6342a0361d2151beeb670d32',
                domain_id: _domain().id,
            });
            console.log('gethomedata FilterOption', data);
            dispatch({ type: 'set_home_data', payload: data });
        } catch (error) {
            console.log(error);
        }
    }

    const resetFilter = () => {
        let rd = {
            store_id: stores[0]._id,
            min_price: '',
            max_price: '',
            low_to_high: false,
            high_to_low: false,
            brand_name: '',
            page: '',
            is_most_popular: false,
            delivery_time_static: '',
            offer_message_or_percentage: ''
        }
        setQueryParams(rd)
        setFilter_state(false)
        dispatch({ type: 'set_mobile_filter', payload: false })
        get_all_data()
        // filter_function(rd)
    }

    useEffect(() => {
        get_all_brands()
        getSuppliers()
    }, [])

    return (
        <section className={styles.filter_section}>
            <div className={styles.filter_inner_section}>
                {isMobile &&
                    <div className={styles.filter_header}>
                        <h3>Filter </h3>
                        <span className={styles.closeCart} onClick={() => dispatch({ type: 'set_mobile_filter', payload: false })}>
                            <i className="fa-thin fa-xmark"></i>
                        </span>
                    </div>
                }
                {/* <div className={styles.filter_item}>
                    <select className={styles.select_box} onChange={(e) => update_Qparams(e.target.value, 'limit')}>
                        <option>Selct Limit</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                    </select>
                </div> */}
                {/* <div className={styles.filter_item}>
                    <select className={styles.select_box} onChange={(e) => update_Qparams(e.target.value, 'supplier_name')}>
                        <option>Selct Supplier</option>
                        {suppliers.map((v, i) =>
                            <option key={'supplier_' + i} value={v.label}>{v.label}</option>
                        )}
                    </select>
                </div> */}
                <div className={styles.filter_item}>
                    <select className={styles.select_box} value={queryParams.brand_name} onChange={(e) => update_Qparams(e.target.value, 'brand_name')}>
                        <option value={''}>BRAND</option>
                        {brands.map((v, i) =>
                            <option key={'brand_' + i} value={v.label}>{v.label}</option>
                        )}
                    </select>
                </div>
                <div className={styles.filter_item}>
                    <select className={styles.select_box} value={queryParams.delivery_time_static} onChange={(e) => update_Qparams(e.target.value, 'delivery_time_static')}>
                        <option value={''}>DELIVERY</option>
                        <option value={'Same Day'}>Same Day</option>
                        <option value={'2 To 3 Days'}>2 To 3 Days</option>
                        <option value={'2 To 10 Days'}>2 To 10 Days</option>
                        <option value={'7 To 10 Days'}>7 To 10 Days</option>
                        <option value={'15 days. Subject to BD Customs'}>15 days. Subject to BD Customs</option>
                    </select>
                </div>
                <div className={styles.filter_item}>
                    <select className={styles.select_box}
                        value={queryParams.high_to_low ? 'high_to_low' : queryParams.low_to_high ? 'low_to_high' : queryParams.is_most_popular ? 'is_most_popular' : ''}
                        onChange={(e) => update_Qparams(e.target.value, 'filter_by')}>
                        <option value={''} disabled>SORT BY </option>
                        <option value={'high_to_low'}>Highest Price</option>
                        <option value={'low_to_high'}>Lowest Price</option>
                        <option value={'is_most_popular'}>Most Popular</option>
                    </select>
                </div>
                <div className={styles.range_filter_item}>
                    <div className={styles.range_input}>
                        <div className={styles.price_range_box}>£ {Math.round(sliderValue[0])}</div>
                        <div className={styles.price_range_box}>£ {Math.round(sliderValue[1])}</div>
                    </div>
                    <div className={styles.range_slider}>
                        <Nouislider
                            range={{ min: 0, max: 2000 }}
                            start={[sliderValue[0], sliderValue[1]]}
                            behaviour={'drag'}
                            connect={true}
                            onSlide={handleSliderChange}
                        />
                    </div>
                </div>
                {filter_state && <div className={styles.resetButton} onClick={resetFilter}>RESET</div>}
            </div>
        </section>
    )
}

export default FilterOption;