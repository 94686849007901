import React, { useState, useEffect } from 'react'
import styles from '../../../assets/css/Checkout.module.css';
import ukbd from '../../../assets/UKBD.png'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

function PaymentSuccess() {
    const { parameter } = useParams();
    const location = useLocation()
    console.log('parameter:', parameter);
    const navigate = useNavigate()
    const [tabState, setTabState] = useState(1);

    useEffect(() => {
        if (!location.state) {
            navigate('/')
        }
    }, [])

    return (
        <div className={styles.checkout_Popup}>
            <div className={styles.carting_head}>
                {/* <div className={styles.pageTitleSection}>
                    <Link className={styles.pageLogoHolder} to={'https://ukbd.app'}>
                        <img className={styles.pageLogo} src={ukbd} alt="" />
                    </Link>
                    <div className={styles.pageTitle} onClick={() => navigate('/')}>
                        <h2>UK & EU Brands</h2>
                    </div>
                </div> */}
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.paymentConatiner}>
                {location.state &&
                    <div className={styles.paymentSuccess}>
                        <p className={styles.successText_1}>THANK YOU</p>
                        <p className={styles.successText_2}>Your order is successful.</p>
                        <div className={styles.cartTabContainer}>
                            <div className={styles.tabContainer}>
                                <div className={styles.tabHead}>
                                    <div className={classNames(styles.tabs, tabState === 1 ? styles.active : '')} onClick={() => setTabState(1)}>UKBD</div>
                                    <div className={classNames(styles.tabs, tabState === 2 ? styles.active : '')} onClick={() => setTabState(2)}>Delivery</div>
                                    <div className={classNames(styles.tabs, tabState === 3 ? styles.active : '')} onClick={() => setTabState(3)}>Returns</div>
                                </div>
                                <div className={styles.tabBody}>
                                    {tabState === 1 ?
                                        <div className={styles.ukbdTab}><p>Connects Bangladeshis on both sides, London & Dhaka</p></div>
                                        : tabState === 2 ?
                                            <div className={styles.ukbdTab}><p>15 days subject to custom clearance in Bangladesh</p></div>
                                            :
                                            <div className={styles.ukbdTab}><p> Unfortunately, for orders shipped outside the UK, we're unable to refund any taxes, duties, charges or delivery costs you may have paid when placing your order. You will be refunded the tax-free price or the products returned. </p></div>
                                    }
                                </div>
                            </div>
                        </div>
                        <p className={styles.successText_3}>Thank you very much for shopping with us. <br /> SEE YOU SOON!</p>
                    </div>
                }
            </div>
            <div className={styles.carting_footer}>
                <button className={styles.proceed_to_chec} onClick={() => navigate('/')}>Continue to Shopping</button>
            </div>
        </div>
    )
}

export default PaymentSuccess