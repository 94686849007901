import React from 'react';
import styles from '../../../assets/css/FloatOption.module.css';
import { useSelector} from "react-redux";
import classNames from 'classnames';
function FloatOption() {
    const website_setting = useSelector((state) => state.rootReducer.website_setting);
    const floatOptions = useSelector((state) => state.rootReducer.floatOptions);
    const whatsappUrl = `https://api.whatsapp.com/send?phone=${'+44 7305 240807'}&&text=${'Hi'}&&type=${'phone_number'}&&app_absent='${0}'`;
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const handleClick = () => {
        if (isMobile) {
            window.location.href = whatsappUrl;
        } else {
            window.open(whatsappUrl, '_blank');
        }
    };

    // console.log(10, website_setting)
    return (
        <div className={classNames(styles.floatOptions, !floatOptions ? styles.floatOptionsHide:'')} id={'floatOptions'}>
            <div className={styles.floatItem} title="Google Play" onClick={()=>window.open(website_setting.android_app_link)}><i className="fa-brands fa-google-play"></i></div>
            <div className={styles.floatItem} style={{fontSize:'18px'}} title="App Store" onClick={()=>window.open(website_setting.ios_app_link)}><i className="fa-brands fa-apple"></i></div>
            {/* <div className={styles.floatItem} title="App Store" onClick={()=>window.open(website_setting.youtube_link)}><i className="fa-brands fa-app-store"></i></div> */}
            <div className={styles.floatItem} title="WhatsApp" onClick={handleClick}><i className="fa-brands fa-whatsapp"></i></div>
            {/* <div className={styles.floatI title=""tem} ><i className="fa-solid fa-message-lines"></i></div> */}
            <div className={styles.floatItem} title="Youtube" onClick={()=>window.open(website_setting.youtube_link)}><i className="fa-brands fa-youtube"></i></div>
            <div className={styles.floatItem} title="Facebook" onClick={()=>window.open(website_setting.facebook_link)}><i className="fa-brands fa-facebook"></i></div>
            <div className={styles.floatItem} title="Twitter" onClick={()=>window.open(website_setting.twitter_link)}><i className="fa-brands fa-twitter"></i></div>
            <div className={styles.floatItem} title="Linkdin" onClick={()=>window.open(website_setting.linked_in_link)}><i className="fa-brands fa-linkedin-in"></i></div>
            <div className={styles.floatItem} title="Instagram" onClick={()=>window.open(website_setting.instagram_link)}><i className="fa-brands fa-instagram"></i></div>
        </div>
    )
}

export default FloatOption;