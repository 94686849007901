import React, { useState } from 'react';
import styles from '../../assets/css/Study.module.css';
import flag_bd from '../../assets/flag-BD.png';
import students from '../../assets/Students.png';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
import { add_new_applicaiton } from '../../Utils/method'
import classNames from 'classnames';
import OTPVerification from '../Migration/OTPVerification';
import Message from '../Migration/Message';
import { useEffect } from 'react';
import Admission from './Admission';


const DegreeOptions = [
    { value: 'undergraduate', label: 'Undergraduate' },
    { value: 'postgraduateTaught', label: 'Postgraduate Taught' },
    { value: 'postgraduateResearch', label: 'Postgraduate Research' },
]
const sessionOptions = [
    { value: 'September 2023', label: 'September 2023' },
    { value: 'December 2023', label: 'December 2023' },
    { value: 'January 2024', label: 'January 2024' },
    { value: 'July 2024', label: 'July 2024' },
    { value: 'September 2024', label: 'September 2024' },
    { value: 'December 2024', label: 'December 2024' },
]
const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',
        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px'
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 6px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'

    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
    })
    ,
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    }),
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: '#ced4da',
            borderRadius: '0px',
            fontWeight: '200',
            fontFamily: 'Poppins',
            height: '18px',
            lineHeight: '13px',
            fontSize: '14px'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
}
const sessionStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',

        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px',
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 6px',
        width: '100%',
        color: 'rgba(0,48,25,.1)',
    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
        overflow: 'scroll',
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    })
}

function Study() {
    const [loader, setLoader] = useState(false)
    const [success, setSuccess] = useState(false)
    const [verification, setVerification] = useState(false)
    const [terms, setTerms] = useState(false);
    const [buttonState, setButtonState] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const universityOptions = [
        { value: 'University of Aberdeen', label: 'University of Aberdeen' },
        { value: 'Abertay University', label: 'Abertay University' },
        { value: 'Aberystwyth University', label: 'Aberystwyth University' },
        { value: 'Anglia Ruskin University', label: 'Anglia Ruskin University' },
        { value: 'Anglo-European College of Chiropractic', label: 'Anglo-European College of Chiropractic' },
        { value: 'Arden University', label: 'Arden University' },
        { value: 'Ashridge Business School', label: 'Ashridge Business School' },
        { value: 'Aston University', label: 'Aston University' },
        { value: 'Bangor University', label: 'Bangor University' },
        { value: 'University of Bath', label: 'University of Bath' },
        { value: 'Bath Spa University', label: 'Bath Spa University' },
        { value: 'University of Bedfordshire', label: 'University of Bedfordshire' },
        { value: 'Birkbeck, University of London', label: 'Birkbeck, University of London' },
        { value: 'University of Birmingham', label: 'University of Birmingham' },
        { value: 'Birmingham City University', label: 'Birmingham City University' },
        { value: 'University of Bolton', label: 'University of Bolton' },
        { value: 'Arts University Bournemouth', label: 'Arts University Bournemouth' },
        { value: 'Bournemouth University', label: 'Bournemouth University' },
        { value: 'BPP University', label: 'BPP University' },
        { value: 'University of Bradford', label: 'University of Bradford' },
        { value: 'University of Brighton', label: 'University of Brighton' },
        { value: 'University of Bristol', label: 'University of Bristol' },
        { value: 'Brunel University London', label: 'Brunel University London' },
        { value: 'University of Buckingham', label: 'University of Buckingham' },
        { value: 'Buckinghamshire New University', label: 'Buckinghamshire New University' },
        { value: 'University of Cambridge', label: 'University of Cambridge' },
        { value: 'Canterbury Christ Church University', label: 'Canterbury Christ Church University' },
        { value: 'Cardiff Metropolitan University', label: 'Cardiff Metropolitan University' },
        { value: 'Cardiff University', label: 'Cardiff University' },
        { value: 'University of Chester', label: 'University of Chester' },
        { value: 'University of Chichester', label: 'University of Chichester' },
        { value: 'City University London', label: 'City University London' },
        { value: 'Courtauld Institute of Art, University of London', label: 'Courtauld Institute of Art, University of London' },
        { value: 'Coventry University', label: 'Coventry University' },
        { value: 'Cranfield University', label: 'Cranfield University' },
        { value: 'University for the Creative Arts', label: 'University for the Creative Arts' },
        { value: 'University of Cumbria', label: 'University of Cumbria' },
        { value: 'De Montfort University', label: 'De Montfort University' },
        { value: 'University of Derby', label: 'University of Derby' },
        { value: 'University of Dundee', label: 'University of Dundee' },
        { value: 'Durham University', label: 'Durham University' },
        { value: 'University of East Anglia', label: 'University of East Anglia' },
        { value: 'University of East London', label: 'University of East London' },
        { value: 'Edge Hill University', label: 'Edge Hill University' },
        { value: 'University of Edinburgh', label: 'University of Edinburgh' },
        { value: 'Edinburgh Napier University', label: 'Edinburgh Napier University' },
        { value: 'University of Essex', label: 'University of Essex' },
        { value: 'University of Exeter', label: 'University of Exeter' },
        { value: 'Falmouth University', label: 'Falmouth University' },
        { value: 'Glasgow Caledonian University', label: 'Glasgow Caledonian University' },
        { value: 'University of Gloucestershire', label: 'University of Gloucestershire' },
        { value: 'Glyndŵr University', label: 'Glyndŵr University' },
        { value: 'Goldsmiths, University of London', label: 'Goldsmiths, University of London' },
        { value: 'University of Greenwich', label: 'University of Greenwich' },
        { value: 'Guildhall School of Music and Drama', label: 'Guildhall School of Music and Drama' },
        { value: 'Harper Adams University', label: 'Harper Adams University' },
        { value: 'Hartpury College', label: 'Hartpury College' },
        { value: 'Heriot-Watt University', label: 'Heriot-Watt University' },
        { value: 'University of Hertfordshire', label: 'University of Hertfordshire' },
        { value: 'Heythrop College, University of London', label: 'Heythrop College, University of London' },
        { value: 'University of the Highlands and Islands', label: 'University of the Highlands and Islands' },
        { value: 'University of Huddersfield', label: 'University of Huddersfield' },
        { value: 'University of Hull', label: 'University of Hull' },
        { value: 'Imperial College of London', label: 'Imperial College of London' },
        { value: 'Institute of Cancer Research, University of London', label: 'Institute of Cancer Research, University of London' },
        { value: 'Institute of Education, University of London', label: 'Institute of Education, University of London' },
        { value: 'Keele University', label: 'Keele University' },
        { value: 'University of Kent', label: 'University of Kent' },
        { value: 'King’s College London', label: 'King’s College London' },
        { value: 'Kingston University', label: 'Kingston University' },
        { value: 'University of Central Lancashire', label: 'University of Central Lancashire' },
        { value: 'Lancaster University', label: 'Lancaster University' },
        { value: 'University of Leeds', label: 'University of Leeds' },
        { value: 'Leeds Beckett University', label: 'Leeds Beckett University' },
        { value: 'Leeds College of Art', label: 'Leeds College of Art' },
        { value: 'Leeds Trinity University', label: 'Leeds Trinity University' },
        { value: 'University of Leicester', label: 'University of Leicester' },
        { value: 'University of Lincoln', label: 'University of Lincoln' },
        { value: 'University of Liverpool', label: 'University of Liverpool' },
        { value: 'Liverpool Hope University', label: 'Liverpool Hope University' },
        { value: 'Liverpool John Moores University', label: 'Liverpool John Moores University' },
        { value: 'Liverpool School of Tropical Medicine', label: 'Liverpool School of Tropical Medicine' },
        { value: 'University of London', label: 'University of London' },
        { value: 'London Business School', label: 'London Business School' },
        { value: 'London Institute of Banking and Finance', label: 'London Institute of Banking and Finance' },
        { value: 'London Metropolitan University', label: 'London Metropolitan University' },
        { value: 'London School of Hygiene and Tropical Medicine', label: 'London School of Hygiene and Tropical Medicine' },
        { value: 'London School of Economics and Political Science', label: 'London School of Economics and Political Science' },
        { value: 'London South Bank University', label: 'London South Bank University' },
        { value: 'University College London', label: 'University College London' },
        { value: 'Loughborough University', label: 'Loughborough University' },
        { value: 'University of Manchester', label: 'University of Manchester' },
        { value: 'Manchester Metropolitan University', label: 'Manchester Metropolitan University' },
        { value: 'Middlesex University', label: 'Middlesex University' },
        { value: 'Newcastle University', label: 'Newcastle University' },
        { value: 'Newman University, Birmingham', label: 'Newman University, Birmingham' },
        { value: 'University of Northampton', label: 'University of Northampton' },
        { value: 'Northumbria University Newcastle', label: 'Northumbria University Newcastle' },
        { value: 'Norwich University of the Arts', label: 'Norwich University of the Arts' },
        { value: 'University of Nottingham', label: 'University of Nottingham' },
        { value: 'Nottingham Trent University', label: 'Nottingham Trent University' },
        { value: 'Open University', label: 'Open University' },
        { value: 'University of Oxford', label: 'University of Oxford' },
        { value: 'Oxford Brookes University', label: 'Oxford Brookes University' },
        { value: 'Plymouth University', label: 'Plymouth University' },
        { value: 'Portsmouth University', label: 'Portsmouth University' },
        { value: 'Queen Margaret University, Edinburgh', label: 'Queen Margaret University, Edinburgh' },
        { value: 'Queen Mary, University of London', label: 'Queen Mary, University of London' },
        { value: 'Queen’s University Belfast', label: 'Queen’s University Belfast' },
        { value: 'University of Reading', label: 'University of Reading' },
        { value: 'Regent’s University London', label: 'Regent’s University London' },
        { value: 'Robert Gordon University, Aberdeen', label: 'Robert Gordon University, Aberdeen' },
        { value: 'University of Roehampton', label: 'University of Roehampton' },
        { value: 'Rose Bruford College of Theatre and Performance', label: 'Rose Bruford College of Theatre and Performance' },
        { value: 'Royal Academy of Music', label: 'Royal Academy of Music' },
        { value: 'Royal Agricultural University', label: 'Royal Agricultural University' },
        { value: 'University of London', label: 'University of London' },
        { value: 'Royal College of Art', label: 'Royal College of Art' },
        { value: 'Royal College of Music', label: 'Royal College of Music' },
        { value: 'Royal College of Nursing', label: 'Royal College of Nursing' },
        { value: 'Royal Conservatoire of Scotland', label: 'Royal Conservatoire of Scotland' },
        { value: 'Royal Northern College of Music', label: 'Royal Northern College of Music' },
        { value: 'Royal Veterinary College', label: 'Royal Veterinary College' },
        { value: 'University of Salford', label: 'University of Salford' },
        { value: 'University of Sheffield', label: 'University of Sheffield' },
        { value: 'Sheffield Hallam University', label: 'Sheffield Hallam University' },
        { value: 'University of South Wales', label: 'University of South Wales' },
        { value: 'University of Southampton', label: 'University of Southampton' },
        { value: 'Solent University', label: 'Solent University' },
        { value: 'University of St Andrews', label: 'University of St Andrews' },
        { value: 'University of St Mark and St John, Plymouth', label: 'University of St Mark and St John, Plymouth' },
        { value: 'St Mary’s University, Twickenham', label: 'St Mary’s University, Twickenham' },
        { value: 'Staffordshire University', label: 'Staffordshire University' },
        { value: 'University of Stirling', label: 'University of Stirling' },
        { value: 'University of Strathclyde', label: 'University of Strathclyde' },
        { value: 'University of Suffolk', label: 'University of Suffolk' },
        { value: 'University of Sunderland', label: 'University of Sunderland' },
        { value: 'University of Surrey', label: 'University of Surrey' },
        { value: 'University of Sussex', label: 'University of Sussex' },
        { value: 'Swansea University', label: 'Swansea University' },
        { value: 'Teesside University', label: 'Teesside University' },
        { value: 'Trinity Laban Conservatoire of Music and Dance', label: 'Trinity Laban Conservatoire of Music and Dance' },
        { value: 'University of the Arts, London', label: 'University of the Arts, London' },
        { value: 'University College of Estate Management', label: 'University College of Estate Management' },
        { value: 'University College of Osteopathy', label: 'University College of Osteopathy' },
        { value: 'University of Law', label: 'University of Law' },
        { value: 'University of Ulster', label: 'University of Ulster' },
        { value: 'University of Wales', label: 'University of Wales' },
        { value: 'University of Wales Trinity Saint David', label: 'University of Wales Trinity Saint David' },
        { value: 'University of Warwick', label: 'University of Warwick' },
        { value: 'University of the West of England, Bristol', label: 'University of the West of England, Bristol' },
        { value: 'University of West London', label: 'University of West London' },
        { value: 'University of the West of Scotland', label: 'University of the West of Scotland' },
        { value: 'University of Westminster', label: 'University of Westminster' },
        { value: 'University of Winchester', label: 'University of Winchester' },
        { value: 'University of Wolverhampton', label: 'University of Wolverhampton' },
        { value: 'University of Worcester', label: 'University of Worcester' },
        { value: 'Writtel University College', label: 'Writtel University College' },
        { value: 'University of York', label: 'University of York' },
        { value: 'York St John University', label: 'York St John University' },
    ]

    const [page_state, setPage_state] = useState(1);

    const [form_data, setForm_data] = useState({
        fullName: '',
        university_name: '',
        campus: '',
        degree: '',
        course_title: '',
        arrival_date: '',
        start_session: '',
        mobile: '',
        email: '',
        applicaiton_for: '',
    });

    const validateEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    };

    // useEffect(() => {
    //     console.log(form_data);
    //     let state = false

    //     if (form_data.applicaiton_for === '') {
    //         state = false
    //     } else if (form_data.fullName === '') {
    //         state = false
    //     } else if (form_data.campus === '') {
    //         state = false
    //     } else if (form_data.degree === '') {
    //         state = false
    //     } else if (form_data.university_name === '') {
    //         state = false
    //     } else if (form_data.course_title === '') {
    //         state = false
    //     } else if (form_data.arrival_date === '') {
    //         state = false
    //     } else if (form_data.start_session === '') {
    //         state = false
    //     } else if (form_data.mobile === '') {
    //         state = false
    //     } else if (form_data.email === '' || !validateEmail(form_data.email)) {
    //         state = false
    //     } else {
    //         state = true
    //     }
    //     setButtonState(state)
    // }, [form_data])

    const handleSelect = (data, type) => {
        setForm_data({
            ...form_data,
            [type]: data ? data.value : '',
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'mobile') {
            if (value !== '0') {
                if (value.length <= 10 && /^\d*$/.test(value)) {
                    setForm_data({
                        ...form_data,
                        [name]: value,
                    });
                }
            }
        } else {
            setForm_data({
                ...form_data,
                [name]: value,
            });
        }
    };

    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        setTerms(checked)
    };

    const reset_form = () => {
        setForm_data({
            fullName: '',
            university_name: '',
            campus: '',
            degree: '',
            course_title: '',
            arrival_date: '',
            start_session: '',
            mobile: '',
            email: '',
            applicaiton_for: '',
        })
    }

    const [application_id, setApplication_id] = useState('');
    const [requiredFields, setRequiredFields] = useState([]);
    const [email_error, setEmail_error] = useState('');
    const [phone_error, setPhone_error] = useState('');

    function findEmptyKeys(obj) {
        const emptyKeys = [];

        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                console.log('Value:', value);
                if (value.trim() === '') {
                    emptyKeys.push(key);
                    if (key === 'email') {
                        setEmail_error('')
                    } else if (key === 'mobile') {
                        setPhone_error('')
                    }
                } else {
                    if (key === 'email' && !validateEmail(value)) {
                        setEmail_error('Enter valid email')
                        emptyKeys.push(key);
                    } else if (key === 'mobile' && value.length < 10) {
                        setPhone_error('Enter valid mobile')
                        emptyKeys.push(key);
                    } else {
                        setEmail_error('')
                        setPhone_error('')
                    }
                }
            }
        }
        setRequiredFields(emptyKeys)
        return emptyKeys;
    }

    useEffect(() => {
        console.log(440, email_error, phone_error);
    }, [email_error, phone_error])

    const submitForm = async () => {
        setLoader(true)
        try {
            const emptyKeys = findEmptyKeys(form_data);
            console.log(304, emptyKeys);

            if (emptyKeys.length > 0) {
                return false;
            }

            let full = form_data.fullName.split(' ')
            let first_name = full[0]
            let last_name = full.slice(1)

            let json = {
                applicaiton_for: 'Study_In_The_Uk',
                first_name: first_name,
                last_name: last_name.toString(),
                campus: form_data.campus,
                degree: form_data.degree,
                arrival_date: form_data.arrival_date,
                start_session: form_data.start_session,
                university_name: form_data.university_name,
                course_title: form_data.course_title,
                mobile: '+880' + form_data.mobile,
                email: form_data.email,
            }

            setVerification(true);
            let apiRes = await add_new_applicaiton(json);
            if (apiRes.success) {
                console.log(449, apiRes);
                setApplication_id(apiRes.data.application_id)
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error(415, 'Error submitting form:');
        }
    }

    const gotoTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }


    return (
        <div className={styles.mainContainer}>
            <div className={styles.leftContainer}>
                <div className={styles.containerInner}>
                    <div className={styles.logoText}>
                        <div className={styles.ukbdtext}>
                            <p className={styles.title}> Study in the UK</p>
                        </div>
                    </div>

                    <div className={styles.topText}>
                        <p className={styles.topinnertext}>
                            We empower Bangladeshi students to make
                            well-informed decisions that ensure successful admission to prestigious UK universities.
                        </p>
                    </div>
                    <div className={styles.imageBox}>
                        <img src={students} className={styles.flag} alt="flag-UK" />
                    </div>
                    <div className={styles.bottomText}>
                        <p className={styles.bottominnertext}>Finding suitable <strong>accommodation</strong> in the UK can pose a significant challenge for international students. We specialise in helping Bangladeshi students discover <strong>cost-effective, secure</strong>, & <strong>strategically located</strong> housing options for their academic journey.</p>
                    </div>
                </div>
            </div>
            <div className={styles.rightContainer} id='studyInUkForm'>
                <div className={styles.tag_container}>
                    <span className={classNames(styles.headBtns, page_state === 1 && styles.active)} onClick={() => setPage_state(1)}>Accommodation</span>
                    <span className={classNames(styles.headBtns, page_state === 2 && styles.active)} onClick={() => setPage_state(2)}>Admission</span>
                </div>
                {page_state === 1 &&
                    <div className={styles.rightContainerInner}>
                        <div className={styles.rightTopText}>
                            <p className={styles.rightTopinnertext}>find your ideal home & job in the UK</p>
                            <p className={styles.rightTopinnertext2}>Let's start NOW</p>
                        </div>
                        <div className={styles.formContainer}>
                            <div className={styles.inputGroup}>
                                <label label className={styles.inputLabel}> {requiredFields.indexOf('fullName') > -1 && <span className={styles.requiredLabel}>*required</span>} Full Name</label>
                                <input className={classNames(styles.input, styles.inputFull, requiredFields.indexOf('fullName') > -1 && styles.error_input)} value={form_data.fullName} name='fullName' onChange={handleInputChange} type='text' placeholder='Enter your full name' autoFocus />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('university_name') > -1 && <span className={styles.requiredLabel}>*required</span>} University</label>
                                <CreatableSelect className={classNames(styles.dropDown, requiredFields.indexOf('university_name') > -1 && styles.error_input)}
                                    onChange={(e) => handleSelect(e, 'university_name')}
                                    isSearchable
                                    isClearable
                                    name='university_name'
                                    placeholder='Select University'
                                    options={universityOptions}
                                    styles={selectStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#0030192d',
                                            primary: 'rgba(0, 48, 25, .75)',
                                        },
                                    })}
                                    classNames={styles.customSelect}
                                    classNamePrefix={styles.customSelect}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('campus') > -1 && <span className={styles.requiredLabel}>*required</span>} Campus</label>
                                <input className={classNames(styles.input, requiredFields.indexOf('campus') > -1 && styles.error_input)} value={form_data.campus} name='campus' onChange={handleInputChange} type='text' placeholder='Enter campus name' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('degree') > -1 && <span className={styles.requiredLabel}>*required</span>} Degree</label>
                                <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('degree') > -1 && styles.error_input)}
                                    defaultValue={''}
                                    isSearchable={true}
                                    placeholder='Select Degree'
                                    name='degree'
                                    onChange={(e) => handleSelect(e, 'degree')}
                                    options={DegreeOptions}
                                    styles={selectStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#0030192d',
                                            primary: 'rgba(0, 48, 25, .75)',
                                        },
                                    })}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('course_title') > -1 && <span className={styles.requiredLabel}>*required</span>} Course Title</label>
                                <input className={classNames(styles.input, requiredFields.indexOf('course_title') > -1 && styles.error_input)} value={form_data.course_title} name='course_title' onChange={handleInputChange} type='text' placeholder='Your Course Title' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('arrival_date') > -1 && <span className={styles.requiredLabel}>*required</span>} Arrival Date</label>
                                <input className={classNames(styles.input, requiredFields.indexOf('arrival_date') > -1 && styles.error_input)} value={form_data.arrival_date} name='arrival_date' onChange={handleInputChange} type='date' placeholder='Arrival date' />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('arrival_date') > -1 && <span className={styles.requiredLabel}>*required</span>} Start Session</label>
                                <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('arrival_date') > -1 && styles.error_input)}
                                    defaultValue={''}
                                    isSearchable={true}
                                    placeholder='Select Session'
                                    name='start_session'
                                    onChange={(e) => handleSelect(e, 'start_session')}
                                    options={sessionOptions}
                                    styles={sessionStyles}
                                    theme={(theme) => ({
                                        ...theme,
                                        borderRadius: 0,
                                        colors: {
                                            ...theme.colors,
                                            primary25: '#0030192d',
                                            primary: 'rgba(0, 48, 25, .75)',
                                        },
                                    })}
                                />
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('mobile') > -1 && <span className={styles.requiredLabel}>*required</span>} Mobile</label>
                                <div className={styles.logoNumber}>
                                    <img src={flag_bd} alt='' />
                                    <p>+880</p>
                                </div>
                                <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('mobile') > -1 || phone_error !== '') && styles.error_input)} maxLength={10} value={form_data.mobile} name='mobile' onChange={handleInputChange} type='number' placeholder='Enter your Mobile number' />
                                {phone_error !== '' && <p className={styles.error_label}>{phone_error}</p>}
                            </div>
                            <div className={styles.inputGroup}>
                                <label className={styles.inputLabel}> {requiredFields.indexOf('email') > -1 && <span className={styles.requiredLabel}>*required</span>} Email</label>
                                <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('email') > -1 || email_error !== '') && styles.error_input)} value={form_data.email} name='email' onChange={handleInputChange} type='text' placeholder='Enter your email' />
                                {email_error !== '' && <p className={styles.error_label}>{email_error}</p>}
                            </div>
                            <div className={styles.inputGroupTerms}>
                                <div className={styles.termsGroup}>
                                    <input type='checkbox' id='termsAccepted' onChange={handleCheckboxChange} name='termsAccepted' />
                                    <label className={styles.input_label} htmlFor='termsAccepted' style={{ position: 'unset' }}> Accepting terms & conditions</label>
                                </div>
                            </div>
                            <div className={styles.submitBtn}>
                                <button type='submit' onClick={submitForm} style={(terms) ? {} : { pointerEvents: 'none', opacity: '0.6' }}> SUBMIT </button>
                            </div>
                        </div>
                        <div className={styles.rightBottomText}>
                            <p className={styles.rightBottominnertext}>
                                Our dedicated support team will contact you to ensure that you can focus on your studies with peace of mind, knowing you have a comfortable & conducive living environment, along with a part-time job & pocket money.
                            </p>
                        </div>
                    </div>
                }

                {page_state === 2 && <Admission universityOptions={universityOptions} />}
            </div>
            <div className={styles.line1_and_line2_container}>
                <div className={styles.line1_vertical}></div>
                <div className={styles.line2_vertical}></div>
                <div className={styles.line_cursor_box} onClick={() => gotoTop('studyInUkForm')}></div>
            </div>
            {verification && <OTPVerification type={'study'} email={form_data.email} setSuccess={setSuccess} setVerification={setVerification} reset_form={reset_form} application_id={application_id} />}
            {success && <Message setSuccess={setSuccess} setAssessmentForm={null} />}
        </div>
    );

}

export default Study;