import axios from "axios";
import {appStatic} from "../Config/Config";
// const googlePOSTmethod =(API)=>{
//     return new Promise((resolve,reject)=>{
//         axios.post(API+appStatic.GOOGLE_KEY)
//         .then(function (response) {
//             resolve(response);
//         })
//         .catch(function (error) {
//             reject(error);
//         })
//     })
// }
const googleGETmethod =(API)=>{
    return new Promise((resolve,reject)=>{
        axios.get(API+appStatic.GOOGLE_KEY+'&libraries=places')
        .then(function (response) {
            resolve(response.data);
        })
        .catch(function (error) {
            reject(error);
        })
    })
}

//https://maps.googleapis.com/maps/api/geocode/json?latlng=40.714224,-73.961452&key=YOUR_API_KEY

export {
    googleGETmethod
}