/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { _domain } from "../../../Config/Domains";
import { getPopularItems, get_item_from_product } from "../../../Utils/method";
import { useParams } from "react-router-dom";
import PopularItem from "../Popular/PopularItem";
import Item from "../Items/Item";
import { isMobile } from "react-device-detect";
import Loader from "../../Loader";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../Breadcrumb";
import SiteMap from "../Items/SiteMap";
import FooterSlider from "../Items/FooterSlider";

const Products = () => {
    const params = useParams();
    const dispatch = useDispatch();

    const [loader, setLoader] = useState(true);
    const [items, setItems] = useState([]);
    const right_side_loader = useSelector((state) => state.rootReducer.right_side_loader);
    // const paginate_data = useSelector((state) => state.rootReducer.paginate_data);
    const stores = useSelector((state) => state.rootReducer.stores);
    const active_product = useSelector((state) => state.rootReducer.active_product);
    // const items = useSelector((state) => state.rootReducer.items);
    const [totalPage, setTotalPage] = useState(1);

    const [paginate_data, setPaginate_data] = useState({
        limit: 48,
        totalItems: 0,
        currentPage: 0,
        itemsPerPage: 0
    });

    useEffect(() => {
        let page = Math.ceil(paginate_data.totalItems / paginate_data.itemsPerPage);
        setTotalPage(page)
        // console.log({ paginate_data: paginate_data, totalPage: page });
    }, [paginate_data])

    async function fetchMoreData(page) {
        try {
            setLoader(true);
            // if (active_product === 'popular') {
            //     let data = await getPopularItems({
            //         store_id: stores[0]._id,
            //         page: page,
            //         limit: paginate_data.limit,
            //         domain_id: _domain().id
            //     })
            //     console.log('getPopularItems:', data);
            //     // dispatch({ type: 'add_items', payload: data });
            //     setPaginate_data({ ...paginate_data, ...data.paginate_data })
            //     setItems([...items, ...data.items])
            // } else {
                let data = await get_item_from_product({
                    product_id: active_product,
                    page: page,
                    limit: paginate_data.limit,
                    domain_id: _domain().id
                });
                console.log('Item Section get_item_from_product:', data);
                // dispatch({ type: 'add_items', payload: data });
                setPaginate_data({ ...paginate_data, ...data.paginate_data })
                setItems([...items, ...data.items])
            // }
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error)
        }
    }

    useEffect(() => {
        let _isMount = true
        const get_product_items = async () => {
            setLoader(true)
            try {
                let apires = await get_item_from_product({
                    product_id: params._id,
                    page: 1,
                    limit: 48,
                    store_id: '6342a0361d2151beeb670d32',
                    domain_id: _domain().id
                });
                console.log('set_product_data:', apires);
                dispatch({ type: 'set_product_data', payload: apires });
                if (_isMount) {
                    setPaginate_data({ ...paginate_data, ...apires.paginate_data })
                    setItems(apires.items)
                }
                console.log(apires);
                setLoader(false)

            } catch (error) {
                setLoader(false)
                console.log(error)
            }
        }

        get_product_items()

        return () => {
            _isMount = false
        }
    }, [params._id])


    return (
        <>
            <Breadcrumb />
            <div className="product_section">
                <div className="parent_for_product">
                    {right_side_loader ? <Loader />
                        :
                        isMobile ?
                            items.map(v => <PopularItem data={v} key={v._id} />)
                            :
                            items.map(v => <Item data={v} key={v._id} />)
                    }
                </div>
                {items.length > 0 &&
                    <div className="pagination_section">
                        {loader ?
                            <Loader />
                            :
                            <div>
                                <div className='total_items_count'>{items.length} / {paginate_data.totalItems}</div>
                                <div className='loadMore_data' style={totalPage > paginate_data.currentPage ? {} : { pointerEvents: 'none' }} onClick={() => fetchMoreData(Number(paginate_data.currentPage + 1))}>{totalPage > paginate_data.currentPage ? 'Load More' : 'You have reached the end'}</div>
                            </div>
                        }
                    </div>
                }
                <div className="bottom_banner_section">
                    <div className="bottom_banner_title">Discover more products</div>
                    <FooterSlider />
                </div>
                <div className="sitemap_section">
                    <SiteMap />
                </div>
            </div>
        </>
    )
}

export default Products;