import classNames from 'classnames';
import styles from '../../assets/css/Popup.module.css';
const WorkPopup = ({ setMenu_popup }) => {
    return (
        <div className={classNames(styles.popup_wraper, styles.deshi_wraper)}>
            <div className={classNames(styles.popupInner, styles.deshiInner)} onMouseLeave={() => setMenu_popup('')}>
                <span className={styles.closePopup} onClick={() => setMenu_popup('')}><i className="fa-thin fa-xmark"></i></span>
                <div className={styles.popup_header}>
                    <p className={styles.main_title}>Work & Settle in the UK</p>
                </div>
                <div className={styles.popup_body}>
                    <div className={styles.popup_body_content}>
                        <p className={styles.sub_title}>The most effective path to becoming a British citizen!</p>
                        <p className={styles.body_text}>Brexit has created new opportunities for individuals from countries outside the EU, including Bangladeshi talents and job seekers. Obtaining a job and British citizenship is a significant step towards building a bright future for you and your family. It offers a wide array of benefits, rights, and opportunities that can positively impact various aspects of your personal, professional, and social life.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WorkPopup;