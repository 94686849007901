import React, { useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";
import styles from '../../assets/css/Header.module.css'
function UserHeadSection() {
    const navigate = useNavigate();
    const user = useSelector((state) => state.rootReducer.user_data);
    const [navPopup, setnavPopup] = useState(false);
    // console.log('User data: ', user)
    // const signout =()=> {
    //     navigate('/logout');
    //     setnavPopup(false);
    // }
    const profile =()=> {
        navigate('/profile');
        setnavPopup(false);
    }
    return (
        <>
                {/* <div className={styles.menu_user} style={navPopup ? {pointerEvents:'none'}:{}} onClick={() => setnavPopup(!navPopup)}> */}
            {user ?
                <div className={styles.menu_user} style={navPopup ? {pointerEvents:'none'}:{}} onClick={profile}>
                    <span className={styles.userName} style={{ marginRight: '2px' }}> {user.first_name} </span>
                    <div className={styles.user_img}>
                        <i className="fa-thin fa-user"></i>
                    </div>
                </div>
                :
                <Link to={"/sign-in"} >
                    <div className={styles.menu_user}>
                        <span className={styles.userName} style={{ marginRight: '2px' }}> Hello </span>
                        <div className={styles.user_img}>
                            <i className="fa-thin fa-user"></i>
                        </div>
                    </div>
                </Link>
            }

            {/* {navPopup &&
                <OutsideClickHandler onOutsideClick={()=> setnavPopup(false)}>
                    <div className={styles.userNavPopup}>
                        <div className={styles.userNavPopupItem} onClick={profile}>Profile</div>
                        <div className={styles.userNavPopupItem} onClick={signout}>Sign Out</div>
                    </div>
                </OutsideClickHandler>
            } */}
        </>
    )
}

export default UserHeadSection;