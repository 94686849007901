import React, { useEffect, useState } from 'react'
import styles from '../../../assets/css/JobApplications.module.css'
import { get_job_applications_by_user } from '../../../Utils/method';
import { useSelector } from 'react-redux';
import Loader from '../../Loader';
import Moment from 'react-moment';
import 'moment-timezone';
import { IMAGE_URL } from '../../../Config/Config';
import pdf from '../../../assets/pdf.svg'


function JobApplications({ set_File, setDocument }) {
    const user_data = useSelector((state) => state.rootReducer.user_data);
    const [application, setApplication] = useState([]);
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        let _isMounted = true
        async function initial() {
            setLoader(true)
            try {
                let apires = await get_job_applications_by_user({
                    user_id: user_data._id,
                    server_token: user_data.server_token
                });
                if (_isMounted) {
                    if (apires.success) {
                        setApplication(apires.data)
                    }
                    console.log(apires);
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.log(error);
            }
        }
        initial()
        return () => {
            _isMounted = false
        }
    }, [])

    const [expand_item, setExpand_item] = useState('');

    const preview_file = (d) => {
        set_File(d)
        setDocument(true)
    }

    return (
        <>
            <div className={styles.application_header}>
                <p className={styles.serial}>Sl.</p>
                <p className={styles.application_title}>Title</p>
                <p className={styles.application_date}>Date</p>
            </div>
            <div className={styles.profileOrdersCont}>
                {loader ?
                    <Loader />
                    :
                    application.length > 0 ?
                        application.map((v, i) =>
                            <div className={styles.application_item} key={v._id}>
                                <div className={styles.application_columns} style={expand_item === v._id ? { backgroundColor: 'rgb(255 0 0 / 5%)' } : {}} onClick={() => setExpand_item(prev => prev !== v._id ? v._id : '')}>
                                    <p className={styles.serial}>{i + 1}</p>
                                    <p className={styles.application_title}>{v.job_title}</p>
                                    <p className={styles.application_date}><Moment date={v.created_at} format="ddd, MMM Do YYYY, hh:mm A" /></p>
                                </div>
                                {expand_item === v._id &&
                                    <div className={styles.application_documents}>
                                        <div className={styles.docs_item} onClick={() => preview_file(IMAGE_URL + v.doc_birth_certificate)}>
                                            <img src={IMAGE_URL + v.doc_birth_certificate} alt='' />
                                        </div>
                                        <div className={styles.docs_item} onClick={() => preview_file(IMAGE_URL + v.doc_last_degree)}>
                                            <img src={IMAGE_URL + v.doc_last_degree} alt='' />
                                        </div>
                                        <div className={styles.docs_item} onClick={() => preview_file(IMAGE_URL + v.doc_nid)}>
                                            <img src={IMAGE_URL + v.doc_nid} alt='' />
                                        </div>
                                        <div className={styles.docs_item} onClick={() => preview_file(IMAGE_URL + v.doc_passport)}>
                                            <img src={IMAGE_URL + v.doc_passport} alt='' />
                                        </div>
                                        <div className={styles.docs_item} onClick={() => preview_file(IMAGE_URL + v.doc_cv)}>
                                            <img src={pdf} alt='' />
                                        </div>
                                    </div>
                                }
                            </div>
                        )
                        :
                        <p className={styles._not_found}>No application found!</p>
                }
            </div>
        </>
    )
}

export default JobApplications;