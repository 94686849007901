import React, { useState } from "react";
import Store from "./Store";
import SubCategory from "./SubCategory";

function Group({ data, navigation }) {
    const [expand, setExpand] = useState(true);
    return (
        <div className="group_items">
            <Store data={data} expand={expand} setExpand={setExpand} />
            {expand ?
                <ul className="sub_cate_container">
                    {data.products.map(v => <SubCategory data={v} navigation={navigation} key={v._id}/>)}
                </ul> : ''
            }

        </div>
    )
}

export default Group;