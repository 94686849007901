import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { _domain } from '../Config/Domains';

function Breadcrumb() {
    const location = useLocation();
    const params = useParams();
    const totalItems = useSelector((state) => state.rootReducer.paginate_data.totalItems)
    const [active_product_data, setActive_product_data] = useState(null)
    const [active_main_cate, setActive_main_cate] = useState(null)

    function getActiveProductData(active) {
        let product_groups = JSON.parse(localStorage.getItem('product_groups'))
        let d = [];
        for (let i of product_groups) {
            let p = i.products;
            let q = [];
            for (let t of p) {
                q.push({ ...t, main_cate: i });
            }
            d = [...d, ...q];
        }
        for (let i of d) {
            if (i._id === active) {
                return i;
            }
        }

    }

    useEffect(() => {
        let data = getActiveProductData(params._id);
        setActive_product_data(data)
        setActive_main_cate(data.main_cate)
    }, [params._id])


    return (
        <div className="page_nav" style={_domain().name === 'ukbd.app' ? { padding: '5px 43px' } : {}}>
            <div style={{ borderRadius: '2px', backgroundColor: '#FFF', padding: '0 5px 0 7px' }}>
                <Link to={"/"}><span> Home </span></Link>
                <span style={{ fontSize: '10px' }}> <i className="fa-thin fa-chevron-right"></i> </span>
                {location.pathname.indexOf('/product/') > -1 ?
                    !active_main_cate ? ''
                        : <span>{active_main_cate ? active_main_cate.name ? active_main_cate.name[0] : '' : ''} <span style={{ fontSize: '10px' }}> <i className="fa-thin fa-chevron-right"></i> </span>  {active_product_data ? active_product_data.name ? active_product_data.name[0] : '' : ''} ({totalItems}) </span>
                    : ''
                }
            </div>
            {/* <p className='breadCumLabel'>Products are directly sourced from London and delivered to your doorstep in Dhaka.</p> */}
        </div>
    )
}

export default Breadcrumb;