export const getUser = () => {
    const userStr = localStorage.getItem('user_data');
    if (userStr) return JSON.parse(userStr);
    else return false;
}

export const getCurrencySign = (currency) => {
    if (currency === 'BDT') {
        return '৳'
    } else if (currency === 'GBP') {
        return '£'
    } else if (currency === 'USD') {
        return '$'
    }
}

export const alternateCurrency = (currency) => {
    if (currency === 'BDT') {
        return '£'
    } else if (currency === 'GBP') {
        return '৳'
    } else if (currency === 'USD') {
        return '৳'
    }
}

export const rate_convr_GBPtoBDT = (exchangeRate) => {
    // exchangeRate = 133.17 BDT
    // 1 GBP = 133.17 BDT
    return 1 * exchangeRate;
};

export const rate_convr_BDTtoGBP = (exchangeRate) => {
    // exchangeRate = 0.0076 GBP
    // 1 BDT = 0.0076 GBP
    return 1 / exchangeRate;
};

export const calculatePercentage = (percentage, number) => {
    return (percentage / 100) * number;
};

// price convert to alternative currency from given currency
export const priceConvertToAlternate = (v, c, r) => {
    // console.log('priceConvertToAlternate:', v, c, r);
    let cv = Number(v * r);
    // console.log('Alternate Value:', cv);
    if (c === 'BDT') {
        if (Number.isInteger(cv)) {
            return cv;
        } else {
            return parseFloat(cv).toFixed(2);
        }
    } else if (c === 'GBP') {
        return Math.round(cv)
    }
}

// price convert according to given currency
export const priceConvertTo = (v, co) => {
    //co = {
    //     "payment_currency": "GBP",
    //     "view_price_currency": "BDT",
    //     "exchange_rate_website": 0.0078
    // }
    if (co.view_price_currency === "BDT") {
        if (co.payment_currency === 'GBP') {
            let cv = Number(v * co.exchange_rate_website);
            if (Number.isInteger(cv)) {
                return cv;
            } else {
                return parseFloat(cv).toFixed(2);
            }
        } else {
            return v;
        }
    } else {
        if (co.payment_currency === 'BDT') {
            let cv = Number(v * co.exchange_rate_website);
            return Math.round(cv)
        } else {
            return v;
        }
    }
}

export const previousPrice = (price, offer) => {
    if (offer === 10) {
        return Number(price / 0.9).toFixed(2);
    } else if (offer === 20) {
        return Number(price / 0.8).toFixed(2);
    } else if (offer === 30) {
        return Number(price / 0.7).toFixed(2);
    } else if (offer === 50) {
        return Number(price / 0.5).toFixed(2);
    }

}

export const addWeights = (items) => {
    let totalWeight = 0;
    for (const item of items) {
        totalWeight += (item.item_weight * item.quantity * item.shipping_duties);
    }
    return totalWeight;
};

export const addParcentage = (items) => {
    let total = 0;
    for (const item of items) {
        total += calculatePercentage(item.handling_charge_in_the_uk, item.itemTotal)
    }
    return total;
}

export const exchange_rate = (typeof window !== 'undefined') ? localStorage.getItem('exchange_rate') ? JSON.parse(localStorage.getItem('exchange_rate')) : null : null
// console.log(444, exchange_rate);
export const active_currency = (typeof window !== 'undefined') ?
    (localStorage.getItem('active_currency') ?
        (function () {
            try {
                return JSON.parse(localStorage.getItem('active_currency'));
            } catch (error) {
                console.error(11, "Error parsing 'active_currency' from localStorage:", error);
                return null;
            }
        })() : null
    ) : null;

export const currencyData = [
    { code: "GBP", icon: 'gb', name: "GBP", sign: '$', rate: exchange_rate?.['GBP'] || 1 },
    { code: "USD", icon: 'us', name: "Dollar", sign: '$', rate: exchange_rate?.['USD'] || 1 },
    { code: "EUR", icon: 'eu', name: "Euro", sign: '€', rate: exchange_rate?.['EUR'] || 1 },
    { code: "CAD", icon: 'ca', name: "Dollar", sign: '$', rate: exchange_rate?.['CAD'] || 1 },
    { code: "BDT", icon: 'bd', name: "TK", sign: '৳', rate: exchange_rate?.['BDT'] || 1 },
    { code: "INR", icon: 'in', name: "Rupee", sign: '₹', rate: exchange_rate?.['INR'] || 1 },
    { code: "PKR", icon: 'pk', name: "Rupee", sign: 'Rs', rate: exchange_rate?.['PKR'] || 1 },
];

export const converted_currency = (price) => {
    if (active_currency) {
        return `${active_currency.sign} ${(price * active_currency.rate).toFixed(2)}`
    } else {
        return `${currencyData[0].sign} ${(price * currencyData[0].rate).toFixed(2)}`
    }
}