import React from "react";
import UKBD from "../assets/UKBD.png"
import USBD from "../assets/USBD.jpg"
import JPBD from "../assets/JPBD.jpg"
import { _domain } from "../Config/Domains";

function HomeLoader() {
    return (
        <div className="loader_ico home" style={{ height: '100vh' }}>
            <div style={{ width: '100px', height: '100px' }}>
                {_domain().name === 'ukbd.app' ? <img src={UKBD} alt={"loader"} className={"zoom-in-out-box"} />
                    : _domain().name === 'usbd.app' ? <img src={USBD} alt={"loader"} className={"zoom-in-out-box"} />
                        : _domain().name === 'jpbd.app' ? <img src={JPBD} alt={"loader"} className={"zoom-in-out-box"} />
                            : <img src={UKBD} alt={"loader"} className={"zoom-in-out-box"} />
                }
            </div>
        </ div>
    )
}
export default HomeLoader;