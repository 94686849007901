import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { getUniqCartId, getDeviceToken, user_registationMethod, getUserData, email_or_phone_verification } from "../../../Utils/method"
import { useDispatch, useSelector } from 'react-redux'
import isEmail from 'validator/lib/isEmail';
import Countdown from "react-countdown";
import styles from '../../../assets/css/SignIn.module.css'
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUp(params) {
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const login_modal = useSelector((state) => state.rootReducer.login_modal)
    const [acceptCondition, setAcceptCondition] = useState(false);
    const [buttonState, setButtonState] = useState(false);
    const [passVisibility, setPassVisibility] = useState(false);
    const [inputState, setInputState] = useState(false);
    const [sendtime, setSendTime] = useState(Date.now() + 180000);
    const [spining, setSpining] = useState(false);
    const [otp, setOtp] = useState('');
    const [activeStep, setActiveStep] = useState(1);
    const [inputOtp, setInputOtp] = useState('');
    const [inputData, setInputData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        country_phone_code: '',
        country_code: '',
        address: '',
        is_phone_number_verified: true,
        is_email_verified: true,
        login_by: 'Manual',
        social_id: "",
        cart_unique_token: getUniqCartId(),
        device_token: getDeviceToken(),
        device_type: 'web',
        referral_code: '',
        city: '',
    })

    const [errMsg, setErrMsg] = useState({
        email: '',
        phone: '',
        otp: '',
        password: ''
    })

    const onChangeFormData = (e) => {
        let { name, value } = e.target;

        if (name === 'first_name' || name === 'last_name') {
            if (Number(value.charAt(0))) {
                return;
            }
        } else if (name === 'email') {
            if (value !== '' && !isEmail(value)) {
                console.log(41, 'It\'s not Email.')
                setErrMsg({ ...errMsg, email: 'Enter valid email address' });
            } else {
                setErrMsg({ ...errMsg, email: '' });
            }
        }
        setInputData({ ...inputData, [name]: value })
    }

    const getPhoneData = (value, data, event, formattedValue) => {
        let { countryCode, dialCode, name } = data;
        setInputData({
            ...inputData,
            phone: value.slice(data.dialCode.length),
            country_phone_code: '+' + dialCode,
            country_code: countryCode
        })
        setErrMsg({ ...errMsg, 'phone': '' })
    }

    useEffect(() => {
        let state = false;
        if (inputData.first_name === '') {
            state = false;
        } else if (inputData.last_name === '') {
            state = false;
        } else if (inputData.phone === '') {
            state = false;
        } else if (inputData.email === '' || !isEmail(inputData.email)) {
            state = false;
        } else if (inputData.password === '') {
            state = false;
        } else if (!acceptCondition) {
            state = false;
        } else {
            state = true
        }

        setButtonState(state);
        setInputState(state);
    }, [inputData, acceptCondition]);


    const userSignUp = async () => {
        try {
            if (buttonState) {
                setSpining(prev => !prev);
                let apires = await user_registationMethod(inputData);
                if (apires.success) {
                    toast.success(apires.status_phrase, { autoClose: 1000 });

                    getUserData({ data: apires.user, type: 'set' });
                    dispatch({ type: 'set_user_data', payload: apires.user });
                    if (login_modal.from === 'checkout') {
                        dispatch({ type: 'set_login_modal', payload: { from: '', signin: false, signup: false } });
                        navigate("/" + login_modal.from);
                    } else {
                        navigate("/");
                    }
                    setSpining(prev => !prev);
                } else {
                    setSpining(prev => !prev);
                    toast.error(apires.status_phrase, { autoClose: 1000 });
                }

                console.log(136, apires)
            }


        } catch (error) {
            console.log(137, error)
        }
    }

    const verification = async (data) => {
        setSpining(prev => !prev);
        try {
            setSendTime(Date.now() + 180000);
            let apires = await email_or_phone_verification(data);
            console.log(apires);
            if (apires.success) {
                setOtp(apires.otp);
            } else {
                setOtp(apires.otp);
            }
            setSpining(prev => !prev);
        } catch (error) {
            setSendTime(Date.now() + 180000);
            setSpining(prev => !prev);
            console.log(error);
        }
    }

    useEffect(() => {
        console.log(Number(inputOtp) === Number(otp), inputOtp.length)
        if (inputOtp.length === 6 || inputOtp.length > 5) {
            if (Number(inputOtp) === Number(otp)) {
                console.log("OTP Matched");
                console.log("Increment 1");
                // setActiveStep(prev => prev + 1);
            } else {
                if (inputOtp.length !== '') {
                    setErrMsg({ ...errMsg, 'otp': 'You have entered wrong OTP.' });
                } else {
                    setErrMsg({ ...errMsg, 'otp': '' });
                }
            }
        } else {
            setErrMsg({ ...errMsg, 'otp': '' });
        }
    }, [inputOtp])

    const gotoNext = async (current) => {
        if (current === 1 && inputData.phone !== '') {
            let apires = await email_or_phone_verification({ type: 'phone', value: inputData.phone });
            if (apires.success) {
                setActiveStep(current + 1);
                setErrMsg({ ...errMsg, 'phone': '' })
            } else {
                console.log(apires)
                setErrMsg({ ...errMsg, 'phone': 'This phone is already used.' })
                toast.error("This phone is already used.", { autoClose: 1000 });
            }
            return;
        }

        if (current === 2 && inputData.email !== '' && isEmail(inputData.email)) {
            // verification({ type: 'email', value: inputData.email });
            setSpining(true);
            let apires = await email_or_phone_verification({ type: 'email', value: inputData.email });
            if (apires.success) {
                setSendTime(Date.now() + 180000);
                setOtp(apires.otp);
                setActiveStep(current + 1);
                setSpining(prev => !prev);
                setErrMsg({ ...errMsg, 'email': '' })
            } else {
                setSpining(prev => !prev);
                setErrMsg({ ...errMsg, 'email': 'This email is already used.' })
                toast.error("This email is already used.", { autoClose: 1000 });
            }
            console.log(apires)
            return;
        }

        if ((current === 3 && inputOtp !== '') && (Number(inputOtp) === Number(otp))) {
            console.log("Increment 4");
            setActiveStep(current + 1);
            return;
        } else {
            return;
        }
    }

    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer newestOnTop />
            <div className={styles.carting_head}>
                <span className={styles.closeCart} onClick={() => navigate(-1)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.signin_partition}>
                <div className={classNames(styles.form_content, styles.signup_content)} style={{ backgroundColor: '#F8FAFF' }}>
                    <div className={styles.signUp_form}>
                        <h1 className={styles.signup_title}>Sign Up</h1>
                        {
                            activeStep === 1 ?
                                <div className={classNames(styles.inputCont, styles.phoneInput)}>
                                    <p className={styles.inputLabel}>Enter your phone number</p>
                                    <PhoneInput
                                        containerClass={styles.phone_module}
                                        inputClass={styles.inputs}
                                        buttonClass={styles.codeDropDown}
                                        dropdownClass={styles.countryList}
                                        inputProps={{
                                            name: 'phone',
                                            required: true,
                                        }}
                                        country={'bd'}
                                        value={''}
                                        onChange={(value, data, event, formattedValue) => getPhoneData(value, data, event, formattedValue)}
                                    />
                                    <span className={styles.errMsg} style={{ bottom: '-16px' }}>{errMsg.phone}</span>
                                </div>
                                : activeStep === 2 ?
                                    <div className={styles.inputCont}>
                                        <p className={styles.inputLabel}>Enter your email</p>
                                        <input className={classNames(styles.inputs, inputData.email === '' ? styles.inputError : '')} value={inputData.email} onChange={onChangeFormData} type="email" placeholder="Email" name="email" required autoFocus />
                                        <span className={styles.errMsg}>{errMsg.email}</span>
                                    </div>
                                    : activeStep === 3 ?
                                        <div className={styles.inputCont}>
                                            <p className={styles.inputLabel}>
                                                OTP has been sent to your email.
                                                <label className="verificationLabel">
                                                    <Countdown date={sendtime} key={sendtime} renderer={props => props.completed ?
                                                        <span className={styles.resendBTN} onClick={() => verification({ type: 'email', value: inputData.email })} ><i className="fa-thin fa-arrow-rotate-right"></i> Resend</span>
                                                        :
                                                        <span className={styles.resendBTN} style={{ textDecoration: 'none' }} >{props.minutes}:{props.seconds}</span>} />
                                                </label>
                                            </p>
                                            <input className={classNames(styles.inputs)} value={inputOtp} onChange={(e) => setInputOtp(e.target.value)} type="number" placeholder="Enter OTP" name="emailOtp" required autoFocus />
                                            <span className={styles.errMsg}>{errMsg.otp}</span>
                                        </div>
                                        :
                                        <>
                                            <div className={styles.inputCont}>
                                                <input className={classNames(styles.inputs, inputData.first_name === '' ? styles.inputError : '')} value={inputData.first_name} onChange={onChangeFormData} type="text" placeholder="First Name" name="first_name" autoFocus autoComplete="new-password" />
                                            </div>
                                            <div className={styles.inputCont}>
                                                <input className={classNames(styles.inputs, inputData.last_name === '' ? styles.inputError : '')} value={inputData.last_name} onChange={onChangeFormData} type="text" placeholder="Last Name" name="last_name" required autoComplete="new-password" />
                                            </div>
                                            <div className={styles.inputCont}>
                                                <input className={classNames(styles.inputs)} value={inputData.referral_code} onChange={onChangeFormData} type="text" placeholder="Referral Code" name="referral_code" autoComplete="new-password" />
                                            </div>
                                            <div className={styles.inputCont}>
                                                <input className={classNames(styles.inputs, inputData.password === '' ? styles.inputError : '')} value={inputData.password} minLength={6} onChange={onChangeFormData} type={!passVisibility ? "password" : "text"} placeholder="Password" name="password" required autoComplete="new-password" />
                                                <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-thin", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span> {/*<i className="fa-thin fa-eye-slash"></i> */}
                                            </div>
                                            <div className={styles.inputCont}>
                                                <label className={styles.rememberContainer}> By Continuing you may agree to out <Link to={'/terms-conditions'} target={'_blank'}> Terms Conditions </Link> and <Link to={'/privacy-policy'} target={'_blank'}> Privacy Policy </Link>
                                                    <input type="checkbox" onChange={(e) => setAcceptCondition(e.target.checked)} name="conditions" />
                                                    <span className={styles.checkmark}></span>
                                                </label>
                                            </div>
                                        </>
                        }

                        {activeStep === 4 ?
                            <div className={classNames(styles.inputCont, spining ? styles.onProcoess : '')}>
                                <button type="submit" className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} onClick={userSignUp}>Sign Up  <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                            </div>
                            :
                            <div className={classNames(styles.inputCont, spining ? styles.onProcoess : '')}>
                                <button type="submit"
                                    className={
                                        classNames(styles.signin_button,
                                            activeStep === 1 && inputData.phone !== '' ? ''
                                                : activeStep === 2 && inputData.email !== '' && isEmail(inputData.email) ? ''
                                                    : (activeStep === 3 && inputOtp !== '') && (Number(inputOtp) === Number(otp)) ? ''
                                                        : styles.buttoninactive
                                        )
                                    }
                                    onClick={() => gotoNext(activeStep)}>Next  <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignUp;