/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Sidebar from './layouts/Sidebar';
import RightSide from './RightSide'
import { get_domain_by_name, gethomedata } from "../Utils/method";
import Loader from "./HomeLoader";
import { isMobile } from 'react-device-detect';
import DeliveryWarning from './popups/DeliveryWarning';
import SignupWarning from './popups/SignupWarning';
import { useLocation, useParams } from 'react-router-dom';
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { _domain } from '../Config/Domains';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';
import Landing from './pages/Landing/Landing';
import MessengerCustomerChat from 'react-messenger-customer-chat';

if (isMobile) {
    let vh = window.innerHeight * 0.01;
    document.querySelector("#root").style.setProperty("--vh", `${vh}px`);
}

function Home() {
    const dispatch = useDispatch()
    const location = useLocation();
    const params = useParams();

    const full_page_loader = useSelector((state) => state.rootReducer.full_page_loader);
    const deliveryWarning = useSelector((state) => state.rootReducer.deliveryWarning)
    const signupWarning = useSelector((state) => state.rootReducer.signupWarning)
    const initialFetchD = useRef(true);

    useEffect(() => {
        async function get_home_data(domain_id) {
            try {
                dispatch({ type: 'set_right_side_loader', payload: true });
                let data = await gethomedata({
                    store_delivery_id: localStorage.getItem('delivery_id') || '',
                    store_id: '6342a0361d2151beeb670d32',
                    domain_id: domain_id
                });
                console.log('gethomedata Home', data);
                console.log('Product groups:', data.product_groups);

                if (window.location.pathname.indexOf('/product') > -1) {
                    data['active_product'] = params._id;
                }
                localStorage.setItem('product_groups', JSON.stringify(data.product_groups))
                dispatch({ type: 'set_home_data', payload: data });
                dispatch({ type: 'set_currency_sign', payload: data.country.currency_sign });
                dispatch({ type: 'set_right_side_loader', payload: false });
            } catch (error) {
                console.log(error);
            }
        }
        if ((location.pathname.indexOf('/product/') > -1 || location.pathname.indexOf('/single-item/') > -1) || location.pathname === '/' || location.pathname === '/deshi' || location.pathname === '/migration' || location.pathname.indexOf('/migration/') > -1 || location.pathname === '/study' || location.pathname === '/profile') {
            get_home_data(_domain().id)
        } else {
            dispatch({ type: 'set_full_page_loader', payload: false })
        }

        const get_domain_data = async () => {
            try {
                let apires = await get_domain_by_name(`?name=${_domain().name}`)
                console.log('get_domain_by_name:', apires.domain);
                localStorage.setItem('_domain', JSON.stringify(apires.domain))
            } catch (error) {
                console.log(error);
            }
        }

        get_domain_data()

        return () => {
            initialFetchD.current = false;
        }
    }, [location])

    const containerRef = useRef(null);

    const scrollToBottom = () => {
        const container = containerRef.current;
        if (_domain().name === 'ukbd.app') {
            if (!isMobile) {
                if (container) {
                    // console.log(document.querySelector('#landing')?.clientHeight);
                    if (document.querySelector('#landing')) {
                        if (container.scrollTop >= document.querySelector('#landing')?.clientHeight) {
                            document.querySelector('#main_content_div').style.overflowY = 'auto';
                        } else {
                            document.querySelector('#main_content_div').style.overflowY = 'hidden';
                        }
                    } else {
                        document.querySelector('#main_content_div').style.overflowY = 'auto';
                    }
                }
            }
        }
    };


    useEffect(() => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: '678835337239904',
                xfbml: true,
                version: 'v11.0', // Replace with the desired Facebook Graph API version
            });
        };

        (function (d, s, id) {
            var js,
                fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = 'https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js';
            fjs.parentNode.insertBefore(js, fjs);
        })(document, 'script', 'facebook-jssdk');
    }, []);

    const openMessenger = () => {
        window.FB.CustomerChat.showDialog({
            pageId: '105645192401585',
        });
    };

    return (
        <>
            {full_page_loader ? <Loader /> :
                <div className='container'>
                    <Header />
                    <div className='container_middle'
                        id='container_middle'
                        ref={containerRef}
                        onScroll={scrollToBottom}
                        style={_domain().name === 'ukbd.app' ? { overflowY: 'auto' } : {}}
                    >

                        {_domain().name === 'ukbd.app' && location.pathname === '/' && <Landing />}
                        {isMobile && <Sidebar />}
                        <RightSide />
                    </div>

                    {!isMobile ?
                        <MessengerCustomerChat
                            pageId="105645192401585"
                            appId="678835337239904"
                        />
                        :
                        <div className={'custom_messenger'} onClick={openMessenger}>
                            <i className="fa-brands fa-facebook-messenger" style={{ color: '#005eff' }}></i>
                        </div>
                    }

                    <Footer />
                    {/* {ProductPreviewStatus ? <ProductPreview /> : ''} */}
                    {/* {location.pathname === '/' ? <CountDownTimer />:''} */}
                    {deliveryWarning.warning && <DeliveryWarning />}
                    {signupWarning.warning && <SignupWarning />}
                    {/* {permissionPopup && <LocationPermission permission={permission} />} */}
                    {/* {(window.location.hostname.indexOf('usbdapp') > -1 || window.location.hostname.indexOf('jpbdapp') > -1) && <SliderPopup />} */}

                    <CookieBanner
                        className='cookieBanner'
                        policyLink="/privacy-policy"
                        message="We use cookies to help give you the best experience on our site and to allow us and third parties to customise the marketing content you see across websites and social media."
                        preferencesOptionInitiallyChecked
                        statisticsOptionInitiallyChecked
                        marketingOptionInitiallyChecked
                        showAcceptSelectionButton
                        acceptSelectionButtonText="Accept Selection"
                        onAcceptPreferences={() => {
                            // load your preferences trackers here
                        }}
                        onAcceptStatistics={() => {
                            // load your statistics trackers here
                        }}
                        onAcceptMarketing={() => {
                            // load your marketing trackers here
                        }}
                        styles={{
                            message: {
                                color: '#fff',
                                fontFamily: 'Poppins',
                                fontSize: '13px',
                                fontWeight: 200,
                                lineHeight: '20px',
                                marginBottom: '0px',
                            },
                            optionWrapper: {
                                marginLeft: '0px',
                                marginRight: '10px',
                                display: 'inline-block'
                            },
                            checkbox: {
                                position: 'relative',
                                top: '2px',
                            },
                            optionLabel: {
                                color: '#fff',
                                fontFamily: 'Poppins',
                                fontWeight: 200,
                                fontSize: '13px',
                            },
                            policy: {
                                color: '#fff',
                                fontFamily: 'Poppins',
                                fontWeight: 200,
                                fontSize: '13px',
                            },
                            button: {
                                borderRadius: '15px',
                                fontFamily: 'Poppins',
                            }
                        }}
                    />
                </div>
            }
        </>

    )
}

export default Home;