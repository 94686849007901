import React, { useState } from 'react';
import { Rating } from 'react-simple-star-rating'
import styles from '../../../assets/css/ProductPreview.module.css'
import { IMAGE_URL } from '../../../Config/Config';
const Review_write = (props) => {
    const [rating, setRating] = useState(0)
    const tooltipArray = [
        "Terrible",
        "Terrible+",
        "Bad",
        "Bad+",
        "Average",
        "Average+",
        "Great",
        "Great+",
        "Awesome",
        "Awesome+"
    ];
    const fillColorArray = [
        "#f17a45",
        "#f17a45",
        "#f19745",
        "#f19745",
        "#f1a545",
        "#f1a545",
        "#f1b345",
        "#f1b345",
        "#f1d045",
        "#f1d045"
    ];

    // Catch Rating value
    const handleRating = (rate) => {
        setRating(rate)
        // other logic
    }
    // Optinal callback functions
    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)

    const [text, setText] = useState('');
    const [count, setCount] = useState('');
    const MAX_LENGTH = 200; // Define the maximum character length

    const handleTextChange = (event) => {
        const inputValue = event.target.value;
        const newCount = inputValue.length;

        // Update the textarea value and character count
        setText(inputValue);
        setCount(newCount);
    };

    const countRemaining = MAX_LENGTH - text.length;

    return (
        <div className={styles.review_popup}>
            <span onClick={() => props.setReviewWriting(false)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.review_container}>
                <div className={styles.review_title_section}>
                    <div className={styles.review_image}>
                        <img className={styles.sliderImg} src={IMAGE_URL + props.data.image_url[0]} alt='' />
                    </div>
                    <div className={styles.review_title}><h2> {props.data.name} </h2></div>
                </div>
                <div className={styles.review_rating_section}>
                    <p className={styles.rating_label}>Rating the product out of 5</p>
                    <Rating
                        onClick={handleRating}
                        onPointerEnter={onPointerEnter}
                        onPointerLeave={onPointerLeave}
                        onPointerMove={onPointerMove}
                        initialValue={rating}
                        transition
                        allowFraction
                        // showTooltip
                        // tooltipArray={tooltipArray}
                        fillColorArray={fillColorArray}
                        tooltipClassName={styles.tooltipStyle}
                    />
                </div>
                <div className={styles.review_comment_section}>
                    <p className={styles.rating_label}>Tell us more about it</p>
                    <textarea
                        className={styles.comment_box}
                        value={text}
                        onChange={handleTextChange}
                        placeholder="e.g. Was it good quality? What did you use it for? What would be helpful for others to know?"
                    />
                    <p className={styles.remain_counter}>Characters remaining: {countRemaining}/{MAX_LENGTH}</p>
                </div>
                <div className={styles.review_btn_section}>
                    <button className={styles.review_submit_btn}>Submit Review</button>
                </div>
            </div>
        </div>
    );
}

export default Review_write;