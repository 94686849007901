import React, { useState, useEffect } from "react";
import styles from '../../../assets/css/Cart.module.css';
import { getCurrencySign, calculatePercentage, priceConvertToAlternate, alternateCurrency, rate_convr_GBPtoBDT } from "../../../Utils/common"
import CurrencyFormat from 'react-currency-format';
import { useSelector, useDispatch } from "react-redux";
import { useCart } from "react-use-cart";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import { _domain } from "../../../Config/Domains";

function Summary(props) {
    const dispatch = useDispatch()
    const {
        totalUniqueItems,
        cartTotal
    } = useCart();

    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const [activeDelivery, setactiveDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const cart_total_weight = useSelector((state) => state.rootReducer.cart_total_weight);
    const cart_total_parcentage = useSelector((state) => state.rootReducer.cart_total_parcentage);
    const commission_parcentage = useSelector((state) => state.rootReducer.commission_parcentage);
    const currencyChangeCounter = useSelector((state) => state.rootReducer.currencyChangeCounter);
    const [currency_data, setCurrency_data] = React.useState(JSON.parse(localStorage.getItem('currency_data')))

    useEffect(() => {
        setCurrency_data(JSON.parse(localStorage.getItem('currency_data')))
    }, [currencyChangeCounter])

    const [commission, setCommission] = useState(0)
    const cart_total_price = Number(cartTotal).toFixed(2);
    const parcentage_price = (cart_total_parcentage).toFixed(2);
    const weight_price = (cart_total_weight).toFixed(2);
    const parcentage_and_weight_price = Number(Number(parcentage_price) + Number(weight_price)).toFixed(2)
    const total_price = (Number(weight_price) + Number(cart_total_price) + Number(parcentage_price) + Number(commission)).toFixed(2);

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data.length !== 1) {
            if (data[1].indexOf('.') === -1) {
                return v;
            } else {
                let frc = data[1].split('.')
                return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
            }
        }
    }

    useEffect(() => {
        //   console.log('total_price:', total_price);
        localStorage.setItem('total_cart_amount', total_price)
    }, [total_price])


    useEffect(() => {
        if (cartTotal === 0) {
            dispatch({ type: "set_cart_total_weight", payload: 0 })
            dispatch({ type: "set_cart_total_parcentage", payload: 0 })
        }
        let comParcent = calculatePercentage(10, cartTotal)
        setCommission(comParcent.toFixed(2))
    }, [cartTotal])

    return (
        <div className={styles.summaryPart}>
            <div className={styles.sectionTitle}>YOUR INVOICE</div>
            <div className={styles.invoiceContainer}>
                <div className={styles.summarySections}>
                    <div className={styles.summaryInnerTop}>
                        <div className={styles.bagSection}>
                            <span className={styles.bagdata}>Your Basket ({totalUniqueItems} items)</span>
                            <div className={styles.itemPrice}> <CurrencyFormat value={cart_total_price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} /> </div>
                        </div>
                        <div className={styles.bagSection}>
                            <span className={styles.bagdata} style={{ width: '180px', lineHeight: '20px' }}>Handling charge in the UK, Shipping, customs & duties.</span>
                            <div className={styles.itemPrice}> <CurrencyFormat value={parcentage_and_weight_price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} /> </div>
                        </div>
                        <div className={styles.bagSection}>
                            <span className={styles.bagdata}>{_domain().title} Commission</span>
                            <div className={styles.itemPrice}> <CurrencyFormat value={commission} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} /> </div>
                        </div>
                    </div>
                    <div className={styles.summaryInnerBottom}>
                        <span className={styles.promoLabel}>Add your promotion code</span>
                        <div className={styles.promoCodeSection}>
                            <input type={'text'} placeholder={'Enter code'} />
                            <button className={styles.promoAdd}>APPLY</button>
                        </div>

                        <div className={styles.totalSection}>
                            <span className={styles.totaldata}>Total (<span style={{ fontWeight: 300, fontSize: 14 }}>Incl. delivery in Dhaka</span>)</span>
                            <div className={styles.itemPrice}><CurrencyFormat value={total_price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} /> </div>
                        </div>
                        <div className={styles.total_section}>
                            <span className={styles.totaldata} style={{ fontSize: 12, fontWeight: 300, color: '#012965' }}>Equivalent Taka (BDT) excluding VAT : </span>
                            <span className={styles.total_in_bd}><CurrencyFormat value={priceConvertToAlternate(total_price, 'GBP', rate_convr_GBPtoBDT(activeDelivery.conversion_rate))} displayType={'text'} thousandSeparator={true} prefix={alternateCurrency(_domain().currency) + ' '} renderText={value => value} /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Summary;