import React, { useState } from 'react';
import styles from '../../assets/css/Header.module.css';
import AddButton from '../AddButton';
import CurrencyFormat from 'react-currency-format';
import { Link, useLocation } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import loader from '../../assets/loader.svg';
import { IMAGE_URL } from '../../Config/Config';
import { useCart } from 'react-use-cart';
import { getCurrencySign } from '../../Utils/common';
import classNames from 'classnames';
import { searchItems } from '../../Utils/method';
import { _domain } from '../../Config/Domains';

function SearchSuggest(props) {
    const {
        inCart
    } = useCart();
    const location = useLocation()

    const [searchValue, setSearchValue] = useState('');
    const [searchLoader, setSearchLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState();
    const [paginate_data, setPaginate_data] = useState({ currentPage: 1, totalItems: 0, itemsPerPage: 0 });
    const [nextPage, setNextPage] = useState();
    const [searchedItemList, setSearchedItemList] = useState([]);
    const stores = useSelector((state) => state.rootReducer.stores);

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    const fetchMoreData = async () => {
        try {
            if (hasNextPage) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: searchValue,
                    page: nextPage,
                    limit: 20,
                    domain_id: _domain().id
                });
                if (apires.status) {
                    setSearchedItemList([...searchedItemList, ...apires.items]);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                console.log('Fetch data: ', apires);
            }
        } catch (error) {
            console.log(error)
        }
    }

    let timer;
    const searchOnKeyup = (e) => {
        clearTimeout(timer);
        timer = setTimeout(async function () {
            setSearchLoader(true);
            var vLength = e.target.value.length
            if (vLength > 0) {
                let apires = await searchItems({
                    store_id: stores[0]._id,
                    search_text: e.target.value,
                    page: 1,
                    limit: 50,
                    domain_id: _domain().id
                });
                if (apires.status) {
                    setSearchedItemList(apires.items);
                    setPaginate_data(apires.paginate_data)
                    let count = Number(apires.paginate_data.totalItems / apires.paginate_data.itemsPerPage) > apires.paginate_data.currentPage
                    setHasNextPage(count);
                    setNextPage(count ? Number(apires.paginate_data.currentPage + 1) : apires.paginate_data.currentPage);
                }
                setSearchLoader(false);
                console.log(apires)
            }
        }, 500);
    }

    return (
        <div className={styles.searchSuggestionPopup}>
            <div className={styles.searchSuggestionPopupInner} onMouseLeave={() => props.setMenu_popup('')}>
                <div className={styles.carting_head}>
                    <span className={styles.closeCart} onClick={() => props.setMenu_popup('')}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <div className={styles.searchInputSection}>
                    <input type="text" id="headerSearch1" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup} placeholder="Search ..." autoFocus />
                    <span className={styles.searchButton}>
                        <i className="fa-thin fa-magnifying-glass"></i>
                    </span>
                </div>
                {/* <span className={styles.closeSearch} ><i className="fa-thin fa-xmark"></i></span> */}
                <div className={styles.searchItemContainer} id="searchItemContainer">
                    {searchLoader ?
                        <div style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                        </div>
                        :
                        <InfiniteScroll
                            dataLength={searchedItemList.length}
                            next={fetchMoreData}
                            hasMore={hasNextPage}
                            // className={styles.searchItemContainer}
                            loader={
                                <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                                </div>
                            }
                            scrollableTarget="searchItemContainer"
                        >
                            {
                                searchedItemList.map((v, i) =>
                                    <div className={classNames(styles.srItemHolder, inCart(v._id) ? styles.itemInCart : '')} key={'sri_' + i}>
                                        <Link to={'/single-item/' + v._id} className={styles.srItem} onClick={() => { props.setMenu_popup(''); setSearchValue('') }}>
                                            <div className={styles.sri_image}>
                                                <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
                                            </div>
                                            <div className={styles.sriDetails}>
                                                <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
                                                <div className={styles.sri_price}>
                                                    <CurrencyFormat value={v.price.toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                                                </div>
                                            </div>
                                        </Link>
                                        <div className={styles.srItemAdd}>
                                            <AddButton data={v} theme="search" />
                                        </div>
                                    </div>
                                )
                            }
                        </InfiniteScroll>
                    }
                </div>
            </div>
        </div>
    )
}

export default SearchSuggest