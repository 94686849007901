import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { useNavigate } from 'react-router-dom'
import styles from '../../../assets/css/Profile.module.css'
import { deactiveUser } from '../../../Utils/method'


export default function DeactivatePopup(props) {
    // const navigate = useNavigate();
    const [deactiveState, setDeactiveState] = useState(1)
    const [password, setPassword] = useState('')
    const [passwordErr, setPasswordErr] = useState('')
    const user = useSelector((state) => state.rootReducer.user_data);

    const deactiveAccount = async () => {
        if (password === '') return;
        try {
            let apires = await deactiveUser({old_password:password});
            console.log(apires)
            if (apires.success) {
                localStorage.removeItem('user_data');
                props.setDeactivePopup(false)
            //     // navigate('/');
                window.location.href = "/";
                setPasswordErr('')
            } else {
                setPasswordErr(apires.status_phrase)
            }
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div className={styles.wrapper}>
            <span className={styles.closeCart} onClick={() => props.setDeactivePopup(false)}>
                <i className="fa-thin fa-xmark"></i>
            </span>
            <div className={styles.mainContainer}>
                {deactiveState === 1 ?
                    <div className={styles.mainContent}>
                        <h2 className={styles.accountStatusTitle}>Delete account </h2>
                        <p className={styles.accountStatusMsg}>Do you really want to delete this account?</p>
                    </div>
                    : deactiveState === 2 ?
                        <div className={styles.mainContent}>
                            <h2 className={styles.accountStatusTitle}>Caution</h2>
                            <p className={styles.accountStatusMsg}>
                                Hi, <span style={{ fontWeight: '500', whiteSpace: 'nowrap' }}>{user.first_name + ' ' + user.last_name}</span>, thank you for being with us. Please read the below points carefully before deleting your account.
                            </p>
                            <h2 className={styles.accountStatusTitle}>Deleting Your Account will</h2>
                            {/* <bold centered> Deleting Your Account will: </b> */}
                            <ul className={styles.cautionList}>
                                <li> Delete all your account info permanently from UKBD.</li>
                                <li>Erase all your data including profile information, Past Orders, Cart Information, Delivery Location information, Purchase History etc.</li>
                                <li>No further access with the same credentials.</li>
                                <li>This process is irreversible.</li>
                            </ul>
                        </div>
                        :
                        <div className={styles.mainContent}>
                            <h2 className={styles.accountStatusTitle}>Delete account </h2>
                            <div className={styles.inputContainer} style={{padding:'0 40px'}}>
                                <p className={styles.accountStatusMsg} style={{ textAlign: 'left',paddingBottom:'0px' }}>Enter your password</p>
                                <input type={'password'} style={{marginBottom:'0px'}} className={styles.inputs} value={password} onChange={(e)=> setPassword(e.target.value)} placeholder="Password *" autoComplete="new-password" />
                                <span className={styles.errMsg} style={{position:'unset'}}>{passwordErr}</span>
                                <span className={styles.psw}><Link to="/forgot-password">Forgotten your password?</Link></span>
                            </div>
                        </div>
                }

                <div className={styles.timerSection}>
                    {deactiveState === 1 ?
                        <h2 className={styles.accountStatusBtns}>
                            <button className={styles.cancelAction} onClick={() => props.setDeactivePopup(false)}>Cancel</button>
                            <button className={styles.accountAction} onClick={() => setDeactiveState(2)}>Yes</button>
                        </h2>
                        : deactiveState === 2 ?
                            <h2 className={styles.accountStatusBtns}>
                                <button className={styles.cancelAction} onClick={() => setDeactiveState(1)}>Cancel</button>
                                <button className={styles.accountAction} onClick={() => setDeactiveState(3)}>Continue</button>
                            </h2>
                            :
                            <h2 className={styles.accountStatusBtns}>
                                <button className={styles.cancelAction} onClick={() => setDeactiveState(2)}>Cancel</button>
                                <button className={styles.accountAction} style={password === ''? {opacity:'0.5'}:{}} onClick={deactiveAccount}>Confirm</button>
                            </h2>
                    }
                </div>
            </div>
        </div>
    )
}
