import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { user_get_legal } from "../../../Utils/method";
import styles from '../../../assets/css/PrivacyPolicy.module.css';
import { useNavigate } from "react-router-dom";

function PrivacyPolicy() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const user_privacy = useSelector((state) => state.rootReducer.user_privacy)
    const fetchData = async () => {
        try {
            let data = await user_get_legal();
            dispatch({ type: 'set_user_privacy', payload: data.legal.user_privacy });
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        fetchData();
    }, []);
    return (
        <div className={styles.privacyPolicyContainer}>
            <span onClick={() => navigate(-1)} className={styles.closeCart}><i className="fa-thin fa-xmark"></i></span>
            <div className={styles.privacyPolicy} dangerouslySetInnerHTML={{ __html: user_privacy }}></div>
            <div id="cookiefirst-policy-page"></div>
            <div>This cookie policy has been created and updated by <a href="https://cookiefirst.com">CookieFirst.com</a>.</div>
            <div id="cookiefirst-cookies-table"></div>
            <div>This cookie table has been created and updated by the <a href="https://cookiefirst.com">CookieFirst consent management platform</a>.</div>

        </div>
    )
}

export default PrivacyPolicy;