import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { get_stripe_payment_intent } from "../../../Utils/method";

import CheckoutForm from "./CheckoutForm";
import "../../../assets/css/Stripe.css";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

export default function Stripe(props) {
  console.log(16, props)
  // const stripePromise = loadStripe("pk_test_TYooMQauvdEDq54NiTphI7jx");
  const stripePromise = loadStripe(props.paymentGetWay?.payment_key_id);

  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret: props.ck,
    appearance,
  };

  return (
      props.ck && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm ck={props.ck} paymentGetWay={props.paymentGetWay} confirm_button={props.confirm_button} sCard={props.sCard} invoice={props.invoice} />
        </Elements>
      )
  );
}
