import classNames from 'classnames';
import styles from '../../assets/css/Study.module.css';
import flag_bd from '../../assets/flag-BD.png';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useEffect, useState } from 'react';
import Documents from '../Migration/Documents';
import { add_new_admission } from '../../Utils/method';
import OTPVerification from '../Migration/OTPVerification';
import Message from '../Migration/Message';
import pdf from '../../assets/pdf.svg'

const DegreeOptions = [
    { value: 'undergraduate', label: 'Undergraduate' },
    { value: 'postgraduateTaught', label: 'Postgraduate Taught' },
    { value: 'postgraduateResearch', label: 'Postgraduate Research' },
]
const sessionOptions = [
    { value: 'September 2023', label: 'September 2023' },
    { value: 'December 2023', label: 'December 2023' },
    { value: 'January 2024', label: 'January 2024' },
    { value: 'July 2024', label: 'July 2024' },
    { value: 'September 2024', label: 'September 2024' },
    { value: 'December 2024', label: 'December 2024' },
]

const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',
        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px'
    }),
    input: (baseStyles, state) => ({
        ...baseStyles,
        color: '#012965',
        height: '18px',
        padding: '0px',
        marginTop: '0px',
        marginBottom: '0px',
        fontSize: '14px'
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
        height: '18px'
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 6px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'

    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
    }),
    valueContainer: (baseStyles) => ({
        ...baseStyles,
        padding: '0px'
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    }),
    multiValue: (styles, { data }) => {
        // const color = chroma(data.color);
        return {
            ...styles,
            backgroundColor: '#ced4da',
            borderRadius: '0px',
            fontWeight: '200',
            fontFamily: 'Poppins',
            height: '18px',
            lineHeight: '13px',
            fontSize: '14px'
        };
    },
    multiValueLabel: (styles, { data }) => ({
        ...styles,
        color: data.color,
    }),
    multiValueRemove: (styles, { data }) => ({
        ...styles,
        color: data.color,
        ':hover': {
            backgroundColor: data.color,
            color: 'white',
        },
    }),
}
const sessionStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',

        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px',
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(1,41,101,1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 6px',
        width: '100%',
        color: 'rgba(1,41,101,1)',
    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
        overflow: 'scroll',
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(1,41,101,1)',
    })
}

const Admission = ({ universityOptions }) => {
    const [terms, setTerms] = useState(false);
    const [doc_popup, setDoc_popup] = useState(false);
    const [loader, setLoader] = useState(false);
    const [page_state, setPage_state] = useState(1);


    const handleCheckboxChange = (e) => {
        const { checked } = e.target;
        setTerms(checked)
    };

    const [selected_varsities, setSelected_varsities] = useState([]);
    const handleUniversitySelect = (selectedOption) => {
        if (selectedOption.length > 5) {
        } else {
            let data = []
            setSelected_varsities(selectedOption)
            for (const iterator of selectedOption) {
                data.push(iterator.value)
            }
            setForm_data({
                ...form_data,
                university_name: data
            });
        }
    };

    useEffect(() => {
        console.log(175, selected_varsities);
    }, [selected_varsities])

    const handleSelect = (data, type) => {
        setForm_data({
            ...form_data,
            [type]: data ? data.value : '',
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === 'mobile') {
            if (value !== '0') {
                if (value.length <= 10 && /^\d*$/.test(value)) {
                    setForm_data({
                        ...form_data,
                        [name]: value,
                    });
                }
            }
        } else {
            setForm_data({
                ...form_data,
                [name]: value,
            });
        }
    };

    const [form_data, setForm_data] = useState({
        fullName: '',
        university_name: '',
        degree: '',
        course_title: '',
        start_session: '',
        mobile: '',
        email: '',
        doc_passport: { name: '', image: '', url: '' },
        doc_nid: { name: '', image: '', url: '' },
        doc_last_degree: { name: '', image: '', url: '' },
        doc_birth_certificate: { name: '', image: '', url: '' },
        doc_cv: { name: '', image: '', url: '' },
    });

    const reset_form = () => {
        setForm_data({
            fullName: '',
            university_name: '',
            degree: '',
            course_title: '',
            start_session: '',
            mobile: '',
            email: '',
            doc_passport: { name: '', image: '', url: '' },
            doc_nid: { name: '', image: '', url: '' },
            doc_last_degree: { name: '', image: '', url: '' },
            doc_birth_certificate: { name: '', image: '', url: '' },
            doc_cv: { name: '', image: '', url: '' },
        })
        setSelected_varsities([])
    }

    const validateEmail = (input) => {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(input);
    };

    // useEffect(() => {
    //     let state = false;
    //     if (form_data.fullName.trim() === '') {
    //         state = false
    //     } else if (form_data.university_name === '' || form_data.university_name.length === 0) {
    //         state = false
    //     } else if (form_data.degree === '') {
    //         state = false
    //     } else if (form_data.course_title === '') {
    //         state = false
    //     } else if (form_data.start_session === '') {
    //         state = false
    //     } else if (form_data.mobile === '') {
    //         state = false
    //     } else if (form_data.email === '' || !validateEmail(form_data.email)) {
    //         state = false
    //     } else if (form_data.doc_passport === '') {
    //         state = false
    //     } else if (form_data.doc_nid === '') {
    //         state = false
    //     } else if (form_data.doc_last_degree === '') {
    //         state = false
    //     } else if (form_data.doc_birth_certificate === '') {
    //         state = false
    //     } else if (form_data.doc_cv === '') {
    //         state = false
    //     } else {
    //         state = true
    //     }
    //     console.log(form_data);
    //     setButtonState(state)
    // }, [form_data])

    const [verification, setVerification] = useState(false)
    const [application_id, setApplication_id] = useState('');
    const [success, setSuccess] = useState(false)
    const [assessmentForm, setAssessmentForm] = useState(false)

    const [requiredFields, setRequiredFields] = useState([]);
    const [email_error, setEmail_error] = useState('');
    const [phone_error, setPhone_error] = useState('');

    function findEmptyKeys(obj) {
        const emptyKeys = [];
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (key === 'doc_passport' || key === 'doc_nid' || key === 'doc_last_degree' || key === 'doc_birth_certificate' || key === 'doc_cv') {
                    // if (value.url === '') {
                    //     emptyKeys.push(key);
                    // }
                } else {
                    if (value === '') {
                        emptyKeys.push(key);
                        if (key === 'email') {
                            setEmail_error('')
                        } else if (key === 'mobile') {
                            setPhone_error('')
                        }
                    } else {
                        if (key === 'email' && !validateEmail(value)) {
                            setEmail_error('Enter valid email')
                            emptyKeys.push(key);
                        } else if (key === 'mobile' && value.length < 10) {
                            setPhone_error('Enter valid mobile')
                            emptyKeys.push(key);
                        } else {
                            setEmail_error('')
                            setPhone_error('')
                        }
                    }
                }
            }
        }
        setRequiredFields(emptyKeys)
        return emptyKeys;
    }

    const onSubmitApplication = async () => {
        try {
            const emptyKeys = findEmptyKeys(form_data);
            console.log(304, emptyKeys);

            if (emptyKeys.length > 0) {
                setPage_state(1)
                return false;
            } else {
                if (page_state === 1) {
                    setPage_state(2)
                    return false;
                }
            }

            setLoader(true)

            let full = form_data.fullName.split(' ')
            let first_name = full[0]
            let last_name = full.slice(1)

            let json = {
                first_name: first_name,
                last_name: last_name.toString(),
                degree: form_data.degree,
                start_session: form_data.start_session,
                university_name: form_data.university_name,
                course_title: form_data.course_title,
                mobile: '+880' + form_data.mobile,
                email: form_data.email,
                doc_passport: form_data.doc_passport.url,
                doc_nid: form_data.doc_nid.url,
                doc_last_degree: form_data.doc_last_degree.url,
                doc_birth_certificate: form_data.doc_birth_certificate.url,
                doc_cv: form_data.doc_cv.url,
            }

            setVerification(true);
            let apiRes = await add_new_admission(json);
            console.log(449, apiRes);
            if (apiRes.success) {
                setApplication_id(apiRes.data.application_id)
                if (page_state === 3) {
                    setPage_state(4)
                }
            }
            setLoader(false)
        } catch (error) {
            setLoader(false)
            console.error(415, 'Error submitting form:');
        }
    }

    return (
        <div className={styles.rightContainerInner}>
            {page_state === 1 &&
                <div className={styles.rightTopText}>
                    <p className={styles.rightTopinnertext}>find your dream University in the UK</p>
                    <p className={styles.rightTopinnertext2}>Let's start NOW</p>
                </div>
            }
            {page_state === 3 &&
                <div className={styles.rightTopText}>
                    <p className={styles.rightTopinnertext2} style={{ textAlign: 'left' }}>INFORMATION PREVIEW</p>
                </div>
            }
            {page_state === 1 &&
                <div className={styles.formContainer}>
                    <div className={styles.inputGroup}>
                        <label label className={styles.inputLabel}> {requiredFields.indexOf('fullName') > -1 && <span className={styles.requiredLabel}>*required</span>} Full Name</label>
                        <input className={classNames(styles.input, styles.inputFull, requiredFields.indexOf('fullName') > -1 && styles.error_input)} value={form_data.fullName} name='fullName' onChange={handleInputChange} type='text' placeholder='Enter your full name' autoFocus />
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('university_name') > -1 && <span className={styles.requiredLabel}>*required</span>} University</label>
                        <CreatableSelect
                            className={classNames(styles.university_dropDown, requiredFields.indexOf('university_name') > -1 && styles.error_input)}
                            onChange={handleUniversitySelect}
                            isMulti
                            isSearchable
                            isClearable
                            placeholder='Select University'
                            value={selected_varsities}
                            options={[{ value: 'Any University', label: 'Any University' }, ...universityOptions]}
                            styles={selectStyles}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#0030192d',
                                    primary: 'rgba(0, 48, 25, .75)',
                                },
                            })}
                            classNames={styles.customSelect}
                            classNamePrefix={styles.customSelect}
                        />
                    </div>

                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('degree') > -1 && <span className={styles.requiredLabel}>*required</span>} Degree</label>
                        <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('degree') > -1 && styles.error_input)}
                            defaultValue={''}
                            isSearchable={true}
                            placeholder='Select Degree'
                            name='degree'
                            onChange={(e) => handleSelect(e, 'degree')}
                            options={DegreeOptions}
                            styles={selectStyles}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#0030192d',
                                    primary: 'rgba(0, 48, 25, .75)',
                                },
                            })}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('course_title') > -1 && <span className={styles.requiredLabel}>*required</span>} Course Title</label>
                        <input className={classNames(styles.input, requiredFields.indexOf('course_title') > -1 && styles.error_input)} value={form_data.course_title} name='course_title' onChange={handleInputChange} type='text' placeholder='Your Course Title' />
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('start_session') > -1 && <span className={styles.requiredLabel}>*required</span>} Start Session</label>
                        <Select className={classNames(styles.dropDownMiddle, requiredFields.indexOf('start_session') > -1 && styles.error_input)}
                            defaultValue={''}
                            isSearchable={true}
                            placeholder='Select Session'
                            name='start_session'
                            onChange={(e) => handleSelect(e, 'start_session')}
                            options={sessionOptions}
                            styles={sessionStyles}
                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 0,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#0030192d',
                                    primary: 'rgba(0, 48, 25, .75)',
                                },
                            })}
                        />
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('mobile') > -1 && <span className={styles.requiredLabel}>*required</span>} Mobile</label>
                        <div className={styles.logoNumber}>
                            <img src={flag_bd} alt='' />
                            <p>+880</p>
                        </div>
                        <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('mobile') > -1 || phone_error !== '') && styles.error_input)} maxLength={10} value={form_data.mobile} name='mobile' onChange={handleInputChange} type='number' placeholder='Enter your Mobile number' />
                        {phone_error !== '' && <p className={styles.error_label}>{phone_error}</p>}
                    </div>
                    <div className={styles.inputGroup}>
                        <label className={styles.inputLabel}> {requiredFields.indexOf('email') > -1 && <span className={styles.requiredLabel}>*required</span>} Email</label>
                        <input className={classNames(styles.input, styles.inputFull, (requiredFields.indexOf('email') > -1 || email_error !== '') && styles.error_input)} value={form_data.email} name='email' onChange={handleInputChange} type='text' placeholder='Enter your email' />
                        {email_error !== '' && <p className={styles.error_label}>{email_error}</p>}
                    </div>
                    {/* <div className={styles.inputGroup}>
                    <label className={styles.inputLabel}>
                        {(requiredFields.indexOf('doc_passport') > -1 || requiredFields.indexOf('doc_nid') > -1 || requiredFields.indexOf('doc_last_degree') > -1 || requiredFields.indexOf('doc_birth_certificate') > -1 || requiredFields.indexOf('doc_cv') > -1) &&
                            <span className={styles.requiredLabel}>*required</span>
                        }
                        Documents</label>
                    {(form_data.doc_passport.url !== '' || form_data.doc_nid.url !== '' || form_data.doc_last_degree.url !== '' || form_data.doc_birth_certificate.url !== '' || form_data.doc_cv.url !== '') &&
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', columnGap: '10px', margin: '6px 0 8px' }}>
                            {form_data.doc_passport.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_passport.image} alt='' /></div>}
                            {form_data.doc_nid.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_nid.image} alt='' /></div>}
                            {form_data.doc_last_degree.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_last_degree.image} alt='' /></div>}
                            {form_data.doc_birth_certificate.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_birth_certificate.image} alt='' /></div>}
                            {form_data.doc_cv.image !== '' && <div className={styles.uploaded_doc}> <img src={pdf} alt='' /></div>}
                        </div>
                    }
                    {form_data.doc_passport.url !== '' && form_data.doc_nid.url !== '' && form_data.doc_last_degree.url !== '' && form_data.doc_birth_certificate.url !== '' && form_data.doc_cv.url !== '' ?
                        <label className={styles.input} onClick={() => setDoc_popup(true)} style={{ backgroundColor: '#012965', display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                            <i style={{
                                transform: 'rotate(45deg)',
                                fontSize: '22px',
                                marginTop: '-1px',
                                color: '#fff'
                            }}
                                className="fa-thin fa-paperclip"
                            ></i>

                            <span style={{
                                width: 'calc(100% - 25px)',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                                color: '#fff'
                            }}>
                                5 documents uploaded
                            </span>
                        </label>
                        :
                        <label className={
                            classNames(styles.input, (requiredFields.indexOf('doc_passport') > -1 || requiredFields.indexOf('doc_nid') > -1 || requiredFields.indexOf('doc_last_degree') > -1 || requiredFields.indexOf('doc_birth_certificate') > -1 || requiredFields.indexOf('doc_cv') > -1) && styles.error_input)
                        }
                            onClick={() => setDoc_popup(true)} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', cursor: 'pointer' }}>
                            <i style={{
                                transform: 'rotate(45deg)',
                                fontSize: '22px',
                                marginTop: '-1px',
                            }}
                                className="fa-thin fa-paperclip"
                            ></i>

                            <span style={{
                                width: 'calc(100% - 25px)',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                overflow: 'hidden'
                            }}>
                                Upload your documents
                            </span>
                        </label>
                    }
                </div>
                <div className={styles.inputGroupTerms}>
                    <div className={styles.termsGroup}>
                        <input type='checkbox' id='termsAccepted' onChange={handleCheckboxChange} name='termsAccepted' />
                        <label className={styles.input_label} htmlFor='termsAccepted' style={{ position: 'unset' }}> Accepting terms & conditions</label>
                    </div>
                </div> */}
                    <div className={styles.submitBtn} style={{ marginTop: '10px' }}>
                        <button type='submit' onClick={onSubmitApplication}> SUBMIT </button>
                    </div>
                </div>
            }
            {page_state === 3 &&
                <div className={classNames(styles.formContainer, styles.info_section)}>
                    <div className={styles.info_container}>
                        <p className={styles.info_item}>FULL NAME : {form_data.fullName}</p>
                        <p className={styles.info_item}>UNIVERSITY : {form_data.university_name}</p>
                        <p className={styles.info_item}>DEGREE : {form_data.degree}</p>
                        <p className={styles.info_item}>COURSE TITLE : {form_data.course_title}</p>
                        <p className={styles.info_item}>START SESSION : {form_data.start_session}</p>
                        <p className={styles.info_item}>MOBILE : {form_data.mobile}</p>
                        <p className={styles.info_item}>EMAIL : {form_data.email}</p>
                        <p className={styles.info_item}>DOCUMENTS : </p>

                        <div className={styles.documents_container}>
                            {form_data.doc_passport.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_passport.image} alt='' /></div>}
                            {form_data.doc_nid.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_nid.image} alt='' /></div>}
                            {form_data.doc_last_degree.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_last_degree.image} alt='' /></div>}
                            {form_data.doc_birth_certificate.image !== '' && <div className={styles.uploaded_doc}> <img src={form_data.doc_birth_certificate.image} alt='' /></div>}
                            {form_data.doc_cv.image !== '' && <div className={styles.uploaded_doc}> <img src={pdf} alt='' /></div>}
                        </div>
                    </div>
                    <div className={styles.inputGroupTerms}>
                        <div className={styles.termsGroup}>
                            <input type='checkbox' id='termsAccepted' onChange={handleCheckboxChange} name='termsAccepted' />
                            <label className={styles.input_label} htmlFor='termsAccepted' style={{ position: 'unset' }}> Accepting terms & conditions</label>
                        </div>
                    </div>
                    <div className={styles.submitBtn} style={{ marginTop: '10px' }}>
                        <button type='submit' style={terms ? {} : { opacity: '0.6', pointerEvents: 'none' }} onClick={onSubmitApplication} > SUBMIT </button>
                    </div>
                </div>
            }
            <div className={styles.rightBottomText}>
                <p className={styles.rightBottominnertext}>
                    Our dedicated support team will contact you to ensure that you can focus on your studies with peace of mind, knowing you have a comfortable & conducive living environment, along with a part-time job & pocket money.
                </p>
            </div>

            {page_state === 2 && <Documents setPage_state={setPage_state} form_data={form_data} setForm_data={setForm_data} />}
            {page_state === 4 && <OTPVerification type={'admission'} email={form_data.email} setPage_state={setPage_state} reset_form={reset_form} application_id={application_id} />}
            {page_state === 5 && <Message setPage_state={setPage_state} />}
        </div>
    )
}

export default Admission;