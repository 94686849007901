/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { AddToCart, add_favourite_item, remove_favourite_item, getUserData } from "../Utils/method";
import { useDispatch, useSelector } from "react-redux";
import { useCart } from "react-use-cart";
import styles from '../assets/css/Item.module.css';
import classNames from 'classnames';
import { addWeights, addParcentage } from '../Utils/common';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

function AddButton(props) {
    const user_data = getUserData({ type: 'get' })
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { addItem, inCart, items, updateItemQuantity } = useCart();
    const stores = useSelector((state) => state.rootReducer.stores);
    const city = useSelector((state) => state.rootReducer.city);
    const country = useSelector((state) => state.rootReducer.country);
    const favourite_items = useSelector((state) => state.rootReducer.favourite_items);
    const allfavourite_items = useSelector((state) => state.rootReducer.allfavourite_items);

    useEffect(() => {
        const totalWeight = addWeights(items);
        const totalParcentage = addParcentage(items);
        // console.log(25, totalWeight, totalParcentage);
        // console.log(25, items);
        dispatch({ type: "set_cart_total_weight", payload: totalWeight })
        dispatch({ type: "set_cart_total_parcentage", payload: totalParcentage })
    }, [items])

    const throughToast = (type) => {
        if (type === 'plus') {
            toast.success('Item added to your cart.', { autoClose: 1000 });
        } else {
            toast.success('Item removed from your cart.', { autoClose: 1000 });
        }
    }

    const addtoCartthisItem = async (id, q, type) => {
        try {
            const product_details = stores.filter(v => v._id === props.data.store_id)[0].products.filter(v => v._id === props.data.product_id)[0]
            const store_details = stores.filter(v => v._id === props.data.store_id)[0]
            if (q === 'initial') {
                addItem({ ...props.data, id: props.data._id, price: props.data.price, product_details: product_details })
            } else {
                updateItemQuantity(id, q)
            }
            throughToast(type)
            await AddToCart({ items: items.filter(v => v.id !== props.data._id), item: props.data, store_details, product_details, city, country, q: q === 'initial' ? 1 : q })
        } catch (error) {
            toast.error(error.status_phrase, { autoClose: 1000 });
            console.log(error);
        }
    }

    const addTo_favourite = async (v) => {
        if (user_data) {
            try {
                let data = {
                    user_id: user_data._id,
                    item_id: v._id,
                }
                let apires = await add_favourite_item(data)
                console.log(67, apires.favourite_items);
                if (apires.success) {
                    dispatch({ type: "set_favourite_items", payload: apires.favourite_items })
                    toast.success('Item added to your favourite list.', { autoClose: 1000 });
                }
            } catch (error) {
                console.log(error);
            }
        } else {
            navigate('/sign-in')
        }
    }

    const removeFavourite = async (v) => {
        console.log(v);
        try {
            let data = {
                user_id: user_data._id,
                item_id: v._id,
            }
            let apires = await remove_favourite_item(data);
            console.log(53, apires);
            if (apires.success) {
                let d = allfavourite_items.filter(f => f._id !== v._id)
                let d2 = favourite_items.filter(f => f !== v._id)
                dispatch({ type: "set_allfavourite_items", payload: d });
                dispatch({ type: "set_favourite_items", payload: d2 });
                toast.success('Item removed from your favourite list.', { autoClose: 1000 });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            {inCart(props.data._id) ? items.filter(k => k.id === props.data._id).map(v =>
                props.theme === 'cart' ?
                    <div className={classNames(styles.quantity_group_update)} key={v._id}>
                        <div className={styles.cartBtnGroup}>
                            <span className={styles.min_btn} onClick={() => v.quantity > 1 ? addtoCartthisItem(v.id, Number(Number(v.quantity) - 1), 'minus') : ''}>
                                <i className="fa-light fa-minus"></i>
                            </span>
                            <input type={'number'} min={1} value={v.quantity} onChange={(e) => addtoCartthisItem(v.id, e.target.value)} />
                            <span className={styles.max_btn} onClick={() => addtoCartthisItem(v.id, Number(Number(v.quantity) + 1), 'plus')}>
                                <i className="fa-light fa-plus"></i>
                            </span>
                        </div>

                        <span className={styles.removeItem} onClick={() => addtoCartthisItem(v.id, 0, 'minus')}>
                            <i className="fa-thin fa-trash-can"></i>
                        </span>
                    </div>
                    : props.theme === 'search' ?
                        <span className={styles.srItemAddButton} onClick={() => addtoCartthisItem(v.id, v.quantity + 1, 'plus')} style={{ backgroundColor: '#ff0000', color: '#fff' }} key={v._id}>
                            <i className="fa-light fa-plus"></i>
                        </span>
                        :
                        <div className={styles.product_cart + ' ' + styles[props.theme]} key={v._id}>
                            <span className={styles.quantity_group} style={!props.data.is_item_in_stock ? { borderTop: '1px solid rgba(255, 0, 0, 0.2)' } : {}}>
                                <span className={styles.min_btn} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} onClick={() => addtoCartthisItem(v.id, v.quantity - 1, 'minus')}>
                                    <i className="fa-light fa-minus"></i>
                                </span>
                                <span className={styles.quantity} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} >{v.quantity}</span>
                                <span className={styles.max_btn} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} onClick={() => addtoCartthisItem(v.id, v.quantity + 1, 'plus')}>
                                    <i className="fa-light fa-plus"></i>
                                </span>
                            </span>
                            {favourite_items.indexOf(v._id) === -1 ?
                                <button className={styles.wishButton} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} type='submit' onClick={() => addTo_favourite(v)}>
                                    <i style={{ color: '#ff0000' }} className="fa-thin fa-heart"></i>
                                </button>
                                :
                                <button className={styles.wishButton} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} type='submit' onClick={() => removeFavourite(v)}>
                                    <i style={{ color: '#ff0000', fontWeight: 600 }} className="fa-thin fa-heart"></i>
                                </button>
                            }
                        </div>
            )
                :
                props.theme === 'search' ?
                    <span className={styles.srItemAddButton} onClick={() => addtoCartthisItem(props.data._id, 'initial', 'plus')}>
                        <i className="fa-light fa-plus"></i>
                    </span>
                    :
                    <div className={styles.product_cart + ' ' + styles[props.theme]} >
                        <button className={classNames(styles.addButton, 'addButton')} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} type='submit' onClick={() => addtoCartthisItem(props.data._id, 'initial', 'plus')}> <span className={styles.basketIcon}><i className="fa-light fa-bag-shopping"></i></span> ADD</button>
                        {favourite_items.indexOf(props.data._id) === -1 ?
                            <button className={styles.wishButton} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} type='submit' onClick={() => addTo_favourite(props.data)}>
                                <i style={{ color: '#ff0000' }} className="fa-thin fa-heart"></i>
                            </button>
                            :
                            <button className={styles.wishButton} style={!props.data.is_item_in_stock ? { pointerEvents: 'none', backgroundColor: 'rgb(1 41 101 / 10%)' } : {}} type='submit' onClick={() => removeFavourite(props.data)}>
                                <i style={{ color: '#ff0000', fontWeight: 600 }} className="fa-thin fa-heart"></i>
                            </button>}
                    </div>
            }
        </>
    )
}

export default AddButton;