/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
// import { Document, Page } from 'react-pdf';
import styles from '../../../assets/css/DocumentPreview.module.css'
import { Document, Page, pdfjs } from 'react-pdf';
import daraz from '../../../assets/content-for-left-section-20230822.pdf'
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const DocumentPreview = ({ setDocument, _file, set_File }) => {
    const [numPages, setNumPages] = useState();
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }
    const [doc_type, setDoc_type] = useState('');
    useEffect(() => {
        console.log(_file.split('.')[_file.split('.').length - 1]);
        setDoc_type(_file.split('.')[_file.split('.').length - 1])
    }, [])

    return (
        <div className={styles.preview_container}>
            <div className={styles.preview_header}>
                <h3>Document Preview</h3>
                <span className={styles.closeCart} onClick={() => { setDocument(false); set_File('') }}><i className="fa-thin fa-xmark"></i></span>
            </div>
            <div className={styles.preview_body}>
                {doc_type === 'pdf' ?
                    <div className={styles.pdf_section}>
                        <Document file={daraz} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} />
                        </Document>
                        <p className={styles.page_number}>
                            Page {pageNumber} of {numPages}
                        </p>
                    </div>
                    :
                    <div className={styles.img_section}>
                        <img src={_file} alt='' />
                    </div>
                }
            </div>
        </div>
    )
}

export default DocumentPreview;