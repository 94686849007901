import React from 'react'
import CurrencyFormat from 'react-currency-format';
// import { IoBagSharp } from 'react-icons/io5';
import styles from '../../../assets/css/Item.module.css'
import { getCurrencySign, alternateCurrency, priceConvertToAlternate, rate_convr_GBPtoBDT } from '../../../Utils/common'
import classNames from 'classnames';
import { convertCurrency } from '../../../Utils/currencyConverter';
import { _domain } from '../../../Config/Domains';

export default function ItemPrice(props) {

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }
    return (
        props.activeDelivery !== undefined ?
            props.type === 'item' || props.type === 'sitem' ?
                <div className={classNames(styles.product_price, props.type === 'sitem' ? 'singleItem_price' : '')} >
                    {/* <p>{convertCurrency(props.data.price, 'BDT')}</p> */}
                    <CurrencyFormat value={Number(props.data.price).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(_domain().currency) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                    <CurrencyFormat value={priceConvertToAlternate(props.data.price, 'GBP', rate_convr_GBPtoBDT(props.activeDelivery.conversion_rate))} displayType={'text'} thousandSeparator={true} prefix={alternateCurrency(_domain().currency) + ' '} renderText={value => <p className={styles.bd_price}>{value}</p>} />
                    {props.offer_text !== '' && props.data.offer_percentage !== 'custom1' && props.data.offer_percentage !== 'custom2' ?
                        <p className={classNames(styles.prev_price, props.type === 'sitem' ? 'singleItem_prevPrice' : '')} ><span> WAS </span> <span> <CurrencyFormat value={props.previousPrice(props.data.price, Number(props.data.offer_percentage))} displayType={'text'} thousandSeparator={true} prefix={'£ '} renderText={v => v} /> </span></p>
                        : ''}
                    <p className={classNames(styles.deliveryTime, props.type === 'sitem' ? 'singleItem_deliveryTime' : '')} > Excl. shipping & duties. </p>
                </div>
                : ''
            : ''
    )
}
