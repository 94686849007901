import React, { useEffect } from 'react'
import styles from '../../assets/css/Migration.module.css';
import students from '../../assets/Students.png';
import classNames from 'classnames';
import { useState } from 'react';
import Select from 'react-select';
import { add_new_job_application, get_item_from_product } from '../../Utils/method';
import flag_bd from '../../assets/flag-BD.png';
import OTPVerification from './OTPVerification';
import Message from './Message';
import Documents from './Documents';
import Jobs from './Jobs';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import pdf from '../../assets/pdf.svg'
import JobApplication from './JobApplication';
import Loader from '../Loader';

const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        '&:focus': {
            border: '0 !important',
        },
        border: 'none',
        background: 'transparent',
        minHeight: '18px'
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    })
    ,
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 15px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'
    })
    ,
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',

    })
    ,
    multiValue: (base) => ({
        ...base,
        background: '#ced4da',
        borderRadius: '0px',
        marginTop: '0px',
        fontWeight: '400',
        lineHeight: '18px',
        position: 'relative',
        top: '-2px',
        left: '-10px'
    }),
    singleValue: (baseStyles) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.9)',
    })
}

const IeltsOptions = [
    { value: '4.0', label: '4.0' },
    { value: '4.5', label: '4.5' },
    { value: '5.0', label: '5.0' },
    { value: '5.5', label: '5.5' },
    { value: '6.0', label: '6.0' },
    { value: '6.5', label: '6.5' },
    { value: '7.0', label: '7.0' },
    { value: '7.5', label: '7.5' },
    { value: '8.0', label: '8.0' },
    { value: '8.5', label: '8.5' },
    { value: '9.0', label: '9.0' },
    { value: 'Not Available', label: 'Not Available' },
]

const SalaryOptions = [
    { value: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}`, label: `BDT ${(200000).toLocaleString('en-IN')} - BDT ${(300000).toLocaleString('en-IN')}` },
    { value: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}`, label: `BDT ${(300000).toLocaleString('en-IN')} - BDT ${(500000).toLocaleString('en-IN')}` },
    { value: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}`, label: `BDT ${(500000).toLocaleString('en-IN')} - BDT ${(700000).toLocaleString('en-IN')}` },
    { value: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}`, label: `BDT ${(700000).toLocaleString('en-IN')} - BDT ${(1000000).toLocaleString('en-IN')}` },
    { value: 'other', label: 'Other' },
]

const getCurrentDomain = () => {
    const hostname = window.location.hostname;
    const domainParts = hostname.split('.');
    // console.log('domainParts:', domainParts[0]);
    return domainParts[0];
};

const currentDomain = getCurrentDomain();

function Migration() {
    const [loader, setLoader] = useState(false)
    const [all_jobs, setAll_jobs] = useState([]);
    const [expand, setExpand] = useState([])
    const [current_job, setCurrent_job] = useState(null);
    const [job_application, setJob_application] = useState(false);

    useEffect(() => {
        let _isMounted = true;

        async function init() {
            setLoader(true)
            try {
                let apires = await get_item_from_product({ product_id: '64e5c601dbc86d3553601259' })
                if (_isMounted) {
                    console.log('get_item_from_product:', apires.items);
                    const reversedArray = apires.items.slice().reverse();
                    setAll_jobs(reversedArray)
                }
                setLoader(false)
            } catch (error) {
                setLoader(false)
                console.log(error);
            }
        }
        init()

        return () => {
            _isMounted = false
        }
    }, [])

    const manageExpand = (id) => {
        if (expand.indexOf(id) > -1) {
            setExpand(prev => prev.filter(f => f !== id))
        } else {
            setExpand(prev => [...prev, id])
        }
    }

    const applyFun = (d) => {
        console.log(d);
        setCurrent_job(d)
        setJob_application(true)
    }

    const format_date = (d) => {
        const date = new Date(d);
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-US', options);
        return formattedDate;
    }

    const compare_date = (d) => {
        const targetDate = new Date(d);
        const currentDate = new Date();

        if (currentDate < targetDate) {
            console.log(11);
            return true
        } else if (currentDate > targetDate) {
            console.log(22);
            return false
        } else {
            console.log(33);
            return true
        }
    }
    const inputs = document.querySelectorAll('input[type="text"], input[type="number"]');

    inputs.forEach(function (input) {
        input.addEventListener('input', function () {
            const inputValue = input.value.trim();
            if (inputValue !== '') {
                input.style.backgroundColor = '#F5FAFF';
            } else {
                input.style.backgroundColor = '#fff';
            }
        });
    });
    const gotoTop = (id) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }
    }

    return (
        <div className={styles.mainContainer}>
            <ToastContainer />
            <div className={styles.leftContainer}>
                <div className={styles.containerInner}>
                    <div className={styles.logoText}>
                        <div className={styles.ukbdtext}>
                            <p className={styles.title}>BECOME A BRITISH CITIZEN</p>
                            <p className={styles.sectitle}>Turn your dreams into reality!</p>
                        </div>
                    </div>
                    <Link to={'/application?form_id=2312170006'} className={styles.sponsorBtn}>Apply for Sponsorship</Link>
                    <div className={styles.firstPageContent}>
                        <div className={styles.topText}>
                            <p className={styles.topinnertext}>Brexit has created new opportunities for individuals from countries outside the EU, including Bangladeshi talents & job seekers.</p>
                        </div>
                        <div className={styles.imageBox}>
                            <img src={students} className={styles.flag} alt="flag-UK" />
                        </div>
                        <div className={styles.bottomText}>
                            <p className={styles.bottominnertext}>
                                Getting a job in <strong> London </strong> & British <strong> citizenship </strong> is a significant step towards building a <strong> bright future </strong> for you & your family. It offers many benefits, rights, & opportunities that could make a <strong> positive impact </strong> on your profession, future, & social life.
                            </p>
                        </div>
                    </div>

                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Get a Job in London</p>
                        <p className={classNames(styles.section_para)}>
                            Bangladeshi graduates can grab the opportunity to build
                            their careers in the UK, a nation abundant with hundreds
                            and thousands of job openings. Nonetheless, employers
                            encounter difficulties in filling various positions, particularly
                            in roles like Chefs, Developers, Teachers, Engineers, and
                            Nurses. Hence, if you possess the required skills,
                            immigrating to the UK and applying for a job could be a
                            viable option. We suggest considering career paths in fields
                            such as IT, Accounting, Finance, Banking, Engineering,
                            Education, Marketing, Healthcare, Recruitment, and HR, as
                            these represent some of the leading job sectors in the UK.
                        </p>
                        <p className={styles.section_para}>
                            Our team of experienced immigration consultants, lawyers, and
                            professionals is here to assist anyone navigating the complicated
                            UK immigration and visa systems. Rest assured that we are
                            committed to working diligently to achieve the desired outcome
                            for your immigration concerns.
                        </p>
                    </div>
                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Benefits of UK Tier 2 VISA</p>
                        <p className={styles.section_para}>
                            A Tier 2 visa is a visa type that permits non-EEA (European Economic Area) nationals to
                            work in the UK. This visa is intended for skilled workers who have received a job offer in
                            the UK and possess a valid Certificate of Sponsorship (CoS) from a UK employer
                            holding a licensed Tier 2 sponsorship.
                        </p>
                        <p className={styles.section_para}>
                            The Tier 2 visa is categorised into four distinct types: General, Intra-Company Transfer,
                            Minister of Religion, and Sportsperson.
                        </p>
                        <p className={styles.section_para}>
                            Holding a Tier 2 visa comes with various advantages. Firstly, it permits an individual to
                            both reside and work in the UK, offering a chance to accumulate valuable work
                            experience and earn a salary. Moreover, it serves as a route towards achieving
                            permanent residency and eventually, citizenship, making it particularly enticing for those
                            aiming to establish a long-term presence in the UK. Additionally, the visa allows you to
                            bring your dependents, including spouses, civil partners, and children under 18 years
                            old, who can also engage in work or studies within the UK.
                        </p>
                        <p className={styles.section_para}>
                            The UK's job market is characterised by its competitiveness and diversity, spanning
                            across numerous industries such as finance, technology, healthcare, and education.
                            The nation boasts a robust economy, with London recognised as one of the world's
                            premier financial hubs. However, the job market has faced increased competition in
                            recent years due to factors like Brexit and the repercussions of the COVID-19
                            pandemic, which have led to job losses within certain sectors. Nonetheless, numerous
                            opportunities remain available for skilled workers, and the UK continues to allure
                            international talent with its appealing prospects.
                        </p>
                    </div>
                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Eligibility Criteria</p>
                        <p className={styles.section_para}>
                            The Tier 2 (General) Visa is the most prevalent category within the Tier 2 visa
                            framework. It is tailored for skilled workers who have received a job offer in the UK and
                            possess a valid Certificate of Sponsorship (CoS) issued by a UK employer with an
                            accredited Tier 2 sponsorship. This visa remains valid for a span of up to five years and
                            is open to extension.
                        </p>
                        <p className={styles.section_para}>
                            To qualify for a Tier 2 visa, an individual needs to fulfil specific criteria, which
                            encompass:
                        </p>
                        <p className={styles.para_title}> <strong>Job Offer Requirements:  </strong> </p>
                        <p className={styles.section_para}>
                            A valid job offer from a UK employer possessing a Tier 2 sponsorship license is
                            essential. This offer should pertain to a skilled position that cannot be filled by a
                            resident worker
                        </p>
                        <p className={styles.para_title}> <strong>Skill Level Requirements:</strong> </p>
                        <p className={styles.section_para}>
                            The job offer must meet the stipulated skill level, typically equivalent to or above RQF
                            (Regulated Qualifications Framework) level 6. This implies that the job necessitates a
                            bachelor's degree or equivalent experience in any field.
                        </p>
                        <p className={styles.para_title}> <strong>English Language Proficiency:</strong> </p>
                        <p className={styles.section_para}>
                            Demonstrating English proficiency is crucial. This can be achieved by passing an
                            approved English language test or showcasing a degree taught in English.
                        </p>
                        <p className={styles.para_title}> <strong>Financial Capacity: </strong> </p>
                        <p className={styles.section_para}>
                            Demonstrating sound financial standing to support oneself without relying on public
                            funds is essential. The required amount varies based on individual circumstances,
                            including whether dependents are also brought along.
                        </p>
                    </div>
                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Application Process</p>
                        <p className={styles.para_title}> <strong>Prohibited Categories: </strong> </p>
                        <p className={styles.section_para}>
                            Individuals must not fall into any prohibited categories, such as possessing a criminal
                            record, prior deportation from the UK, or being deemed a threat to national security.
                            Meeting these criteria is pivotal to securing eligibility for a Tier 2 visa.
                        </p>
                        <p className={styles.section_para}>
                            The application process for a Tier 2 visa involves a series of steps:
                        </p>
                        <p className={styles.sub_section_para}>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 1:</strong></p>  <p className={styles.steps_para}>Secure a job offer from a UK employer holding a licensed Tier 2 sponsorship.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 2:</strong></p>  <p className={styles.steps_para}>Acquire a Certificate of Sponsorship (CoS) from your employer.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 3:</strong></p>  <p className={styles.steps_para}>Complete an online application form on the official UK government website.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 4:</strong></p>  <p className={styles.steps_para}>Furnish the necessary documents, including your passport, CoS, English language test results, and evidence of maintenance funds.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 5:</strong></p>  <p className={styles.steps_para}>Pay the visa fee, which can vary based on the duration of your intended stay.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 6:</strong></p>  <p className={styles.steps_para}>Schedule an appointment at a visa application centre to provide biometric data such as fingerprints and photographs.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 7:</strong></p>  <p className={styles.steps_para}>Attend an interview at the visa application centre if requested.</p></div>
                            <div className={styles.steps_items}><p className={styles.steps}> <strong>Step 8:</strong></p>  <p className={styles.steps_para}>Await a decision on your visa application.</p></div>
                        </p>
                        <p className={styles.section_para}>
                            The requisite documents and fees might vary according to your individual
                            circumstances, but they generally encompass:
                        </p>
                        <p className={styles.sub_section_para}>
                            <ul>
                                <li> Passport or travel document</li>
                                <li> Certificate of Sponsorship</li>
                                <li> English language proficiency test results</li>
                                <li> Proof of maintenance funds</li>
                                <li> Tuberculosis (TB) test results (if applicable)</li>
                                <li> Visa application fee</li>
                            </ul>
                        </p>
                        <p className={styles.section_para}>
                            The processing time for visas and the waiting period fluctuate based on the country
                            from which you're applying. Typically, the standard processing time for a Tier 2 visa is
                            around three weeks. However, expedited processing may be available at an additional
                            cost, leading to a faster decision. After your visa application is approved, you're free to
                            travel to the UK and commence your work.

                        </p>
                    </div>
                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Dependents & Family members</p>
                        <p className={styles.section_para}>
                            Dependents and family members of Tier 2 visa holders might have the opportunity to
                            accompany them in the UK. The eligibility criteria for these dependents and family
                            members are outlined as follows:
                        </p>
                        <p className={styles.section_para}>
                            Spouses or Partners: Both married and unmarried partners could qualify to join a Tier 2
                            visa holder in the UK.
                        </p>
                        <p className={styles.section_para}>
                            Children: Children under the age of 18 might also be eligible to accompany a Tier 2 visa
                            holder in the UK.
                        </p>
                        <p className={styles.section_para}>
                            For eligibility to join a Tier 2 visa holder in the UK, dependents and family members are
                            required to:
                        </p>
                        <p className={styles.sub_section_para}>
                            Be listed on the Tier 2 visa holder's visa application.
                            Present evidence substantiating their relationship with the Tier 2 visa holder.
                            Satisfy English language criteria, if applicable.
                            Have sufficient financial means to sustain themselves without relying on public
                            funds.
                        </p>
                        <p className={styles.section_para}>
                            The visa application procedure for dependents and family members closely resembles
                            that of the Tier 2 visa holder. They must complete an online application form, furnish
                            the necessary documents, pay the visa fee, and submit biometric information at a visa
                            application centre.
                        </p>
                        <p className={styles.section_para}>
                            Dependents and family members of Tier 2 visa holders possess the privilege to work
                            and study in the UK without constraints. Nonetheless, they must substantiate their
                            relationship with the Tier 2 visa holder and fulfil the applicable eligibility prerequisites for
                            work and study in the UK.
                        </p>
                    </div>
                    <div className={styles.pageSection}>
                        <p className={styles.section_title}>Visa Renewal & Citizenship</p>
                        <p className={styles.section_para}>
                            As your Tier 2 visa approaches its expiration, you may have the opportunity to apply for
                            a visa renewal. The requisites for visa renewal mirror those of the initial visa application,
                            encompassing the necessity for a valid Certificate of Sponsorship, adherence to skill
                            level and English language criteria, and the availability of adequate maintenance funds.
                        </p>
                        <p className={styles.section_para}>
                            Additionally, you'll need to furnish updated documents, such as a new CoS from your
                            employer and current bank statements.
                        </p>
                        <p className={styles.section_para}>
                            If you have resided and worked in the UK on a Tier 2 visa for a period of five years or
                            more, you might be eligible to apply for Indefinite Leave to Remain (ILR), commonly
                            referred to as Permanent Residency. To meet ILR qualifications, you must:
                        </p>
                        <p className={styles.sub_section_para}>
                            Have maintained an uninterrupted presence in the UK for a minimum of five
                            years on a Tier 2 visa.
                        </p>
                        <p className={styles.sub_section_para}>
                            Satisfy the applicable ILR eligibility prerequisites, including the skill level and
                            English language criteria.
                        </p>
                        <p className={styles.sub_section_para}>
                            Uphold a clean record devoid of any breaches of UK immigration regulations
                            during your stay in the UK.
                        </p>
                        <p className={styles.sub_section_para}>
                            Possess adequate maintenance funds.
                        </p>
                        <p className={styles.section_para}>
                            Upon obtaining ILR status, you can live and work in the UK without restrictions.
                            Moreover, you become eligible to seek British citizenship after holding ILR for a
                            minimum of one year. The perks of permanent residency encompass:
                        </p>
                        <p className={styles.sub_section_para}>
                            <p>The right to participate in voting.</p>
                            <p>Access to public funds.</p>
                            <p>Access to the NHS without the obligation to pay surcharges.</p>
                        </p>
                        <p className={styles.section_para}>
                            It's imperative to acknowledge that eligibility criteria and prerequisites for visa renewal
                            and ILR status may evolve over time. Consequently, it's advisable to regularly consult
                            the latest information on the official UK government website or consider seeking
                            professional guidance.
                        </p>
                    </div>

                </div>
            </div>
            <div className={styles.rightContainer} id='rightContainer'>
                {<Link to={'/migration/jobs'} className={styles.fullScreenView}>Full screen view <span><i className="fa-light fa-arrow-up-right-and-arrow-down-left-from-center"></i></span></Link>}
                <div className={styles.rightContainerInner}>
                    <div className={styles.rightTopText}>
                        <p className={styles.rightTopinnertext2}>
                            Let's start NOW
                        </p>
                        <p className={styles.rightTopinnertext}>
                            Find your dream JOB in the UK
                        </p>
                        <p className={styles.right_normal_text}>
                            Become a British citizen through a seamless 5-year Worker <br /> Visa Sponsorship facilitated by the UKBD marketplace.
                        </p>
                    </div>
                    <div className={styles.jobContainer}>
                        {loader ? <Loader />
                            :
                            all_jobs.map(v =>
                                <div className={styles.expandArea} key={v._id}>
                                    <div className={styles.expandAreaHead} onClick={() => manageExpand(v._id)} style={expand.indexOf(v._id) > -1 ? { backgroundColor: '#e5eae8' } : {}}>
                                        <div className={styles.exp_title}>
                                            <p className={styles.mainTitle}>{v.name.toString()} </p>
                                            <div className={styles.exp_title_right}>
                                                <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time === 'Per Annum' ? 'pa' : v.salary_time} ({v.location})</p>
                                                {/* {v.salary_range_end === '' || v.salary_range_end === 0 || v.salary_range_end === '0' || v.salary_range_end === '0.00' ?
                                                    <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} {v.salary_time} ({v.location})</p>
                                                    :
                                                    <p className={styles.price_range} style={{ marginBottom: '5px' }}>{'£ ' + Number(v.salary_range_start).toLocaleString()} to {'£ ' + Number(v.salary_range_end).toLocaleString()} {v.salary_time} ({v.location})</p>
                                                } */}
                                                <p className={styles.price_range}>Deadline: {format_date(v.deadline)}</p>
                                            </div>
                                        </div>
                                        <div className={styles.exp_icon}>
                                            {expand.indexOf(v._id) > -1 ? <i className="fa-light fa-minus"></i> : <i className="fa-light fa-plus"></i>}
                                        </div>
                                    </div>
                                    {expand.indexOf(v._id) > -1 &&
                                        <div className={styles.expandAreaBody}>
                                            <div className={styles.apply_section}>
                                                <button className={styles.apply_form_button} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }} onClick={() => applyFun(v)}>APPLY NOW</button>
                                            </div>
                                            {/* <div className={styles.intro_section}>
                                                <p className={styles.intro_item}> Published:  August 2023 </p>
                                                <p className={styles.intro_item}> Location: Bedfordshire, United Kingdom </p>
                                                <p className={styles.intro_item}> Category: IT Services </p>
                                                <p className={styles.intro_item}> Job Type:  Full-time, Permanent </p>
                                                <p className={styles.intro_item}> Salary:  From £ 45,000 To £ 60,000 Per Annum </p>
                                            </div>
                                            <p className={styles.para_title}>DESCRIPTION</p> */}
                                            <div className={styles.exp_para} dangerouslySetInnerHTML={{ __html: v.details }} />
                                            <div className={styles.apply_section}>
                                                <button className={styles.apply_form_button} style={compare_date(v.deadline) ? {} : { pointerEvents: 'none', opacity: '0.5' }} onClick={() => applyFun(v)}>APPLY NOW</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            )}
                    </div>
                </div>
            </div>
            <div className={styles.line1_and_line2_container}>
                <div className={styles.line1_vertical}></div>
                <div className={styles.line2_vertical}></div>
                <div className={styles.line_cursor_box} onClick={() => gotoTop('rightContainer')}></div>
            </div>

            {job_application && <JobApplication current_job={current_job} setJob_application={setJob_application} />}
        </div >
    )
}

export default Migration;
